export const labelTextField = {
  languageIcon: { cursor: "pointer", color: "#0077AD" },
  languageIconforTextArea: { cursor: "pointer", color: "#0077AD", fontSize: "1em" },
  languageTypography: { paddingTop: "35px", paddingLeft: "35px" },
  defaultLanguageBox: {
    "& > :not(style)": {
      m: 1,
      width: 350,
      xs: "column",
    },
  },
  descriptionTypography: { paddingTop: "10px", paddingLeft: "35px" },
  textField: {
    width: "370px",
    marginTop: "-25px",
  },
  clearAllIcon: { alignSelf: "center", cursor: "not-allowed" },
  clearAllIcon1: { alignSelf: "center", cursor: "pointer" },
  translationTypography: { color: "#006594", fontWeight: "500", fontSize: "18px" },
  stack: { marginTop: "5px" },
  box: {
    "& > :not(style)": {
      m: 1,
      width: 350,
      xs: "column",
      alignSelf: "center",
    },
  },
  typography: { paddingLeft: "35px" },
  textField1: {
    width: "370px",
  },
}
