import { useEffect, useRef, useState } from "react";
import { Button, Stack, Box } from "@vapor/react-material";
import { Tabs, Tab } from "@vapor/react-extended";
import { ETabSubjectMemeber } from "../../../../../domain/enums";
import SubjectMemberDrawer from "../SubjectMemberDrawer/SubjectMemberDrawer";
import { DmsSubjectMembersService } from "../../../../../domain/services/DmsSubjectMembersService";
import { useAuth, useTranslation, fetchJSON } from "@1f/react-sdk";
import {
  GridActionsColDef,
  GridColDef,
  GridColumnVisibilityModel,
  GridRowSelectionModel,
} from "@mui/x-data-grid-pro";
import {
  StandardColumns,
  CustomGridColDef,
} from "../../../../../components/DmsGrid/StandardColumns";
// import { RootState } from "../../../../store/store"
import {
  getDefaultDmsPageRequest,
  IDmsPageRequest,
  IDmsPageResponse,
} from "../../../../../domain/models/pagination/DmsPagination";
import { DmsSubjectDto } from "../../../../../domain/models/subjects";
import DmsDataGridProFunctions, {
  DmsEnumContextType,
} from "../../../../../components/DmsGrid/DmsDataGridProFunctions";
import useCustomSnackbar from "../../../../../custom-hooks/useCustomSnackbar";
import DmsDataGridPro from "../../../../../components/DmsGrid/DmsDataGridPro";
// import { useSelector } from "react-redux"
import { useStateSession } from "@1f/react-sdk";

interface IProps {
  subjectId: string | undefined;
}

enum ETab {
  UserGroupMembers = "?#Members",
  RoleMembers = "?#Members of",
}

const EditRoleTabs = ({ subjectId }: IProps) => {
  const [searchText, setSearchText] = useStateSession<string>(
    "editRolesPage_searchText"
  );
  // const searchText = useSelector((state: RootState) => {
  //   return state.editRolesPage.searchText
  // })
  const previousSearch = useRef("");

  const [activeTab, setActiveTab] = useState<ETab>(ETab.UserGroupMembers);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [refreshToggle, setRefreshToggle] = useState<boolean>(false);
  const { t } = useTranslation("dms");
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>({
    columns: [],
    rows: [],
    pageRequest: getDefaultDmsPageRequest(),
    pageResponse: null,
  });
  const [contextId, setContextId] = useState<string>(
    DmsDataGridProFunctions.getContextId(DmsEnumContextType.Roles, "Edit")
  );
  const { showSuccess, showError } = useCustomSnackbar();

  const ColumnWidths: { [key: string]: number } = {
    subjectId: 300,
    name: 300,
    label: 300,
    descr: 200,
    type: 100,
  };
  const columnVisibilityModel: GridColumnVisibilityModel = {
    subjectId: true,
    type: true,
    name: true,
    label: true,
    descr: true,
  };
  const columns: (GridColDef | GridActionsColDef | CustomGridColDef)[] = [
    StandardColumns.subjectId(t, { width: ColumnWidths["subjectId"] }),
    StandardColumns.subjectType(t, { width: ColumnWidths["type"] }),
    StandardColumns.name(t, { width: ColumnWidths["name"] }),
    StandardColumns.label(t, { width: ColumnWidths["label"] }),
    StandardColumns.descr(t, { width: ColumnWidths["descr"] }),
  ];

  useEffect(() => {
    if (
      searchText !== previousSearch.current ||
      activeTab !== previousSearch.current
    ) {
      previousSearch.current = searchText ?? "";
      fetchData();
    }
  }, [searchText, activeTab]);

  const fetchData = () => {
    setLoading(true);
    if (activeTab === ETab.UserGroupMembers) {
      // Fetch descendant subjects
      dmsDataFunction();
    } else if (activeTab === ETab.RoleMembers) {
      // Fetch ancestor subjects
      dmsDataFunction1();
    }
  };

  // Function to handle fetching descendant subjects
  function dmsDataFunction() {
    let pageRequest: IDmsPageRequest = data.pageRequest;
    pageRequest.searchText = searchText;

    DmsSubjectMembersService.descendantSubjectAsync(
      subjectId ?? "",
      0,
      pageRequest
    )
      .then((x: IDmsPageResponse<DmsSubjectDto>) => {
        let newColumns = DmsDataGridProFunctions.getColumns(x, columns);
        let newColumnVisibilityModel =
          DmsDataGridProFunctions.getViewColumnVisibilityModel(newColumns);
        let newRows = x.data;
        let newData = {
          ...data,
          columnVisibilityModel: newColumnVisibilityModel,
          columns: newColumns,
          rows: newRows,
          pageResponse: x,
        };
        setData(newData);
      })
      .catch((reason: { response: { data: any } }) => {
        showError("Failed to load descendant data.");
        setData({ ...data, columns: [], rows: [], pageResponse: null });
      })
      .finally(() => {
        setLoading(false);
      });
  }

  // Function to handle fetching ancestor subjects
  function dmsDataFunction1() {
    let pageRequest: IDmsPageRequest = data.pageRequest;
    pageRequest.searchText = searchText;

    DmsSubjectMembersService.ancestorSubjectAsync(
      subjectId ?? "",
      0,
      pageRequest
    )
      .then((x: IDmsPageResponse<DmsSubjectDto>) => {
        let newColumns = DmsDataGridProFunctions.getColumns(x, columns);
        let newColumnVisibilityModel =
          DmsDataGridProFunctions.getViewColumnVisibilityModel(newColumns);
        let newRows = x.data;
        let newData = {
          ...data,
          columnVisibilityModel: newColumnVisibilityModel,
          columns: newColumns,
          rows: newRows,
          pageResponse: x,
        };
        setData(newData);
      })
      .catch((reason: { response: { data: any } }) => {
        showError("Failed to load ancestor data.");
        setData({ ...data, columns: [], rows: [], pageResponse: null });
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const onRowSelectionModelChange = (rowSelection: GridRowSelectionModel) => {
    setRowSelectionModel(rowSelection);
  };

  const onCloseDrawer = () => {
    setIsDrawerOpen(false);
    setRefreshToggle((prevState) => !prevState);
  };

  const renderSubjectsDrawer = () => {
    switch (activeTab) {
      case ETab.UserGroupMembers:
        return (
          <SubjectMemberDrawer
            subjectId={subjectId}
            title={t("Role Structure")}
            text={t(
              "Selecting a user, group or role will automatically include it as a member."
            )}
            activeTab={ETabSubjectMemeber.Members}
            isOpen={isDrawerOpen}
            onClose={() => onCloseDrawer()}
          />
        );
      case ETab.RoleMembers:
        return (
          <SubjectMemberDrawer
            subjectId={subjectId}
            title={t("Role Structure")}
            text={t(
              "Choose a parent role to automatically inherit its permissions."
            )}
            activeTab={ETabSubjectMemeber.RolesAssigned}
            isOpen={isDrawerOpen}
            onClose={() => onCloseDrawer()}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <Stack spacing={2}>
      {/* Tabs */}
      <Tabs
        size="small"
        variant="standard"
        value={activeTab}
        onChange={(event, newTab) => setActiveTab(newTab)}
      >
        <Tab label={t("Members")} value={ETab.UserGroupMembers} />
        <Tab label={t("Members of")} value={ETab.RoleMembers} />
      </Tabs>

      <Stack direction="row" spacing={2}>
        <Box
          style={{
            width: "100%",
            display: "flex",
            alignItems: "end",
            justifyContent: "end",
          }}
        >
          <Button variant="contained" onClick={() => setIsDrawerOpen(true)}>
            {t("Configure")}
          </Button>
        </Box>
      </Stack>

      <br />

      {/* Members */}
      {activeTab === ETab.UserGroupMembers && (
        <DmsDataGridPro
          data={data}
          contextId={contextId}
          checkboxSelection={false}
          dmsDataFunction={dmsDataFunction}
          loading={loading}
          onRowSelectionModelChange={onRowSelectionModelChange}
        />
      )}
      {/* Role Members */}
      {activeTab === ETab.RoleMembers && (
        <DmsDataGridPro
          data={data}
          contextId={contextId}
          checkboxSelection={false}
          dmsDataFunction={dmsDataFunction1}
          loading={loading}
          onRowSelectionModelChange={onRowSelectionModelChange}
        />
      )}

      {/* Drawer for subjects */}
      {renderSubjectsDrawer()}
    </Stack>
  );
};

export default EditRoleTabs;
