import { useEffect, useRef, useState } from "react";
import { useAuth, useTranslation } from "@1f/react-sdk";

import VaporPage from "@vapor/react-custom/VaporPage";
import {
  Button,
  IconButton,
  Tooltip,
  Box,
  Stack,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogActions,
  DialogContent,
  Divider,
} from "@vapor/react-material";
import { useNavigate, Target, type Options } from "@1f/react-starter-sdk";
import WindowMaximize from "@vapor/react-icons/WindowMaximize";
import Pen from "@vapor/react-icons/Pen";
import TrashAlt from "@vapor/react-icons/TrashAlt";
import SubjectMembershipsDrawer from "../../../../custom-features/Subjects/SubjectMembershipDrawer/SubjectMembershipsDrawer";
import { DmsSubjectsService } from "../../../../domain/services/DmsSubjectService";
import { DmsSubjectDto } from "../../../../domain/models/subjects";
import useCustomSnackbar from "../../../../custom-hooks/useCustomSnackbar";
import { DmsEnumSubjectType } from "../../../../domain/models/value/DmsEnum";
import SearchBar from "../../../../components/SearchBar/SearchBar";
import {
  GridActionsColDef,
  GridColDef,
  GridRowId,
  GridRowSelectionModel,
} from "@mui/x-data-grid-pro";
import {
  StandardColumns,
  CustomGridColDef,
} from "../../../../components/DmsGrid/StandardColumns";

// import { useDispatch, useSelector } from "react-redux";
// import { AppDispatch, RootState } from "../../../../store/store";
import { useStateSession } from "@1f/react-sdk";
import {
  setSearchText,
  resetRolesPage,
} from "../../../../store/slices/Roles/rolesPageSlice";
import { isBackwardNavigation } from "../../../../utils/helpers";
import {
  getDefaultDmsPageRequest,
  IDmsPageRequest,
  IDmsPageResponse,
} from "../../../../domain/models/pagination/DmsPagination";
import DmsDataGridProFunctions, {
  DmsEnumContextType,
} from "../../../../components/DmsGrid/DmsDataGridProFunctions";
import DmsDataGridPro from "../../../../components/DmsGrid/DmsDataGridPro";

export interface RolesFilter {
  text?: string | null | undefined;
}

function RolesPage() {
  const tokenData = useAuth();
  const [searchText, setSearchText] = useStateSession<string>(
    "rolesPage_searchText"
  );
  // const dispatch = useDispatch<AppDispatch>();

  const previousSearch = useRef("");

  const selectAllEnabled: boolean = true;
  const options: Options = {
    type: "parent",
  };
  const { navigate } = useNavigate();
  const [refreshToggle, setRefreshToggle] = useState<boolean>(false);
  const { showSuccess, showError } = useCustomSnackbar();
  const [subjectIdToDelete, setSubjectIdToDelete] = useState<
    string | undefined
  >();
  const [isSingleDelete, setIsSingleDelete] = useState<boolean>(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [activeSubject, setActiveSubject] = useState<
    DmsSubjectDto | undefined
  >();
  const [isDetailsDrawerOpen, setIsDetailsDrawerOpen] = useState(false);
  const { t } = useTranslation("dms");
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>({
    columns: [],
    rows: [],
    pageRequest: getDefaultDmsPageRequest(),
    pageResponse: null,
  });
  const contextId = useRef<string>(
    DmsDataGridProFunctions.getContextId(DmsEnumContextType.Roles)
  );
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowId[]>([]);

  // Grid state

  //#region Grid related

  const rolesPageColumnWidths: { [key: string]: number } = {
    subjectId: 300,
    name: 300,
    label: 300,
    descr: 200,
    actions: 100,
    flagSystem: 140,
  };

  const columns: (GridColDef | GridActionsColDef | CustomGridColDef)[] = [
    StandardColumns.subjectId(t, { width: rolesPageColumnWidths["subjectId"] }),
    StandardColumns.name(t, { width: rolesPageColumnWidths["name"] }),
    StandardColumns.label(t, { width: rolesPageColumnWidths["label"] }),
    StandardColumns.descr(t, { width: rolesPageColumnWidths["descr"] }),
    StandardColumns.flagSystem(t, {
      width: rolesPageColumnWidths["flagSystem"],
    }),
    {
      field: "_actions_",
      type: "actions",
      width: 140,
      renderCell: (params: any) => (
        <>
          <Tooltip arrow title="Drawer">
            <IconButton
              onClick={() => onDetailsBtnClick(params)}
              color="primary"
            >
              {" "}
              {/*   onDetailsBtnClick(params)} */}
              <WindowMaximize rotation={90} />
            </IconButton>
          </Tooltip>
          <Tooltip arrow title={t("Edit")}>
            <IconButton onClick={() => onEditClick(params)} color="primary">
              <Pen color="primary" />
            </IconButton>
          </Tooltip>
          <Tooltip arrow title={t("Delete")}>
            <IconButton onClick={() => onDeleteClick(params)}>
              <TrashAlt />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];

  //  const [selectedRows, setSelectedRows] = useState<string[]>([])

  //#endregion

  useEffect(() => {
    if (searchText !== previousSearch.current) {
      previousSearch.current = searchText ?? "";
      dmsDataFunction();
    }
  }, [searchText]);

  useEffect(() => {
    if (!isBackwardNavigation()) {
      setSearchText("");
    }
    dmsDataFunction();
  }, []);

  //#region Handlers

  const onDetailsBtnClick = (row: any) => {
    console.log("element passed", row);
    setActiveSubject({ ...row });
    setIsDetailsDrawerOpen(true);
  };

  const onDetailsDrawerClose = () => {
    setIsDetailsDrawerOpen(false);
  };

  const onEditClick = (params: any) => {
    //    navigate(`/role/config/editrole/${params.row.subjectId}`, options);
    navigate(`rnd::dms::app://role/config/editrole/${params.row.subjectId}`, {
      ...options,
      target: Target.SELF,
    });
  };

  const onDeleteClick = (params: any) => {
    setIsSingleDelete(true);
    setSubjectIdToDelete(params.row.subjectId);
    setIsDialogOpen(true);
  };

  const onDeleteSelectedClick = () => {
    setIsSingleDelete(false);
    setIsDialogOpen(true);
  };

  const onDeleteCancel = () => {
    setIsDialogOpen(false);
  };

  const onDeleteConfirm = async () => {
    setIsDialogOpen(false);
    setLoading(true);
    let isSuccess;
    try {
      // Logic when it's a single delete
      // if (isSingleDelete) {
      //   isSuccess = await DmsSubjectsService.deleteOneAsync(
      //     subjectIdToDelete ?? ""
      //   );
      // }
      // // Logic when it's a multiple selection delete
      // else {
      //   isSuccess = await DmsSubjectsService.deleteManyAsync(
      //     rowSelectionModel.map((rowSelected: GridRowId) => {
      //       return rowSelected.toString();
      //     })
      //   );
      // }

      // SUCCESS
      if (isSuccess) {
        showSuccess(
          isSingleDelete
            ? t("Role was deleted successfully")
            : t("Selected items were deleted successfully.")
        );
        setRefreshToggle((prevState) => !prevState);
      }
      // FAIL
      else {
        showError(
          `${t("Something went wrong. The deletion was not successful.")}`
        );
      }
    } catch (e: any) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };
  const onRowSelectionModelChange = (rowSelection: GridRowId[]) => {
    setRowSelectionModel(rowSelection);
  };

  const onSearchText = (newSearchText: string) => {
    //setSearchText(newSearchtext)
    setSearchText(newSearchText);
  };

  //#endregion

  // const apiEndpointFunction = (page: number, pageSize: number, searchText1: string, sort: IDmsOrderByItem[], filter: IDmsSearchFilterItem[]) => {
  //   let pageData = {
  //     page: page,
  //     pageSize: pageSize,
  //     searchText: searchText,
  //     searchFilter: filter,
  //     // orderBy: [
  //     //   {

  //     //     fieldDescriptor: [fieldDescriptor],
  //     //     direction: direction,
  //     //   },
  //     // ],
  //     orderBy: sort,
  //   }
  //   return DmsSubjectsService.searchAsync([DmsEnumSubjectType.Role], pageData)
  // }

  function dmsDataFunction() {
    setLoading(true);
    let pageRequest: IDmsPageRequest = data.pageRequest;
    pageRequest.searchText = searchText;
    return DmsSubjectsService.searchAsync(
      [DmsEnumSubjectType.Role],
      pageRequest
    )
      .then((x: IDmsPageResponse<DmsSubjectDto>) => {
        let newColumns = DmsDataGridProFunctions.getColumns(x, columns);
        let newColumnVisibilityModel =
          DmsDataGridProFunctions.getViewColumnVisibilityModel(newColumns);
        let newRows = x.data;
        let newData = {
          ...data,
          columnVisibilityModel: newColumnVisibilityModel,
          columns: newColumns,
          rows: newRows,
          pageResponse: x,
        };
        setData(newData);
      })
      .catch((reason: { response: { data: any } }) => {
        showError("Failed to load data.");
        setData({ ...data, columns: [], rows: [], pageResponse: null });
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <VaporPage title={t("Roles")} subtitle={t("Management")}>
      <VaporPage.Section>
        <Stack direction="row" spacing={2}>
          <SearchBar
            width="300px"
            filters={searchText ?? ""}
            onFilter={onSearchText}
          />
          <Box
            style={{
              width: "100%",
              display: "flex",
              alignItems: "end",
              justifyContent: "end",
              flexDirection: "row",
            }}
          >
            <Stack direction="row" spacing={2}>
              {typeof rowSelectionModel === "undefined" ||
              rowSelectionModel.length === 0 ? (
                <Button disabled size="medium">
                  {t("Delete")}
                </Button>
              ) : (
                <Button
                  onClick={() => onDeleteSelectedClick()}
                  size="medium"
                  color="error"
                >
                  {t("Delete")}
                </Button>
              )}
              <Divider flexItem orientation="vertical" variant="middle" />
              <Button
                variant="contained"
                onClick={() => navigate("/role/config/newrole", options)}
                onFocusVisible={() => {}}
              >
                {t("New Role")}
              </Button>
            </Stack>
          </Box>
        </Stack>

        <br />
        <br />

        <DmsDataGridPro
          data={data}
          contextId={contextId.current}
          checkboxSelection={true}
          dmsDataFunction={dmsDataFunction}
          loading={loading}
          onRowSelectionModelChange={onRowSelectionModelChange}
          rowSelectionModel={rowSelectionModel}
        />
        {/* Details drawer */}
        <SubjectMembershipsDrawer
          isOpen={isDetailsDrawerOpen}
          subject={activeSubject}
          onClose={onDetailsDrawerClose}
        />

        {/* Delete confirmation dialog */}
        <Dialog
          open={isDialogOpen}
          onClose={onDeleteCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>{t("Confirm elimination?")}</DialogTitle>

          <Divider variant="alert" />

          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {isSingleDelete
                ? t("Do you want to delete this element?")
                : `${t("Do you want to delete the selected elements?")} (${
                    rowSelectionModel.length
                  })?`}
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button variant="outlined" onClick={onDeleteCancel}>
              {t("Cancel")}
            </Button>

            <Button variant="contained" color="error" onClick={onDeleteConfirm}>
              {t("Delete")}
            </Button>
          </DialogActions>
        </Dialog>
      </VaporPage.Section>
    </VaporPage>
  );
}

export default RolesPage;
