import { IDmsNameSetCollection } from "../base/DmsBase";
import {
  IDmsAttributeInfo,
  IDmsTextTranslation,
} from "../component/DmsComponent";

export interface IDmsSearchFilterDtoGrid extends IDmsNameSetCollection {
  filterId?: string;
  ownerId?: string;
  filterType?: DmsEnumSearchFilterType;
  subjects?: string[];
  filterResult?: DmsEnumSearchFilterResult;
  flagCompound?: boolean; // true for composite filters (suspended), false for simple filters
}

export interface IDmsSearchFilterDtoCreate extends IDmsNameSetCollection {
  filterId?: string;
  filterType?: DmsEnumSearchFilterType;
  subjects?: string[];
  filterResult?: DmsEnumSearchFilterResult;
  viewId?: string;
  flagCompound?: boolean; // true for composite filters (suspended), false for simple filters
  searchFilters?: string[]; // only when flagCompound=true: list of inner filters (suspended)
  filterLogic?: DmsEnumSearchFilterLogic; // only when flagCompound=true: and/or for inner filters (suspended)
  filterList?: IDmsSearchFilterItem[];
}

export interface IDmsSearchFilterDtoRead extends IDmsNameSetCollection {
  filterId?: string;
  ownerId?: string;
  filterType?: DmsEnumSearchFilterType;
  subjects?: string[];
  filterResult?: DmsEnumSearchFilterResult;
  viewId?: string;
  flagCompound?: boolean; // true for composite filters (suspended), false for simple filters
  searchFilters?: string[]; // only when flagCompound=true: list of inner filters (suspended)
  filterLogic?: DmsEnumSearchFilterLogic; // only when flagCompound=true: and/or for inner filters (suspended)
  filterList?: IDmsSearchFilterItem[];
  attributeInfoList: IDmsAttributeInfo[];
}

export interface IDmsSearchFilterDtoUpdate {
  filterId?: string;
  filterType?: DmsEnumSearchFilterType;
  subjects?: string[];
  filterResult?: DmsEnumSearchFilterResult;
  viewId?: string;
  flagCompound?: boolean; // true for composite filters (suspended), false for simple filters
  searchFilters?: string[]; // only when flagCompound=true: list of inner filters (suspended)
  filterLogic?: DmsEnumSearchFilterLogic; // only when flagCompound=true: and/or for inner filters (suspended)
  filterList?: IDmsSearchFilterItem[];
}

export interface IDmsSearchFilterItem {
  filterItem?: DmsEnumSearchFilterItem;
  filterCondition?: DmsEnumSearchFilterCondition;
  filterValue?: string[];
  attributeReference?: IDmsAttributeReference;
  filterOption?: IDmsEnumSearchFilterOption;
  name?: string;
}

export interface IDmsAttributeReference {
  attributeId?: string;
  label?: IDmsTextTranslation[];
  descr?: IDmsTextTranslation[];
  type?: string;
}

export enum IDmsEnumSearchFilterOption {
  Exactly = 1,
}

export enum DmsEnumSearchFilterType {
  Common = 0,
  User = 1,
  GroupRole = 2,
}

export enum DmsEnumSearchFilterResult {
  Objects = 1,
  Files = 2,
}

enum DmsEnumSearchFilterLogic {
  Or = 1,
  And = 2,
}

export enum DmsEnumSearchFilterItem {
  Unknown = 0,

  Class = 100,
  Status = 200,
  Attribute = 300,

  ObjectId = 4001,
  ObjectDateCreation = 4101,
  ObjectDateModify = 4102,
  ObjectDateChangeStatus = 4103,
  ObjectRecipients = 4104,
  ObjectText = 4105,
  ObjectHasDocuments = 4106,

  FileName = 5001,
  FileDescription = 5002,
  FileType = 5003,
  FileContent = 5004,
  FileVersion = 5005,
  FileSize = 5006,
  FileDateCreation = 5101,
  FileDateModify = 5102,
}

export enum DmsEnumSearchFilterCondition {
  None = "none",

  IsEmpty = "is-empty",
  IsNotEmpty = "is-not-empty",

  ClassList = "id-contains-values",
  StatusList = "id-contains-values",

  // Special condition on ID
  IdEqual = "id-is",
  IdNotEqual = "id-is-not",
  IdBetween = "id-is-between",
  IdGreater = "id-greater-than",
  IdGreaterEqual = "id-greater-equal",
  IdLess = "id-less-than",
  IdLessEqual = "id-less-equal",
  IdContainsValues = "id-contains-values",

  // Special condition on files
  FileType = "501",
  FileContent = "502",
  FileVersion = "503",

  Recipients = "id-contains-values",

  // Boolean
  BooleanEqual = "bool-is",

  // Datetime
  DatetimeEqual = "datetime-is",
  DatetimeBetween = "datetime-is-between",
  DatetimeBefore = "datetime-is-before",
  DatetimeAfter = "datetime-is-after",
  DatetimeDaysBefore = "datetime-days-before",
  DatetimeDaysAfter = "datetime-days-after",
  DatetimeWeeksBefore = "datetime-weeks-before",
  DatetimeWeeksAfter = "datetime-weeks-after",
  DatetimeMonthsBefore = "datetime-months-before",
  DatetimeMonthsAfter = "datetime-months-after",

  // Date
  DateEqual = "date-is",
  DateBetween = "date-is-between",
  DateBefore = "date-is-before",
  DateAfter = "date-is-after",
  DateDaysBefore = "date-days-before",
  DateDaysAfter = "date-days-after",
  DateWeeksBefore = "date-weeks-before",
  DateWeeksAfter = "date-weeks-after",
  DateMonthsBefore = "date-months-before",
  DateMonthsAfter = "date-months-after",

  // Time
  HourEqual = "hour-is",
  HourBetween = "hour-is-between",
  HourBefore = "hour-is-before",
  HourAfter = "hour-is-after",

  // Int
  IntEqual = "int-is",
  IntBetween = "int-is-between",
  IntGreater = "int-greater-than",
  IntGreaterEqual = "int-greater-equal",
  IntLess = "int-less-than",
  IntLessEqual = "int-less-equal",

  // Decimal
  DecimalEqual = "decimal-is",
  DecimalBetween = "decimal-is-between",
  DecimalGreater = "decimal-greater-than",
  DecimalGreaterEqual = "decimal-greater-equal",
  DecimalLess = "decimal-less-than",
  DecimalLessEqual = "decimal-less-equal",

  //String
  StringEqual = "string-is",
  StringBetween = "string-is-between",
  StringNotEqual = "string-is-not",
  StringContains = "string-contains",
  StringNotContains = "string-not-contains",
  StringContainsValues = "string-contains-values",
  StringStartWith = "string-starts-with",
  StringEndsWith = "string-ends-with",
  StringSelectedValues = "string-select-value",

  // Text
  TextEqual = "string-is",
  TextBetween = "string-is-between",
  TextNotEqual = "string-is-not",
  TextContains = "string-contains",
  TextNotContains = "string-not-contains",
  TextContainsValues = "string-contains-values",
  TextStartWith = "string-starts-with",
  TextEndsWith = "string-ends-with",

  // Enum
  EnumEqual = "id-is",
  EnumRadio = "id-contains-values",
  EnumCheckbox = "id-contains-values",
  EnumContainsValues = "id-contains-values",
  EnumListRadio = "id-contains-values",
  EnumListCheckbox = "id-contains-values",

  // Object
  ObjectEqual = "id-is",
  ObjectContainsValues = "id-contains-values",

  // Subject
  SubjectEqual = "id-is",
  SubjectContainsValues = "id-contains-values",
}

function isDmsEnumSearchFilterCondition(
  value: string
): value is DmsEnumSearchFilterCondition {
  return Object.values(DmsEnumSearchFilterCondition).includes(
    value as DmsEnumSearchFilterCondition
  );
}

export function toDmsEnumSearchFilterCondition(
  value: string
): DmsEnumSearchFilterCondition | undefined {
  if (isDmsEnumSearchFilterCondition(value)) {
    return value;
  }
  return undefined;
}
export function fromDmsEnumSearchFilterCondition(
  value: DmsEnumSearchFilterCondition | undefined
): string {
  return typeof value !== "undefined"
    ? value
    : DmsEnumSearchFilterCondition.None;
}
