import { useState } from "react";

import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  Box,
  Checkbox,
  CircularProgress,
} from "@vapor/react-material";
import Typography from "@vapor/react-extended/ExtendedTypography";
import VaporEmptyState from "@vapor/react-custom/VaporEmptyState";
import PersonIcon from "@mui/icons-material/Person";
import GroupIcon from "@mui/icons-material/Group";
import { visuallyHidden } from "@mui/utils";
import { DmsSubjectDto } from "../../../domain/models/subjects";
import { getLabel } from "../../../utils/helpers";
import { useTranslation } from "@1f/react-sdk";

interface IProps {
  memberships: DmsSubjectDto[];
}

const SubjectMembershipsTable = ({ memberships }: IProps) => {
  const selectAllEnabled: boolean = false;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  const headCells = [
    {
      id: "Id",
      numeric: false,
      disablePadding: true,
      label: t("Id"),
    },
    {
      id: "Name",
      numeric: false,
      disablePadding: true,
      label: t("Name"),
    },
    {
      id: "Descr",
      numeric: false,
      disablePadding: true,
      label: t("Description"),
    },
    {
      id: "Type",
      numeric: false,
      disablePadding: true,
      label: t("Type"),
    },
  ];
  // Grid state
  const [page] = useState(0);
  const [rowsPerPage] = useState(5);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("Id");
  const [dense] = useState(false);

  //#region Grid related

  const numSelected = selectedIds.length;
  const rowCount = memberships.length;
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - memberships.length) : 0;

  function descendingComparator(a: any, b: any, orderBy: any) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order: any, orderBy: any) {
    return order === "desc"
      ? (a: any, b: any) => descendingComparator(a, b, orderBy)
      : (a: any, b: any) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array: any, comparator: any) {
    const stabilizedThis = array.map((el: any, index: any) => [el, index]);
    stabilizedThis.sort((a: any, b: any) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el: any) => el[0]);
  }

  const handleRequestSort = (event: any, property: any) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const createSortHandler = (property: string) => (event: any) => {
    handleRequestSort(event, property);
  };

  //#endregion

  const onSelectAllClick = (event: { target: { checked: any } }) => {
    // Check
    if (event.target.checked) {
      const newSelectedIds: any = memberships.map((s) => s._id);
      setSelectedIds(newSelectedIds);
    }
    // Uncheck
    else {
      setSelectedIds([]);
    }
  };

  const onSelectRow = (event: any, _id: string) => {
    // If is an action button click, don't trigger row selection
    var isActionBtnClick = ["BUTTON", "svg", "path"].includes(
      event.target.tagName
    );
    if (isActionBtnClick) return;

    let isSelectOperation = selectedIds.indexOf(_id) < 0;
    let newSelectedIds = [...selectedIds];

    isSelectOperation
      ? newSelectedIds.push(_id)
      : newSelectedIds.splice(newSelectedIds.indexOf(_id), 1);

    setSelectedIds(newSelectedIds);
  };

  const noRecords: boolean = !memberships || memberships.length === 0;

  return (
    <TableContainer>
      <Table aria-label="customized table">
        {/* Table head */}
        <TableHead shadow>
          <TableRow>
            {selectAllEnabled && (
              <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  indeterminate={numSelected > 0 && numSelected < rowCount}
                  checked={rowCount > 0 && numSelected === rowCount}
                  onChange={onSelectAllClick}
                  inputProps={{
                    "aria-label": "select all Id",
                  }}
                />
              </TableCell>
            )}

            {headCells.map((headCell) => (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "normal"}
                // sortDirection={orderBy === headCell.id ? order : undefined}
                sortDirection={undefined}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  // direction={orderBy === headCell.id ? order : "asc"}
                  direction={undefined}
                  onClick={createSortHandler(headCell.id)}
                >
                  <Typography variant="boldLabel" translate={undefined}>
                    {headCell.label}
                  </Typography>

                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        {/* Table body */}
        <TableBody>
          {/* Loading indicator */}
          {isLoading && (
            <TableRow>
              <TableCell colSpan={6} align="center">
                <CircularProgress />
              </TableCell>
            </TableRow>
          )}

          {/* No data */}
          {noRecords && (
            <TableRow>
              <TableCell
                colSpan={headCells.length + (selectAllEnabled ? 1 : 0)}
              >
                <VaporEmptyState
                  badgeIcon
                  title="There is no data to display"
                />
              </TableCell>
            </TableRow>
          )}

          {/* Data */}
          {isLoading
            ? null
            : stableSort(memberships, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: DmsSubjectDto, index: number) => {
                  const isItemSelected = selectedIds.indexOf(row._id) > -1;
                  const labelId = `enhanced-table-checkbox-${row._id}`;

                  return (
                    <TableRow
                      key={row._id}
                      hover
                      onClick={(e) => onSelectRow(e, row._id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      selected={isItemSelected}
                    >
                      {/* <TableCell padding="normal">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell> */}

                      {/* Id */}
                      <TableCell
                        sx={{ padding: "4px" }}
                        component="th"
                        id={labelId}
                        scope="row"
                        align="left"
                        padding="normal"
                      >
                        <Typography variant="body" translate={undefined}>
                          {row._id}
                        </Typography>
                      </TableCell>

                      {/* Fullname */}
                      <TableCell
                        sx={{ padding: "4px" }}
                        align="left"
                        padding="normal"
                      >
                        <Typography variant="body" translate={undefined}>
                          {row.name}
                        </Typography>
                      </TableCell>

                      {/* Descr */}
                      <TableCell
                        sx={{ padding: "4px" }}
                        align="left"
                        padding="normal"
                      >
                        <Typography variant="body" translate={undefined}>
                          {getLabel(row.descr)}
                        </Typography>
                      </TableCell>

                      {/* Icon */}
                      <TableCell
                        sx={{ padding: "4px" }}
                        align="left"
                        padding="normal"
                      >
                        <Typography variant="body" translate={undefined}>
                          {row.type === 1 ? <PersonIcon /> : <GroupIcon />}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}

          {/* When rows less than page size */}
          {emptyRows > 0 && (
            <TableRow
              style={{
                height: (dense ? 33 : 53) * emptyRows,
              }}
            >
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SubjectMembershipsTable;
