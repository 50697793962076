import {
  useAuth,
  fetch,
  useRequest,
  useTranslation,
  fetchJSON,
} from "@1f/react-sdk";
import { useCurrentTenant, useCurrentWorkspace } from "@1f/react-starter-sdk";
import { Typography } from "@vapor/react-extended";
import { Box, Button } from "@vapor/react-material";
import { AttributeValueDto } from "../../../domain/models/DmsAttributes";

import { CodeBox } from "../code-box";
import { useEffect, useState } from "react";

export const ProxyUrlRequest2 = () => {
  const tokenData = useAuth();
  const tenantData = useCurrentTenant();
  const workspaceData = useCurrentWorkspace();
  const { t } = useTranslation("dms");

  // const { loading, data, error, fetch } = useRequest({
  //   method: "get",
  //   lazy: true,
  //   url: "dms::be::Api://api/v1/DmsAttributes/65ca8fc2ad6a7fc990b1931d/read",
  // });
  const [response, setResponse] = useState<AttributeValueDto | null>(null);
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    setLoading(true);
    // fetch("dms::be::Api://api/v1/DmsAttributes/65ca8fc2ad6a7fc990b1931d/read")
    //   .then((res) => res.json())
    //   .then((res) => setResponse(res))
    //   .catch((error) => console.error(error));
    fetchJSON<AttributeValueDto>(
      //      "dms::be::Api://api/v1/DmsAttributes/65ca8fc2ad6a7fc990b1931d/read"
      "dms::be::Api://api/v1/Dev/whoami"
    )
      .then((res) => setResponse(res))
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    setLoading(false);
  }, [response]);

  return (
    <Box sx={{ mb: "20px" }}>
      <Typography variant="titleSmall">
        useRequest for an internal service {t("welcome_user")}
      </Typography>
      <CodeBox>
        {JSON.stringify(
          // @ts-ignore
          tokenData!,
          null,
          2
        )}
        {/*JSON.stringify(
          tenantData!,
          null,
          2
        )*/}
        {/*JSON.stringify(
          workspaceData!,
          null,
          2
        )*/}
        {/* {`
const { loading, data, error, fetch } = useRequest({
  lazy:   true,
  method: "get",
  url:    "dms::be::Api://api/v1/Dev/whoami"
});
        `.trim()} */}
      </CodeBox>
      <Box
        sx={{ display: "flex", flexDirection: "row", alignItems: "baseline" }}
      >
        <Button
          variant="contained"
          sx={{ mt: 2 }}
          onClick={handleClick}
          disabled={loading}
        >
          Click me
        </Button>
        {loading && "Calling dms::be::Api://api/v1/Dev/whoami..."}
        {/* {error && `ERROR: ${error.message}`} */}
      </Box>
      {response && (
        <CodeBox sx={{ fontSize: 11 }}>
          {JSON.stringify(
            // @ts-ignore
            response,
            null,
            2
          )}
        </CodeBox>
      )}
    </Box>
  );
};
