import React, { useEffect, useRef, useState } from "react";
import { Tabs, Tab, Typography } from "@vapor/react-extended";
import {
  Box,
  FormControl,
  Select,
  MenuItem,
  IconButton,
  FormGroup,
  FormControlLabel,
  Toggle,
} from "@vapor/react-material";
import SearchBar from "../../../../../components/SearchBar/SearchBar";
import { DmsSubjectsService } from "../../../../../domain/services/DmsSubjectService";
import { DmsSubjectMembersService } from "../../../../../domain/services/DmsSubjectMembersService";
import { DmsEnumSubjectType } from "../../../../../domain/models/value/DmsEnum";
import { ETabSubjectMemeber } from "../../../../../domain/enums";
import useCustomSnackbar from "../../../../../custom-hooks/useCustomSnackbar";
import { faSquareCheck } from "@fortawesome/pro-regular-svg-icons/faSquareCheck";
import { faSquare } from "@fortawesome/pro-regular-svg-icons/faSquare";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth, useTranslation, fetchJSON } from "@1f/react-sdk";
import {
  GridActionsColDef,
  GridColDef,
  GridColumnVisibilityModel,
  GridRowSelectionModel,
} from "@mui/x-data-grid-pro";
import {
  StandardColumns,
  CustomGridColDef,
} from "../../../../../components/DmsGrid/StandardColumns";
import {
  getDefaultDmsPageRequest,
  IDmsPageRequest,
  IDmsPageResponse,
} from "../../../../../domain/models/pagination/DmsPagination";
//import { useDispatch, useSelector } from "react-redux"
// import { AppDispatch, RootState } from "../../../../store/store"
import DmsDataGridProFunctions, {
  DmsEnumContextType,
} from "../../../../../components/DmsGrid/DmsDataGridProFunctions";
//import { setCheckedFilter, setSearchText, setTypeValue } from "../../../../store/slices/RolesDrawerTable/rolesDrawerTablePageSlice"
import { DmsSubjectDto } from "../../../../../domain/models/subjects";
import DmsDataGridPro from "../../../../../components/DmsGrid/DmsDataGridPro";
import { useStateSession } from "@1f/react-sdk";

interface IProps {
  activeTab: ETabSubjectMemeber;
  subjectId: string | undefined;
  text: string;
}

const ContentSwitcherDrawer = ({ text, subjectId, activeTab }: IProps) => {
  // const dispatch = useDispatch<AppDispatch>()
  // const searchText = useSelector((state: RootState) => {
  //   return state.rolesDrawerTablePage.searchText
  // })
  // const typeValue = useSelector((state: any) => state.rolesDrawerTablePage.typeValue)
  // const checkedFilter = useSelector((state: RootState) => state.rolesDrawerTablePage.checkedFilter)
  const [searchText, setSearchText] = useStateSession<string>(
    "rolesDrawerTablePage_searchText"
  );
  const [typeValue, setTypeValue] = useStateSession(
    "rolesDrawerTablePage_typeValue"
  );
  const [checkedFilter, setCheckedFilter] = useStateSession<boolean>(
    "rolesDrawerTablePage_checkedFilter"
  );

  const previousSearch = useRef("");
  const [activeMemberTab, setActiveMemberTab] =
    useState<ETabSubjectMemeber>(activeTab);
  const [refreshToggle, setRefreshToggle] = useState<boolean>(false);
  const [searchType, setSearchType] = useState<DmsEnumSubjectType[]>([
    DmsEnumSubjectType.User,
    DmsEnumSubjectType.Group,
  ]);
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>({
    columns: [],
    rows: [],
    pageRequest: getDefaultDmsPageRequest(),
    pageResponse: null,
  });
  const [contextId, setContextId] = useState<string>(
    DmsDataGridProFunctions.getContextId(DmsEnumContextType.Roles, "Drawer")
  );
  const { t } = useTranslation("dms");

  const columnVisibilityModel: GridColumnVisibilityModel = {
    name: true,
    type: true,
    label: true,
  };
  const columnWidths: { [key: string]: number } = {
    name: 200,
    type: 200,
    label: 200,
  };
  const columns: (GridColDef | GridActionsColDef | CustomGridColDef)[] = [
    StandardColumns.name(t, { width: columnWidths["name"] }),
    StandardColumns.subjectType(t, { width: columnWidths["type"] }),
    StandardColumns.label(t, { width: columnWidths["label"] }),

    {
      field: "_actions_",
      headerName: "",
      type: "actions",
      editable: false,
      width: 100,
      renderCell: (params: any) => {
        return (
          <>
            {params.row._checked === true ? (
              <IconButton
                onClick={() =>
                  handleChange(params.row.subjectId, params.row._checked)
                }
              >
                <FontAwesomeIcon icon={faSquareCheck} />
              </IconButton>
            ) : (
              <IconButton
                onClick={() =>
                  handleChange(params.row.subjectId, params.row._checked)
                }
              >
                <FontAwesomeIcon icon={faSquare} />
              </IconButton>
            )}
          </>
        );
      },
    },
  ];
  useEffect(() => {
    if (
      (searchText ?? "") !== previousSearch.current ||
      activeTab !== previousSearch.current
    ) {
      previousSearch.current = searchText ?? "";
      fetchData();
    }
  }, [searchText, activeTab]);

  const onRowSelectionModelChange = (rowSelection: GridRowSelectionModel) => {
    setRowSelectionModel(rowSelection);
  };

  const fetchData = () => {
    setLoading(true);
    if (activeTab === ETabSubjectMemeber.Members) {
      // Fetch descendant subjects
      Memebers();
    } else if (activeTab === ETabSubjectMemeber.RolesAssigned) {
      // Fetch ancestor subjects
      RolesAssign();
    }
  };

  useEffect(() => {
    fetchData();
  }, [typeValue, checkedFilter]);

  const { showError } = useCustomSnackbar();

  const handleChange = async (id: string, checkToggle: boolean) => {
    if (activeMemberTab === ETabSubjectMemeber.Members) {
      await onMembersRoleChange(id, checkToggle);
      fetchData();
    } else if (activeMemberTab === ETabSubjectMemeber.RolesAssigned) {
      await onRoleAssignedChange(id, checkToggle);
      fetchData();
    }
  };
  const handleType = (event: any) => {
    const selectedTypeValue = event.target.value;
    setTypeValue(selectedTypeValue);
    if (selectedTypeValue === DmsEnumSubjectType.User)
      setSearchType([DmsEnumSubjectType.User]);
    else if (selectedTypeValue === DmsEnumSubjectType.Group)
      setSearchType([DmsEnumSubjectType.Group]);
    else if (selectedTypeValue === DmsEnumSubjectType.Role)
      setSearchType([DmsEnumSubjectType.Role]);
    else {
      setSearchType([
        DmsEnumSubjectType.User,
        DmsEnumSubjectType.Group,
        DmsEnumSubjectType.Role,
      ]);
    }
  };

  const onMembersRoleChange = async (id: string, checkToggle: boolean) => {
    try {
      if (checkToggle === true) {
        let response = await DmsSubjectMembersService.updateRoleMembersAsync(
          subjectId,
          [],
          [id]
        );
        if (response) {
        }
      } else {
        let response = await DmsSubjectMembersService.updateRoleMembersAsync(
          subjectId,
          [id],
          []
        );
        if (response) {
        }
      }
    } catch (e: any) {
      showError(e);
    }
  };

  const onRoleAssignedChange = async (id: string, checkToggle: boolean) => {
    try {
      if (checkToggle === true) {
        let response = await DmsSubjectMembersService.updateRoleMembershipAsync(
          subjectId,
          [],
          [id]
        );
        if (response) {
        }
      } else {
        let response = await DmsSubjectMembersService.updateRoleMembershipAsync(
          subjectId,
          [id],
          []
        );
        if (response) {
        }
      }
    } catch (e: any) {
      showError(e);
    }
  };

  function Memebers() {
    const pageRequest: IDmsPageRequest = data.pageRequest;
    pageRequest.searchText = searchText;
    const subjectTypes = searchType;

    setLoading(true);
    DmsSubjectsService.searchAsync(
      subjectTypes,
      pageRequest,
      subjectId,
      "",
      checkedFilter ?? false
    )
      .then((x: IDmsPageResponse<DmsSubjectDto>) => {
        const newColumns = DmsDataGridProFunctions.getColumns(x, columns);
        const newColumnVisibilityModel =
          DmsDataGridProFunctions.getViewColumnVisibilityModel(newColumns);
        const newRows = x.data;

        setData({
          ...data,
          columnVisibilityModel: newColumnVisibilityModel,
          columns: newColumns,
          rows: newRows,
          pageResponse: x,
        });
      })
      .catch((error) => {
        showError("Failed to load descendant data.");
        setData({ ...data, columns: [], rows: [], pageResponse: null });
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function RolesAssign() {
    let pageRequest: IDmsPageRequest = data.pageRequest;
    pageRequest.searchText = searchText;

    let params = "";
    let subjectTypes = [DmsEnumSubjectType.Role];
    subjectTypes.forEach((type) => (params += `subjectTypes=${type}&`));
    if (subjectId !== undefined) params += `membersOf=${subjectId}&`;
    if (checkedFilter !== undefined && checkedFilter !== false)
      params += `checkedFilter=${checkedFilter}&`;

    setLoading(true);

    DmsSubjectsService.searchAsync(
      subjectTypes,
      pageRequest,
      "",
      subjectId,
      checkedFilter ?? false,
      1
    )
      .then((x: IDmsPageResponse<DmsSubjectDto>) => {
        let newColumns = DmsDataGridProFunctions.getColumns(x, columns);
        let newColumnVisibilityModel =
          DmsDataGridProFunctions.getViewColumnVisibilityModel(newColumns);
        let newRows = x.data;
        let newData = {
          ...data,
          columnVisibilityModel: newColumnVisibilityModel,
          columns: newColumns,
          rows: newRows,
          pageResponse: x,
        };
        setData(newData);
      })
      .catch((reason: { response: { data: any } }) => {
        showError("Failed to load assigned roles data.");
        setData({ ...data, columns: [], rows: [], pageResponse: null });
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const handleEnabledChange = (newCheckedFilter: boolean) => {
    setCheckedFilter(newCheckedFilter);
    setRefreshToggle((prevState) => !prevState);
  };

  const onSearchText = (newSearchText: string) => {
    setSearchText(newSearchText);
  };

  return (
    <Box m={2} display={"flex"} flexDirection={"column"} gap={2}>
      <Tabs
        size="small"
        variant="standard"
        value={activeMemberTab}
        onChange={(event: any, newTab: ETabSubjectMemeber) =>
          setActiveMemberTab(newTab)
        }
      >
        <Tab
          disabled={activeTab !== ETabSubjectMemeber.Members}
          label={t("Members")}
          value={ETabSubjectMemeber.Members}
        />
        <Tab
          disabled={activeTab !== ETabSubjectMemeber.RolesAssigned}
          label={t("Role assigned")}
          value={ETabSubjectMemeber.RolesAssigned}
        />
      </Tabs>
      <Typography
        sx={{
          color: "#101214",
          lineHeight: " 125%",
        }}
        fontFamily="Roboto"
        fontWeight="400"
      >
        {text}
      </Typography>
      <Box
        display={"flex"}
        justifyContent={"flex-start"}
        gap={2}
        sx={{ flexWrap: "wrap" }}
      >
        <SearchBar
          width="250px"
          filters={searchText ?? ""}
          onFilter={onSearchText}
        />
        {activeMemberTab === ETabSubjectMemeber.Members && (
          <FormControl sx={{ width: "250px" }}>
            <Select onChange={handleType} value={typeValue}>
              <MenuItem value={-1}>{t("All")}</MenuItem>
              <MenuItem value={2}>{t("Groups")}</MenuItem>
              <MenuItem value={1}>{t("Users")} </MenuItem>
              <MenuItem value={3}>{t("Roles")}</MenuItem>
            </Select>
          </FormControl>
        )}
        <FormGroup>
          <FormControlLabel
            control={
              <Toggle
                checked={checkedFilter ?? false}
                onChange={(e: any) => handleEnabledChange(e?.target?.checked)}
              />
            }
            label={t("Show only selected")}
          />
        </FormGroup>
      </Box>
      {activeMemberTab === ETabSubjectMemeber.Members && (
        <Box>
          <DmsDataGridPro
            data={data}
            contextId={contextId}
            checkboxSelection={false}
            dmsDataFunction={Memebers}
            loading={loading}
            onRowSelectionModelChange={onRowSelectionModelChange}
          />
        </Box>
      )}
      {activeMemberTab === ETabSubjectMemeber.RolesAssigned && (
        <Box>
          <DmsDataGridPro
            data={data}
            contextId={contextId}
            checkboxSelection={false}
            dmsDataFunction={RolesAssign}
            loading={loading}
            onRowSelectionModelChange={onRowSelectionModelChange}
          />
        </Box>
      )}
    </Box>
  );
};

export default ContentSwitcherDrawer;
