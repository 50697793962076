import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import dmsDataGridProPageSlice from "./slices/DmsDataGridPro/dmsDataGridProPageSlice";
import rolesPageSlice from "./slices/Roles/rolesPageSlice";
import { searchFilterApi } from "./api/searchFilterApi";
const reducer = combineReducers({
  dmsDataGridProPage: dmsDataGridProPageSlice,
  rolesPage: rolesPageSlice,
  // [searchFilterApi.reducerPath]: searchFilterApi.reducer,
});

export const store = configureStore({
  reducer,
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(searchFilterApi.middleware),
  // devTools: {
  //   actionSanitizer: (action) => (action.type.startsWith("__rtkq/") ? { ...action, type: "FILTERED_ACTION" } : action),
  // },
  devTools: true,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
// setupListeners(store.dispatch);
