import { useAxios } from "../../utils/axios";
// import { useAxios } from "@1f/react-sdk";
import { DmsEnumSubjectType } from "../models/value/DmsEnum";
import { DmsSubjectDto } from "../models/subjects";

export class MembershipService {
  static getMembersAsync = async (
    token: string,
    subjectId?: string,
    memberTypes?: DmsEnumSubjectType[]
  ): Promise<DmsSubjectDto[]> => {
    const axios = useAxios(token);
    if (!subjectId) return [];
    let params = ``;
    if (!!memberTypes) {
      memberTypes.forEach((mt) => (params += `memberTypes=${mt}&`));
    }
    let response = await axios.get<DmsSubjectDto[]>(
      `/api/v1/Memberships/${subjectId}/members?${params}`
    );
    return response.data;
  };
  static getMembershipsAsync = async (
    token: string,
    subjectId?: string,
    membershipTypes?: DmsEnumSubjectType[]
  ): Promise<DmsSubjectDto[]> => {
    const axios = useAxios(token);
    if (!subjectId) return [];
    let params = ``;
    if (!!membershipTypes) {
      membershipTypes.forEach((mt) => (params += `membershipTypes=${mt}&`));
    }
    let response = await axios.get<DmsSubjectDto[]>(
      `/api/v1/Memberships/${subjectId}/memberships?${params}`
    );
    return response.data;
  };
}
