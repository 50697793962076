import { DmsEnumNamespace } from "../../domain/models/dto/DmsObjects";
import { CustomGridColDef, StandardColumns } from "./StandardColumns";
import { DmsEnumDataType, DmsEnumPropertyId } from "../../domain/DmsConstants";
import { getTextTranslation } from "../../utils/helpers";
import { IDmsViewColumn } from "../../domain/models/dto/DmsViews";
import {
  GridActionsColDef,
  GridColDef,
  GridColumnVisibilityModel,
} from "@mui/x-data-grid-pro";
import { t } from "i18next";
import { IDmsXDataItem } from "../../domain/models/component/DmsComponent";
import {
  IDmsPageRequest,
  ISearchFilter,
  IDmsPageResponseWithInfo,
  IDmsPageResponse,
} from "../../domain/models/pagination/DmsPagination";

export enum DmsEnumContextType {
  Administration = "Admin",
  Classes = "Classes",
  Catalogs = "Catalogs",
  DocumentsList = "DocList",
  PublicationsEdit = "PubEdit",
  PublicationsList = "PubList",
  PublicationsPreview = "PubPreview",
  Roles = "Roles",
}

class DmsDataGridProFunctions {
  static getContextId = (
    contextType: DmsEnumContextType,
    functionName?: string,
    attributeId?: string,
    viewId?: string
  ) => {
    let contextId = contextType + "_" + (functionName || "");
    contextId += "_" + (attributeId || "");
    contextId += "_" + (viewId || "");
    return contextId;
  };

  /** Get columns with page response availability for sorting and filtering */
  static getColumns = (
    pageResponse: IDmsPageResponse<any>,
    normalColumns?: (GridColDef | GridActionsColDef | CustomGridColDef)[],
    customColumns?: (GridColDef | GridActionsColDef | CustomGridColDef)[]
  ) => {
    let columns: (CustomGridColDef | GridColDef)[] = normalColumns ?? [];

    // Custom columns are added as last and pinned to right
    if (customColumns !== undefined) {
      customColumns.forEach((col) => {
        columns.push(col);
      });
    }

    // Only column returned as available field descriptors can be sorted and filtered
    columns.forEach((col) => {
      if (
        pageResponse.availableFieldDescriptors.findIndex(
          (x) => x === col.field
        ) < 0
      ) {
        col.sortable = false;
        col.filterable = false;
      }
    });

    return columns;
  };

  /** Get columns from view */
  static getViewColumns = (
    pageResponse: IDmsPageResponseWithInfo<any, any>,
    customColumns?: (GridColDef | GridActionsColDef)[]
  ) => {
    let columns: any[] = [];
    // This column are used internally and, even hidden, must be kept
    columns.push(StandardColumns.id({ visible: false }));
    columns.push(StandardColumns.objectId({ visible: false }));

    let searchInfo = pageResponse.info;
    if (searchInfo?.viewConfig != null) {
      searchInfo.viewConfig.columnList.forEach((x: IDmsViewColumn) => {
        let column = {
          field: "",
          headerName: getTextTranslation(x.label),
          type: "string",
          editable: false,
          sortable: false,
          filterable: false,
          visible: x.show ?? false,
          n: 0,
          k: "",
        };
        if (x.attributeId != null) {
          if (column.headerName == null) {
            let attrInfo = searchInfo.viewConfig?.attributeInfoList.find(
              (y: { attributeId: string | undefined }) =>
                y.attributeId === x.attributeId
            );
            if (attrInfo != null)
              column.headerName = getTextTranslation(
                attrInfo.label,
                attrInfo.name
              );
          }
          switch (x.dataType) {
            case DmsEnumDataType.Bool:
              column.n = DmsEnumNamespace.AttributeBoolean;
              break;
            case DmsEnumDataType.DateTime:
              column.n = DmsEnumNamespace.AttributeDateTime;
              break;
            case DmsEnumDataType.Int:
              column.n = DmsEnumNamespace.AttributeInt;
              break;
            case DmsEnumDataType.Decimal:
              column.n = DmsEnumNamespace.AttributeDecimal;
              break;
            case DmsEnumDataType.String:
              column.n = DmsEnumNamespace.AttributeString;
              break;
            case DmsEnumDataType.Text:
              column.n = DmsEnumNamespace.AttributeText;
              break;
            case DmsEnumDataType.Enum:
              column.n = DmsEnumNamespace.AttributeEnum;
              break;
            case DmsEnumDataType.Object:
              column.n = DmsEnumNamespace.AttributeObject;
              break;
            case DmsEnumDataType.Subject:
              column.n = DmsEnumNamespace.AttributeSubject;
              break;
          }
          column.k = x.attributeId;
          column.field = `xData_${column.n}_${column.k}`;
          columns.push(column);
        } else if (x.propertyId != null) {
          switch (x.propertyId) {
            case DmsEnumPropertyId.ObjectId:
              column.n = DmsEnumNamespace.PropertyObjectId;
              break;
            case DmsEnumPropertyId.Owner:
              column.n = DmsEnumNamespace.PropertyOwnerId;
              break;
            case DmsEnumPropertyId.Language:
              column.n = DmsEnumNamespace.PropertyLanguage;
              break;
            case DmsEnumPropertyId.FlagSystem:
              column.n = DmsEnumNamespace.PropertyFlagSystem;
              break;
            case DmsEnumPropertyId.FlagPrivate:
              column.n = DmsEnumNamespace.PropertyFlagPrivate;
              break;
            case DmsEnumPropertyId.DateCreation:
              column.n = DmsEnumNamespace.PropertyDateCreation;
              break;
            case DmsEnumPropertyId.DateModify:
              column.n = DmsEnumNamespace.PropertyDateModify;
              break;
            case DmsEnumPropertyId.DateChangeStatus:
              column.n = DmsEnumNamespace.PropertyDateChangeStatus;
              break;
            case DmsEnumPropertyId.ClassId:
              column.n = DmsEnumNamespace.PropertyClassId;
              break;
            case DmsEnumPropertyId.ClassName:
              column.n = 0;
              break;
            case DmsEnumPropertyId.StatusColor:
              column.n = 0;
              break;
          }
          column.field = `xData_${column.n}`;
          columns.push(column);
        }
      });
    }
    // If the view don't specify any columns the Object ID column is displayed
    if (columns.length <= 2) {
      columns.push({
        field: `${DmsEnumNamespace.PropertyObjectId}`,
        headerName: t("Publication ID"),
        type: "string",
        editable: false,
        sortable: true,
        filterable: true,
        visible: true,
        n: DmsEnumNamespace.PropertyObjectId,
        k: "",
      });
    }
    if (customColumns !== undefined) {
      customColumns.forEach((column) => {
        columns.push(column);
      });
    }

    // Only column with n are available for sorting and filtering
    columns.forEach((col) => {
      let bAvailable = col.n != null;
      col.sortable = bAvailable;
      col.filterable = bAvailable;
    });
    return columns;
  };

  static getViewRows = (columns: any[], data: any[]) => {
    let rows: any[] = [];
    data.forEach((r) => {
      let row: any = {};
      columns.forEach((c) => {
        if (c.n == null) row[c.field] = r[c.field];
        else if (r.xData != null) {
          let item = r.xData.find(
            (x: IDmsXDataItem) => x.n === c.n && (x.k === c.k || c.k === "")
          );
          row[c.field] = item?.v ?? "";
        }
      });
      rows.push(row);
    });
    return rows;
  };

  static addSearchFilter = (
    pageRequest: IDmsPageRequest,
    id: string,
    searchFilter: ISearchFilter
  ) => {
    if (pageRequest.searchFilters == null) pageRequest.searchFilters = [];
    let existingFilter = pageRequest.searchFilters.find((x) => x.id === id);
    if (existingFilter != null) {
      existingFilter.logic = searchFilter.logic;
      existingFilter.searchConditions = searchFilter.searchConditions;
      existingFilter.subFilters = searchFilter.subFilters;
    } else {
      searchFilter.id = id;
      pageRequest.searchFilters.push(searchFilter);
    }
  };

  static getViewColumnVisibilityModel = (columns: any[]) => {
    let columnVisibilityModel: GridColumnVisibilityModel = {};
    columns.forEach((c) => {
      columnVisibilityModel[c.field] = c.visible ?? true;
    });
    return columnVisibilityModel;
  };

  static xDataFieldDescriptor = (namespace: DmsEnumNamespace, key?: string) => {
    let result = `xData_${namespace}`;
    if (key != null) result += `_${key}`;
    return [result];
  };
}

export default DmsDataGridProFunctions;
