import { t } from "i18next"

export enum ESourceType {
  Custom = "custom",
  Predefined = "predefined",
}

export enum EModuleType {
  Base = "base",
  Application = "application",
  Functional = "functional",
}
export enum EMandatoryStatus {
  NotRequired = "Notequired",
  Mandatory = "Mandatory",
  StrictMandatory = "StrictMandatory",
}

export const getTranslatedMandatoryStatus = () => {
  return {
    NotRequired: t("EMandatoryStatus.NotRequired"),
    Mandatory: t("EMandatoryStatus.Mandatory"),
    StrictMandatory: t("EMandatoryStatus.StrictMandatory"),
  }
}

export const getTranslatedETabs = () => {
  return {
    To: t("ETabs.To"),
    Cc: t("ETabs.Cc"),
    Ccn: t("ETabs.Ccn"),
  }
}

export enum ETabs {
  To = "To",
  Cc = "Cc",
  Ccn = "Ccn",
}

export enum EMembershipTab {
  UserAndGroupMembers = "1",
  RoleMembers = "2",
  GroupMemberships = "3",
  RoleMemberships = "4",
}

export enum ESelectMode {
  None = 0,
  Single = 1,
  Multiple = 2,
}

export enum EClassAction {
  CopySource = "CopySource",
  CopyDestination = "CopyDestination",
  OverWriteAttribute = "Overwrite",
  //DeleteAttribute = "Delete",
}
export enum EViewAction {
  CopySource = "CopySource",
  CopyDestination = "CopyDestination",
  OverWriteConfiguration = "Overwrite",
}

export enum ETabSubjectMemeber {
  Members = "?#Mmebers",
  ContainingGroups = "?#Containing groups",
  RolesAssigned = "?#Roles assigned",
}

export enum EObjectOperation {
  Read = "readStatuses",
  Delete = "deleteStatuses",
  Edit = "editStatuses",
  Link = "linkStatuses",
  DocCreate = "docCreateStatuses",
  DocModify = "docModifyStatuses",
  DocDelete = "docDeleteStatuses",
  Recipient = "recipientStatuses",
  Catalog = "catalogStatuses",
  Schema = "schemaStatuses",
  Notify = "notifyStatuses",
}

export enum ESecurityTreePaths {
  SpecificPermissions = "/dms/class/operation/*",
  AuthorizationSchema = "/dms/class/schema/*",
  CatalogVisibility = "/dms/catalog/*",
}
export enum DmsRoundingMode {
  Round = 0,
  Floor = 1,
}

export enum EFileOption {
  SiteSetting = "site",
  customSetting = "custom",
}
