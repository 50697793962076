export enum DmsEnumAttributeType {
	Bool = "bool",
	Int = "int",
	Decimal = "decimal",
	String = "string",
	Text = "text",
	DateTime = "datetime",
	Enum = "enum",
	Object = "object",
	Subject = "subject"
}

export enum DmsEnumSubjectType {
	User = 1,
	Group = 2,
	Role = 3
}

export enum DmsEnumSubject {
	Users = "user",
	Groups = "group",
	Roles = "role"
}

export enum EAttributeShortTextFormat {
	String = "string", 		// default
	StringSelection = "string-selection"
}
export enum EAttributeLongTextFormat {
	Text = "text",			// default
	TextHtml = "text-html"
}
export enum EAttributeDateTimeFormat {
	Datetime = "datetime",	// default
	Date = "date",
	Time = "time"
}
export enum EAttributeBoolFormat {
	Bool = "bool",			// default
}
export enum EAttributeIntFormat {
	Int = "int"				// default
}
export enum EAttributeDecimalFormat {
	Decimal = "decimal"		// default
}
export enum EAttributeListFormat {
	EnumSingleList = "enum-single-list",
	EnumSingleSelection = "enum-single-selection",
	EnumSingleRadio = "enum-single-radio",
	EnumMultipleList = "enum-multiple-list",
	EnumMultipleSelection = "enum-multiple-selection",	// default
	EnumMultipleCheckbox = "enum-multiple-checkbox"
}
export enum EAttributePublicationListFormat {
	ObjectSingleSelection = "object-single-selection",
	ObjectMultipleSelection = "object-multiple-selection",	// default
	ObjectMultipleDetail = "object-multiple-detail"
}
export enum EAttributeSubjectFormat {
	SubjectSingleSelection = "subject-single-selection",
	SubjectMultipleSelection = "subject-multiple-selection"	// default
}

export type EAttributeFormats =
	EAttributeSubjectFormat |
	EAttributePublicationListFormat |
	EAttributeListFormat |
	EAttributeDecimalFormat |
	EAttributeIntFormat |
	EAttributeBoolFormat |
	EAttributeShortTextFormat |
	EAttributeLongTextFormat |
	EAttributeDateTimeFormat