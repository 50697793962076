import { useState, useCallback, useEffect, useRef } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useNavigate, Target, Options } from "@1f/react-starter-sdk";
import { useTranslation, useQueryString } from "@1f/react-sdk";

import VaporPage from "@vapor/react-custom/VaporPage";
import VaporHeaderBar from "@vapor/react-custom/VaporHeaderBar";
import {
  Breadcrumbs,
  IconButton,
  Box,
  Link,
  Stack,
  TextField,
  AppBar,
  Toolbar,
  Button,
  Skeleton,
  InputLabel,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@vapor/react-material";
import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";
import Divider from "@mui/material/Divider";

import { UpdateRoleDto } from "../../../../domain/models/subjects";
import { IDmsTextTranslation } from "../../../../domain/models/component/DmsComponent";
import LabelTextField from "../../../../components/LabelTextField/LabelTextField";
import { DmsSubjectsService } from "../../../../domain/services/DmsSubjectService";
import useCustomSnackbar from "../../../../custom-hooks/useCustomSnackbar";
import { useComponentDidMount } from "../../../../custom-hooks/useComponentDidMount";
import EditRoleTabs from "./RoleTabs/EditRoleTabs";

function EditRole() {
  const { navigate } = useNavigate();
  const { getItem } = useQueryString();
  const options: Options = {
    type: "parent",
  };
  const { showSuccess, showError } = useCustomSnackbar();

  // const [subjectId, setSubjectId] = useState("");
  const { id: subjectId } = useParams();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [name, setName] = useState<string | undefined>("");
  const [descr, setDescr] = useState<IDmsTextTranslation[]>([]);
  const [label, setLabel] = useState<IDmsTextTranslation[]>([]);
  const [flagSystem, setFlagsystem] = useState<boolean>();
  const { t } = useTranslation();

  //#region Effects

  const location = useLocation();
  const gridDataRef = useRef(null);

  useEffect(() => {
    const passareDati = location.state;
    gridDataRef.current = passareDati;
    console.log("mi e arrivato", gridDataRef.current);
  }, [location]);

  useComponentDidMount(() => {
    const fetchRoleAsync = async () => {
      setIsLoading(true);
      try {
        let _role = await DmsSubjectsService.getByIdAsync(subjectId!);
        if (!_role) throw Error(`${t("Role not found.")}`);

        setName(_role.name);
        setLabel(_role.label ?? []);
        setDescr(_role.descr ?? []);
        setFlagsystem(_role.flagSystem);
      } catch (e) {
        showError(e);
      } finally {
        setIsLoading(false);
      }
    };

    fetchRoleAsync();
  });

  //#endregion

  //#region Handlers

  const onGoBack = () => {
    //    navigate("/role/config?back=1", { state: gridDataRef.current });
    navigate("rnd::dms::app://role/config?back=1", {
      ...options,
      target: Target.SELF,
    });
  };

  const onCancel = () => {
    // Clear fields or go back ??
    //navigate("/role/config?back=1");
    navigate("rnd::dms::app://role/config?back=1", {
      ...options,
      target: Target.SELF,
    });
  };

  const onSave = async () => {
    let payload: UpdateRoleDto = {
      subjectId: subjectId!,
      name: name,
      label: label,
      descr: descr,
      flagSystem: flagSystem,
    };
    console.log(payload, "paylaod");

    setIsLoading(true);
    try {
      var result = await DmsSubjectsService.updateRoleAsync(payload);
      showSuccess(`${t("Role was updated successfully.")}`);
      onGoBack();
    } catch (e: any) {
      showError(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFullNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let fullName = e.target.value ?? "";
    setName(fullName);
  };

  const handleDescrChange = (labels: IDmsTextTranslation[]) => {
    setDescr(labels);
  };
  const handleLabelChange = (labels: IDmsTextTranslation[]) => {
    setLabel(labels);
  };
  const handleFlagSystemChange = (flag: boolean) => {
    setFlagsystem(flag);
  };
  //#endregion

  const renderSkeleton = useCallback(() => {
    return (
      isLoading && (
        <>
          <Stack direction="row" spacing={2} mt={3}>
            <Box sx={{ p: 1, width: 250 }}>
              <Skeleton variant="rectangular" />
            </Box>

            <Box sx={{ p: 1, width: 300 }}>
              <Skeleton variant="rectangular" />
            </Box>
          </Stack>

          <Divider sx={{ my: 3 }} />

          <Stack direction="column" spacing={2}>
            <Box sx={{ p: 1, width: 250 }}>
              <Skeleton variant="rectangular" />
            </Box>

            <Box sx={{ p: 1, width: "100%" }}>
              <Skeleton variant="rectangular" />
            </Box>
          </Stack>

          <AppBar position="fixed">
            <Toolbar size="medium" variant="regular">
              <Skeleton variant="rectangular" width="100%" />
            </Toolbar>
          </AppBar>
        </>
      )
    );
  }, [isLoading]);

  const renderContent = () => {
    if (isLoading) return null;

    return (
      <>
        {/* Main fields */}
        <Stack direction="column" width={"700px"}>
          {/* Fullname */}
          <Box sx={{ p: 1, display: "flex", gap: 2 }}>
            <TextField
              id="fullName"
              label={t("Role name")}
              variant="outlined"
              value={name}
              onChange={handleFullNameChange}
            />
            <LabelTextField
              title={t("Label")}
              labels={label}
              onChange={handleLabelChange}
            />
          </Box>
          {/* Descr */}
          <Box sx={{ p: 1 }}>
            <InputLabel>{t("Description")}</InputLabel>
            <LabelTextField
              useTextarea
              id="descr"
              title={t("Description")}
              labels={descr}
              onChange={handleDescrChange}
            />
          </Box>
          <InputLabel id="demo-radio-buttons-group-label">
            {t("Do you want to protect the role against modifications?*")}
          </InputLabel>
          <RadioGroup
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            row
          >
            <FormControlLabel
              label={t("Yes")}
              control={
                <Radio
                  checked={flagSystem}
                  onChange={() => handleFlagSystemChange(true)}
                />
              }
              value={true}
            />
            <FormControlLabel
              label={t("No")}
              control={
                <Radio
                  checked={!flagSystem}
                  onChange={() => handleFlagSystemChange(false)}
                />
              }
              value={false}
            />
          </RadioGroup>
        </Stack>

        {/* Tabs content switch */}
        <Divider sx={{ my: 3 }} />

        <EditRoleTabs subjectId={subjectId} />

        {/* Bottom app bar */}
        <AppBar position="fixed">
          <Toolbar size="medium" variant="regular">
            <Button variant="outlined" onClick={onCancel}>
              {t("Cancel")}
            </Button>

            <Button sx={{ ml: "auto" }} variant="contained" onClick={onSave}>
              {t("Save")}
            </Button>
          </Toolbar>
        </AppBar>
      </>
    );
  };

  return (
    <VaporPage sx={{ paddingBottom: "100px" }}>
      {/* Page header */}
      <VaporHeaderBar
        divider
        title=""
        titleItems={
          <Stack direction="column">
            <Typography variant="titleSmall"> {t("Edit Role")} </Typography>
            <Breadcrumbs
              aria-label="breadcrumb"
              separator={<ArrowForwardIos />}
            >
              <Link underline="hover" onClick={onGoBack}>
                {t("Roles")}
              </Link>

              <Typography> {t("Edit Role")} </Typography>
            </Breadcrumbs>
          </Stack>
        }
        leftItems={[
          <IconButton onClick={onGoBack} color="primary" size="medium">
            <ArrowBackIcon></ArrowBackIcon>
          </IconButton>,
        ]}
      />
      <VaporPage.Section>
        {renderSkeleton()}
        {renderContent()}
      </VaporPage.Section>
    </VaporPage>
  );
}

export default EditRole;
