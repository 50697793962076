import { useState, useEffect } from "react";
import { useTokenData, useTranslation } from "@1f/react-sdk";

import HeaderBar from "@vapor/react-custom/VaporHeaderBar";

import { Drawer, IconButton } from "@vapor/react-material";
import TabContext from "@vapor/react-lab/TabContext";
import TabPanel from "@vapor/react-lab/TabPanel";
import ExtendedTabs from "@vapor/react-extended/ExtendedTabs";
import ExtendedTab from "@vapor/react-extended/ExtendedTab";

import CloseIcon from "@mui/icons-material/Close";

import SubjectMembersTable from "../SubjectMembersTable/SubjectMembersTable";
import SubjectMembershipsTable from "../SubjectMembershipsTable/SubjectMembershipsTable";

import { MembershipService } from "../../../domain/services/membershipService";
import { DmsSubjectDto } from "../../../domain/models/subjects";
import { EMembershipTab } from "../../../domain/enums";
import { DmsEnumSubjectType } from "../../../domain/models/value/DmsEnum";
import { getMembershipTabTitle } from "../../../utils/helpers";
import useCustomSnackbar from "../../../custom-hooks/useCustomSnackbar";

interface IProps {
  isOpen: boolean;
  subject?: DmsSubjectDto;
  onClose: () => void;
}

function SubjectMembershipsDrawer({ isOpen, subject, onClose }: IProps) {
  const token = useTokenData();
  const [tabs, setTabs] = useState<EMembershipTab[]>([]);
  const [activeTabId, setActiveTabId] = useState<string>("");

  const [members, setMembers] = useState<DmsSubjectDto[]>([]);
  const [memberships, setMemberships] = useState<DmsSubjectDto[]>([]);
  const { t } = useTranslation();

  const { showError } = useCustomSnackbar();
  //#region Effects

  // effect whenever subject changes
  // 1. Set appropriate tabs based on subject type
  // 2. Re-fetch memberships
  useEffect(() => {
    // 1.
    switch (subject?.type) {
      case DmsEnumSubjectType.User:
        setTabsForUser();
        break;

      case DmsEnumSubjectType.Group:
        setTabsForGroup();
        break;

      case DmsEnumSubjectType.Role:
        setTabsForRoles();
        break;

      default:
        break;
    }

    // 2.
    fetchMembers();
    fetchMemberships();
  }, [subject]);

  const fetchMembers = async () => {
    // Users have no members
    if (subject?.type === DmsEnumSubjectType.User) return;

    let result = await MembershipService.getMembersAsync(token, subject?._id);
    setMembers(result);
  };

  const fetchMemberships = async () => {
    // Users have no members
    try {
      let result = await MembershipService.getMembershipsAsync(
        token,
        subject?._id,
        [DmsEnumSubjectType.Group, DmsEnumSubjectType.Role]
      );
      setMemberships(result);
    } catch (e) {
      showError(e);
    }
  };

  //#endregion

  const setTabsForUser = () => {
    let tabs = [
      EMembershipTab.GroupMemberships,
      EMembershipTab.RoleMemberships,
    ];
    setTabs(tabs);
    setActiveTabId(EMembershipTab.GroupMemberships);
  };

  const setTabsForGroup = () => {
    let tabs = [
      EMembershipTab.UserAndGroupMembers,
      EMembershipTab.GroupMemberships,
      EMembershipTab.RoleMemberships,
    ];
    setTabs(tabs);
    setActiveTabId(EMembershipTab.UserAndGroupMembers);
  };

  const setTabsForRoles = () => {
    let tabs = [
      EMembershipTab.UserAndGroupMembers,
      EMembershipTab.RoleMembers,
      EMembershipTab.RoleMemberships,
    ];
    setTabs(tabs);
    setActiveTabId(EMembershipTab.UserAndGroupMembers);
  };

  const onTabChange = (event: any, newTabId: string) => {
    setActiveTabId(newTabId);
  };

  const renderContent = () => {
    if (!subject?.type || !activeTabId) return;

    switch (activeTabId) {
      case EMembershipTab.UserAndGroupMembers:
        return (
          <SubjectMembersTable
            members={members.filter(
              (m) =>
                m.type === DmsEnumSubjectType.User ||
                m.type === DmsEnumSubjectType.Group
            )}
          />
        );

      case EMembershipTab.RoleMembers:
        return (
          <SubjectMembersTable
            members={members.filter((m) => m.type === DmsEnumSubjectType.Role)}
          />
        );

      case EMembershipTab.GroupMemberships:
        return (
          <SubjectMembershipsTable
            memberships={memberships.filter(
              (m) => m.type === DmsEnumSubjectType.Group
            )}
          />
        );

      case EMembershipTab.RoleMemberships:
        return (
          <SubjectMembershipsTable
            memberships={memberships.filter(
              (m) => m.type === DmsEnumSubjectType.Role
            )}
          />
        );

      default:
        return null;
    }
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={isOpen}
        onClick={(e) => e.stopPropagation()}
        onClose={onClose}
        hideBackdrop
        width={1000}
      >
        <HeaderBar
          title={t("Details")}
          // description="Page description text"
          divider
          rightItems={[
            <IconButton onClick={onClose} color="primary">
              <CloseIcon />
            </IconButton>,
          ]}
        />

        <TabContext value={activeTabId}>
          <ExtendedTabs onChange={onTabChange} size="small" value={activeTabId}>
            {tabs.map((tab) => (
              <ExtendedTab
                key={tab}
                value={tab}
                label={getMembershipTabTitle(subject?.type, tab)}
              />
            ))}
          </ExtendedTabs>

          <TabPanel value={activeTabId}>{renderContent()}</TabPanel>
        </TabContext>
      </Drawer>
    </>
  );
}

export default SubjectMembershipsDrawer;
