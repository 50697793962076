// import { useAxios } from "../../utils/axios";
import { getJSON, putJSON, postJSON } from "@1f/react-sdk";
import { DmsEnumSubjectType } from "../models/value/DmsEnum";
import {
  CreateGroupDto,
  CreateRoleDto,
  DmsSubjectDtoUserCreate,
  DmsSubjectDto,
  UpdateGroupDto,
  UpdateRoleDto,
  UpdateUserDto,
  PermissionSubject,
  IDmsSubjectDtoCurrentUser,
} from "../models/subjects";
import { DmsEnumIncludeSpecialSubjectOption } from "../DmsConstants";
import {
  IDmsPageRequest,
  IDmsPageResponse,
} from "../models/pagination/DmsPagination";

export class DmsSubjectsService {
  //#region ===== CREATE =====

  // static createUserAsync = async (payload: DmsSubjectDtoUserCreate): Promise<DmsSubjectDto> => {
  //   let response = await axios.post<DmsSubjectDto>("/api/v1/DmsSubjects/create/user", payload)
  //   return response.data
  // }

  // static createGroupAsync = async (payload: CreateGroupDto): Promise<DmsSubjectDto> => {
  //   let response = await axios.post<DmsSubjectDto>("/api/v1/DmsSubjects/create/group", payload)
  //   return response.data
  // }

  // static createRoleAsync = async (payload: CreateRoleDto): Promise<DmsSubjectDto> => {
  //   let response = await axios.post<DmsSubjectDto>("/api/v1/DmsSubjects/create/role", payload)
  //   return response.data
  // }

  // //#endregion

  // //#region ===== READ =====

  // static whoamiAsync = async (): Promise<IDmsSubjectDtoCurrentUser> => {
  //   let response = await axios.get<IDmsSubjectDtoCurrentUser>(`/api/v1/DmsSubjects/whoami`)
  //   return response.data
  // }

  static getByIdAsync = async (
    subjectId?: string | null | undefined
  ): Promise<DmsSubjectDto | null> => {
    if (!subjectId) return null;

    let response = await getJSON<DmsSubjectDto>(
      `dms::be::Api://api/v1/DmsSubjects/${subjectId}/read`
    );
    return response;
  };

  // //#endregion

  // //#region ===== UPDATE =====

  // static updateUserAsync = async (payload: UpdateUserDto): Promise<boolean> => {
  //   let response = await axios.put<boolean>(`/api/v1/DmsSubjects/${payload.subjectId}/update/user`, payload)
  //   return response.data
  // }

  // static updateGroupAsync = async (payload: UpdateGroupDto): Promise<boolean> => {
  //   let response = await axios.put<boolean>(`/api/v1/DmsSubjects/${payload.subjectId}/update/group`, payload)
  //   return response.data
  // }

  static updateRoleAsync = async (payload: UpdateRoleDto): Promise<boolean> => {
    let response = await putJSON<boolean>(
      `dms::be::Api://api/v1/DmsSubjects/${payload.subjectId}/update/role`,
      {
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  };

  // //#endregion

  // //#region ===== DELETE =====

  // static deleteOneAsync = async (subjectId: string): Promise<boolean> => {
  //   let response = await axios.delete(`/api/v1/DmsSubjects/${subjectId}/delete`)
  //   return response.data
  // }

  // static deleteManyAsync = async (subjectIdList: string[]): Promise<boolean> => {
  //   let response = await axios.post(`/api/v1/DmsSubjects/delete/many`, subjectIdList);
  //   return response.data
  // }

  // //#endregion

  //#region ===== SEARCH =====

  static searchAsync = async (
    subjectTypes?: DmsEnumSubjectType[],
    pageRequest?: IDmsPageRequest,
    membersOf?: string,
    membershipOf?: string,
    checkedFilter?: boolean,
    includeSpecials?: DmsEnumIncludeSpecialSubjectOption
  ): Promise<IDmsPageResponse<DmsSubjectDto>> => {
    let params = "";
    subjectTypes?.forEach((type) => (params += `subjectTypes=${type}&`));
    if (membersOf !== undefined) params += `membersOf=${membersOf}&`;
    if (membershipOf !== undefined) params += `membershipOf=${membershipOf}&`;
    if (checkedFilter !== undefined && checkedFilter !== false)
      params += `checkedFilter=${checkedFilter}&`;
    if (includeSpecials !== undefined)
      params += `includeSpecials=${includeSpecials}&`;

    // let response = await axios.post<IDmsPageResponse<DmsSubjectDto>>(
    //   `/api/v1/DmsSubjects/search?${params}`,
    //   pageRequest
    // );

    const response = await postJSON<IDmsPageResponse<DmsSubjectDto>>(
      `dms::be::Api://api/v1/DmsSubjects/search?${params}`,
      {
        body: JSON.stringify(pageRequest),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  };
  //---------------------------------------------------------------------------
  //////////// static searchAsync = async (
  //   subjectTypes?: DmsEnumSubjectType[],
  //   pageRequest?: IDmsPageRequest,
  //   membersOf?: string,
  //   membershipOf?: string,
  //   checkedFilter?: boolean,
  //   includeSpecials?: DmsEnumIncludeSpecialSubjectOption
  // ): Promise<IDmsPageResponse<DmsSubjectDto>> => {

  //   const { axios } = useAxios();
  //   let typedResponse: IDmsPageResponse<DmsSubjectDto> = {
  //     count: 0,
  //     page: 0,
  //     pageSize: 0,
  //     data: [],
  //     availableFieldDescriptors: [],
  //   };
  //   let params = "";
  //   subjectTypes?.forEach((type) => (params += `subjectTypes=${type}&`));
  //   if (membersOf !== undefined) params += `membersOf=${membersOf}&`;
  //   if (membershipOf !== undefined) params += `membershipOf=${membershipOf}&`;
  //   if (checkedFilter !== undefined && checkedFilter !== false)
  //     params += `checkedFilter=${checkedFilter}&`;
  //   if (includeSpecials !== undefined)
  //     params += `includeSpecials=${includeSpecials}&`;
  //   axios
  //     .post(`/api/v1/DmsSubjects/search?${params}`, pageRequest)
  //     .then((response) => {
  //       typedResponse = response.data as IDmsPageResponse<DmsSubjectDto>;
  //     })
  //     .catch(console.error);
  //   return typedResponse;
  //   // return response.data
  // };

  // static searchAsync2 = async (subjectTypes?: DmsEnumSubjectType[], roleSecurityPaths?: number, pageRequest?: IDmsPageRequest, membersOf?: string, membershipOf?: string, checkedFilter?: boolean,): Promise<IDmsPageResponse<DmsSubjectDto>> => {
  //   let params = ""
  //   subjectTypes?.forEach((type) => (params += `subjectTypes=${type}&`))
  //   if (membersOf !== undefined) params += `membersOf=${membersOf}&`
  //   if (membershipOf !== undefined) params += `membershipOf=${membershipOf}&`
  //   if (checkedFilter !== undefined) params += `checkedFilter=${checkedFilter}&`
  //   if (roleSecurityPaths !== undefined) params += `roleSecurityPaths=${roleSecurityPaths}&`
  //   params += `specificPermissionClasses=2&includeSpecials=2`
  //   let response = await axios.post<IDmsPageResponse<DmsSubjectDto>>(`/api/v1/DmsSubjects/search?${params}`, pageRequest)
  //   return response.data
  // }

  // static getPermissions = async (id: string): Promise<IDmsPageResponse<PermissionSubject>> => {
  //   let response = await axios.get<IDmsPageResponse<PermissionSubject>>(`/api/v1/DmsSecurityTreePermissions/${id}/read`)
  //   return response.data
  // }

  // static searchSpecificPermissionClasses = async (subjectTypes?: DmsEnumSubjectType[], specificPermissionClasses?: number, pageRequest?: IDmsPageRequest, membersOf?: string, membershipOf?: string, checkedFilter?: boolean,includeSpecials?:number): Promise<IDmsPageResponse<DmsSubjectDto>> => {
  //   let params = ""
  //   subjectTypes?.forEach((type) => (params += `subjectTypes=${type}&`))
  //   if (membersOf !== undefined) params += `membersOf=${membersOf}&`
  //   if (membershipOf !== undefined) params += `membershipOf=${membershipOf}&`
  //   if (checkedFilter !== undefined) params += `checkedFilter=${checkedFilter}&`
  //   if (specificPermissionClasses !== undefined) params += `specificPermissionClasses=${specificPermissionClasses}&`
  //   if (includeSpecials !== undefined) params += `includeSpecials=${includeSpecials}&`
  //   let response = await axios.post<IDmsPageResponse<DmsSubjectDto>>(`/api/v1/DmsSubjects/search?${params}`, pageRequest)
  //   return response.data
  // }

  // static updatePermissions = async (subjectId: string, pathToAdd: string[], pathToRemove: string[], reset?: boolean): Promise<IDmsPageResponse<DmsSubjectDto>> => {

  //   let pageRequest = {
  //     subjectId: subjectId,
  //     pathToAdd: pathToAdd,
  //     pathToRemove: pathToRemove,
  //     reset: reset ?? false
  //   }
  //   let response = await axios.patch<IDmsPageResponse<DmsSubjectDto>>(`api/v1/DmsSecurityTreePermissions/update/role/paths`, pageRequest)
  //   return response.data
  // }

  // //#endregion

  // //TODO: remove
  // static getUsersAndGroupsAsyncTEST = async (pageRequest: IDmsPageRequest, type: number): Promise<IDmsPageResponse<DmsSubjectDto>> => {
  //   let params = `subjectTypes=${type}&`
  //   let response = await axios.post<IDmsPageResponse<DmsSubjectDto>>(`/api/v1/DmsSubjects/search?${params}`, pageRequest)
  //   return response.data
  // }

  // //TODO: remove
  // static getAllAsync = async (subjectTypes?: DmsEnumSubjectType[]): Promise<DmsSubjectDto[]> => {
  //   let params = ""
  //   subjectTypes?.forEach((type) => (params += `subjectTypes=${type}&`))

  //   let response = await axios.post<IDmsPageResponse<DmsSubjectDto>>(`/api/v1/DmsSubjects/search?${params}`, {})
  //   return response.data.data
  // }
}
