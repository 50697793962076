import React from "react";
import { FC, useEffect, useRef, useState } from "react";
import DataGridPro from "@vapor/react-x-data-grid/DataGridPro";
import {
  GridActionsColDef,
  GridApiPro,
  GridCallbackDetails,
  GridColumnMenu,
  GridColumnMenuItemProps,
  GridColumnMenuProps,
  GridColumnOrderChangeParams,
  GridColumnResizeParams,
  GridColumnVisibilityModel,
  GridFilterModel,
  GridLogicOperator,
  GridPaginationModel,
  GridPinnedColumns,
  GridPreferencePanelParams,
  GridRenderCellParams,
  GridRowId,
  GridRowOrderChangeParams,
  GridRowSelectionModel,
  GridSortModel,
  GridFilterItem,
  MuiEvent,
} from "@vapor/react-x-data-grid/node_modules/@mui/x-data-grid-pro";

import {
  getDefaultDmsPageRequest,
  ISearchFilter,
} from "../../domain/models/pagination/DmsPagination";
import {
  IDmsPageRequest,
  IDmsPageResponse,
} from "../../domain/models/pagination/DmsPagination";
import { VaporEmptyState } from "@vapor/react-custom";
import {
  useTranslation,
  useStateSession,
  useBrowserStorage,
} from "@1f/react-sdk";
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  Radio,
} from "@vapor/react-material";
import {
  booleanFilterOperators,
  datetimeFilterOperators,
  decimalFilterOperators,
  emptyFilterOperators,
  idFilterOperators,
  intFilterOperators,
  labelDescrFilterOperators,
  stringFilterOperators,
} from "./FilterOperators";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripLines } from "@fortawesome/pro-regular-svg-icons/faGripLines";
import { faArrowsRotate } from "@fortawesome/pro-solid-svg-icons";
import { IDmsOrderByItem } from "../../domain/models/pagination/DmsPagination";

// import { useDispatch, useSelector } from "react-redux";
// import { AppDispatch, RootState } from "../../store/store";
// import {
//   // setPaginationModel,
//   setFilterModel,
//   setRowSelectionModel,
//   resetPage,
// } from "../../store/slices/DmsDataGridPro/dmsDataGridProPageSlice";
import { isBackwardNavigation } from "../../utils/helpers";
import { LocaleTextConstant } from "./LocaleTextCostant";

// interface IDmsDataGridProColumn {
//   field: string
//   headerName?: any
//   editable: boolean
//   width?: number
//   type: string
//   filterOperators?: any
//   filterable?: boolean
//   disableReorder?: boolean //if not specified is true
//   pinnable?: boolean //if not specified is true
//   sortable?: boolean //if not specified is true
//   resizable?: boolean
//   renderCell?: any
// }

export interface IExtendedGridActionsColDef
  extends GridActionsColDef<any, any, any> {
  n?: number;
  k?: string;
}

interface IDmsDataGridPro {
  apiRef?: React.MutableRefObject<GridApiPro>;
  data: {
    columns: IExtendedGridActionsColDef[];
    columnVisibilityModel: GridColumnVisibilityModel;
    rows: [];
    pageRequest?: IDmsPageRequest;
    pageResponse?: IDmsPageResponse<any>;
  };
  // columnVisibilityModel?: GridColumnVisibilityModel
  dmsDataFunction(): void;
  loading: boolean;
  contextId: string;
  pagination?: boolean; //if is set on false, the table will be with only 1page as a list. else, if you want to customize page size use the props below
  defaultPageSize?: number;
  pageSizeOptions?: number[];
  paginationModel?: GridPaginationModel;
  keepNonExistentRowsSelected?: boolean;
  checkboxSelection?: boolean;
  radioSelection?: boolean;
  disableRowSelectionOnClick?: boolean;
  onRowSelectionModelChange?: (
    rowSelectionModel: GridRowSelectionModel
  ) => void;
  rowSelectionModel?: GridRowSelectionModel;
  hideFooter?: boolean;
  onRowClick?: any;
  getRowId?: any;
  rowReordering?: boolean;
  onRowOrderChange?: (rowOrderChange: GridRowOrderChangeParams) => void;
}

type Widths = {
  [key: string]: number;
};
type Data = {
  columns: IExtendedGridActionsColDef[];
  columnVisibilityModel: GridColumnVisibilityModel;
  rows: any[];
  pageRequest: IDmsPageRequest;
  pageResponse: any;
};

interface LocalStorageGridData {
  widths: Widths;
  columnVisibilityModel: GridColumnVisibilityModel;
  columnOrder: string[];
  sortModel: GridSortModel;
  pageSize: number;
  pinnedColumns: GridPinnedColumns;
}

/**
 * DmsDataGridPro is a wrapper for MUI DataGridPro to semplify server side paging and data management
 * @param props
 * @returns
 */
const DmsDataGridPro: FC<IDmsDataGridPro> = (props) => {
  const { t } = useTranslation("dms");
  const localStorageGrid = useRef(props.contextId);
  const previousContextId = useRef(props.contextId);
  const previousPaginationModel = useRef<GridPaginationModel | null>({
    page: 0,
    pageSize: 5,
  });
  const previousFilterModel = useRef<GridFilterModel | null>({ items: [] });
  const previousRowSelectionModel = useRef<GridRowSelectionModel | null>([]);
  //#region DefinitionsState
  // const dispatch = useDispatch<AppDispatch>();
  // const paginationModel = useSelector((state: RootState) => {
  //   if (state.dmsDataGridProPage[props.contextId])
  //     return state.dmsDataGridProPage[props.contextId].paginationModel;
  //   else return { page: 0, pageSize: 5 };
  // });
  const [nameSession, setNameSession] = useState<string>(
    "DataGrid[" + props.contextId + "]"
  );
  const [paginationModel, setPaginationModel] =
    useStateSession<GridPaginationModel>(nameSession + ".paginationModel");
  // const filterModel = useSelector((state: RootState) => {
  //   // console.log("Current state redux :", state) // Aggiungi questo log
  //   return state.dmsDataGridProPage[props.contextId]
  //     ? state.dmsDataGridProPage[props.contextId].filterModel ?? { items: [] }
  //     : { items: [] };
  // });
  const [filterModel, setFilterModel] = useStateSession<GridFilterModel>(
    nameSession + ".filterModel"
  );

  const [oldPageRequest, setOldPageRequest] = useState<IDmsPageRequest>(
    getDefaultDmsPageRequest()
  );
  const [data, setData] = useState<Data>({
    columns: [],
    columnVisibilityModel: {},
    rows: [],
    pageRequest: getDefaultDmsPageRequest(),
    pageResponse: null,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [rowCount, setRowCount] = useState<number>(0);
  // const [paginationModel, setPaginationModel] = useState<GridPaginationModel>(() => {
  //   let pageSize = 5
  //   let localStorageGridData: LocalStorageGridData = JSON.parse(localStorage.getItem(localStorageGrid.current) ?? "null")
  //   if (localStorageGridData) pageSize = localStorageGridData.pageSize ?? props.defaultPageSize ?? 5
  //   else pageSize = props.defaultPageSize ?? (props.paginationModel ? props.paginationModel.pageSize : 5)

  //   return {
  //     pageSize: pageSize,
  //     page: props.paginationModel ? props.paginationModel.page : 0,
  //   }
  // })

  // const [filterModel, setFilterModel] = useState<GridFilterModel>(props.filterModel || { items: [] })
  // const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [] })
  // const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>(props.rowSelectionModel || [])

  const radioSelection = useRef<GridRowId>((props.rowSelectionModel || [])[0]);
  // const rowSelectionModel: GridRowSelectionModel = useSelector(
  //   (state: RootState) => {
  //     // console.log("Current state redux :", state) // Aggiungi questo log
  //     let result = state.dmsDataGridProPage[props.contextId]
  //       ? state.dmsDataGridProPage[props.contextId].rowSelectionModel ?? []
  //       : [];
  //     radioSelection.current = result[0];
  //     return [...result];
  //   }
  // );
  const [rowSelectionModel, setRowSelectionModel] =
    useStateSession<GridRowSelectionModel>(nameSession + ".rowSelectionModel");

  const [sortModel, setSortModel] = React.useState<GridSortModel>(() => {
    let localStorageGridData: LocalStorageGridData = JSON.parse(
      localStorage.getItem(localStorageGrid.current) ?? "null"
    );
    if (localStorageGridData) {
      let model = localStorageGridData.sortModel ?? [];
      return model;
    } else return [];
  });

  const [pinnedColumns, setPinnedColumns] = React.useState(() => {
    // Carica le colonne bloccate dal localstorage
    let localStorageGridData: LocalStorageGridData = JSON.parse(
      localStorage.getItem(localStorageGrid.current) ?? "null"
    );
    if (localStorageGridData) {
      let pinnedColumns = localStorageGridData.pinnedColumns ?? {};
      return pinnedColumns;
    } else return { left: [], right: [] };
  });

  const [columnVisibilityModel, setColumnVisibilityModel] = useState(() => {
    let localStorageGridData: LocalStorageGridData = JSON.parse(
      localStorage.getItem(localStorageGrid.current) ?? "null"
    );
    if (localStorageGridData) {
      let columnVisibilityModel =
        localStorageGridData.columnVisibilityModel ?? {};
      return columnVisibilityModel;
    } else return {};
  });

  // const [columnOrder, setColumnOrder] = React.useState<string[]>(() => {
  //   // Carica l'ordine delle colonne dal localStorage
  //   let localStorageGridData: LocalStorageGridData = JSON.parse(localStorage.getItem(localStorageGrid.current) ?? "null")
  //   let newOrder = localStorageGridData ? (localStorageGridData.columnOrder ? localStorageGridData.columnOrder : data.columns.map((col) => col.field)) : data.columns.map((col) => col.field)
  //   return newOrder
  // })

  const columnOrder = useRef<string[]>([]);
  const widths = useRef<Widths>({});

  const actionCols = useRef<string[]>([]);

  const orderingIcon = () => {
    return <FontAwesomeIcon color="#0090D1" size="lg" icon={faGripLines} />;
  };

  //#endregion

  //#region Functions
  // const readColumnsWidth = () => {
  //   let columnsWidthReaded: Widths = {}
  //   if (typeof props.data !== "undefined")
  //     if (typeof props.data.columns !== "undefined") {
  //       props.data.columns.forEach((col) => {
  //         columnsWidthReaded[col.field] = col.width!
  //       })
  //     }
  //   return columnsWidthReaded
  // }
  const getSortings = (sModel: GridSortModel) => {
    //check the sortModel, for the moment, manage only one sort
    let fieldsToSort: IDmsOrderByItem[] = [];
    if (sModel.length === 0) {
      fieldsToSort = [];
    } else {
      for (let i = 0; i < sModel.length; i++) {
        let sortDirection = 0;
        if (sModel[i].sort === "asc") {
          sortDirection = 1;
        } else if (sModel[i].sort === "desc") {
          sortDirection = -1;
        }
        let fieldToSort: IDmsOrderByItem = {
          fieldDescriptor: [],
          direction: 0,
        };
        fieldToSort.fieldDescriptor = [sModel[i].field];
        fieldToSort.direction = sortDirection;
        fieldsToSort = [...fieldsToSort, fieldToSort];
      }
    }
    return fieldsToSort;
  };

  // //All filter combinations to be added to the columns
  // // add filters to every column type
  const applyFilterOperators = (columns: any[]) => {
    //return columns;
    if (props.radioSelection) {
      if (columns.find((x) => x.field === "radioSelection") == null) {
        columns.unshift({
          field: "radioSelection",
          headerName: "",
          type: "",
          width: 50,
          align: "center",
          editable: false,
          renderCell: (params: GridRenderCellParams<any, boolean>) => {
            return (
              <Radio
                checked={radioSelection.current === params.row.id}
                value={params.row.id}
              />
            );
          },
        });
      }
    }
    return columns.map((column) => {
      switch (column.type) {
        case "id":
          return { ...column, filterOperators: idFilterOperators };
        case "text-translation":
          return { ...column, filterOperators: labelDescrFilterOperators };

        case "string":
          return { ...column, filterOperators: stringFilterOperators };
        case "int":
          return { ...column, filterOperators: intFilterOperators };
        case "bool":
          return { ...column, filterOperators: booleanFilterOperators };
        case "datetime":
          return { ...column, filterOperators: datetimeFilterOperators };
        case "decimal":
          return { ...column, filterOperators: decimalFilterOperators };

        default:
          return { ...column, filterOperators: emptyFilterOperators };
      }
    });
  };
  const resetGrid = () => {
    let lsWidths: Widths = {};
    let columns = [...data.columns];
    columns.forEach((col) => {
      lsWidths[col.field] = col.width!;
    });
    let lsVisibilityModel: GridColumnVisibilityModel = {};
    columns.forEach((col) => {
      lsVisibilityModel[col.field] = true;
    });
    const colOrder = columns.map((col) => {
      return col.field;
    });
    const lsGridDataToSave: LocalStorageGridData = {
      widths: lsWidths,
      columnVisibilityModel: lsVisibilityModel,
      columnOrder: colOrder,
      sortModel: [],
      pageSize: 5,
      pinnedColumns: {
        left: [],
        right: [],
      },
    };
    localStorage.setItem(
      localStorageGrid.current,
      JSON.stringify(lsGridDataToSave)
    );
    setData({ ...data, columns: columns });
    setRefWidths(lsWidths);
    setColumnVisibilityModel(lsVisibilityModel);
    setRefColumnOrder(colOrder);
    setSortModel([]);
    // dispatch(
    //   setPaginationModel({
    //     contextId: props.contextId,
    //     paginationModel: { page: paginationModel.page, pageSize: 5 },
    //   })
    // );
    setPaginationModel({ page: paginationModel!.page, pageSize: 5 });
    let newPageRequest: IDmsPageRequest = data.pageRequest;
    newPageRequest.page = paginationModel ? paginationModel.page : 0;
    newPageRequest.page!++;
    newPageRequest.pageSize = getDefaultDmsPageRequest().pageSize;
    newPageRequest.orderBy = [];
    props.data.pageRequest = newPageRequest;

    setPinnedColumns({ left: [], right: [] });
    onFilterModelChange({ items: [] });
    props.dmsDataFunction();
  };

  const orderedColumns = (columnsToOrder: IExtendedGridActionsColDef[]) => {
    const orderField: { [key: string]: number } = {};
    columnOrder.current.forEach((field, index) => {
      orderField[field] = index;
    });
    const newColumnsOrdered = columnsToOrder.sort(
      (a: IExtendedGridActionsColDef, b: IExtendedGridActionsColDef) => {
        const aOrder = orderField[a.field];
        const bOrder = orderField[b.field];

        if (aOrder !== undefined && bOrder !== undefined) {
          // Se entrambi gli elementi sono nell'ordine, confronta i loro indici
          return aOrder - bOrder;
        } else if (aOrder !== undefined) {
          // Se solo a è nell'ordine, viene prima
          return -1;
        } else if (bOrder !== undefined) {
          // Se solo b è nell'ordine, viene prima
          return 1;
        } else {
          // Se nessuno è nell'ordine, mantieni l'ordine originale (opzionale)
          return 0;
        }
      }
    );
    return newColumnsOrdered;
  };

  const setRefWidths = (newWidths: Widths) => {
    widths.current = newWidths;
    const lsGridDataToSave: LocalStorageGridData = {
      widths: widths.current,
      columnVisibilityModel: columnVisibilityModel,
      columnOrder: columnOrder.current,
      sortModel: sortModel,
      pageSize: paginationModel ? paginationModel.pageSize : 5,
      pinnedColumns: pinnedColumns,
    };
    localStorage.setItem(
      localStorageGrid.current,
      JSON.stringify(lsGridDataToSave)
    );
  };

  const setRefColumnOrder = (newColumnOrder: string[]) => {
    columnOrder.current = newColumnOrder;
    const lsGridDataToSave: LocalStorageGridData = {
      widths: widths.current,
      columnVisibilityModel: columnVisibilityModel,
      columnOrder: columnOrder.current,
      sortModel: sortModel,
      pageSize: paginationModel ? paginationModel.pageSize : 5,
      pinnedColumns: pinnedColumns,
    };
    localStorage.setItem(
      localStorageGrid.current,
      JSON.stringify(lsGridDataToSave)
    );
  };

  //#endregion

  //#region useEffect
  useEffect(() => {
    previousFilterModel.current = filterModel;
    previousRowSelectionModel.current = rowSelectionModel;
    previousPaginationModel.current = paginationModel;
    setNameSession("DataGrid[" + props.contextId + "].paginationModel");
  }, [props.contextId]);

  useEffect(() => {
    localStorageGrid.current = props.contextId;
    const arrPC = previousContextId.current.split("_");
    const arrNC = props.contextId.split("_");
    if (arrPC[0] == arrNC[0] && arrPC[1] == arrNC[1] && arrPC[2] == arrNC[2]) {
      if (arrPC[3] == arrNC[3]) return;
      else {
        setFilterModel(previousFilterModel.current);
        setRowSelectionModel(previousRowSelectionModel.current);
        setPaginationModel(previousPaginationModel.current);
      }
    } else {
      setFilterModel({ items: [] });
      setRowSelectionModel([]);
      setPaginationModel({ page: 0, pageSize: 5 });
    }
    // Carica le colonne bloccate dal localstorage
    let localStorageGridData: LocalStorageGridData = JSON.parse(
      localStorage.getItem(localStorageGrid.current) ?? "null"
    );
    if (localStorageGridData) {
      let lsWidths = localStorageGridData.widths ?? {};
      // widths.current = lsWidths
      setRefWidths(lsWidths);
      let lsColumnVisibilityModel =
        localStorageGridData.columnVisibilityModel ?? {};
      setColumnVisibilityModel(lsColumnVisibilityModel);
      let lsColumnOrder = localStorageGridData.columnOrder ?? {};
      setRefColumnOrder(lsColumnOrder);
      let lsSortModel = localStorageGridData.sortModel ?? {};
      setSortModel(lsSortModel);
      let lsPaginationModel: GridPaginationModel = {
        page: paginationModel ? paginationModel.page : 0,
        pageSize:
          localStorageGridData.pageSize ??
          (paginationModel ? paginationModel.pageSize : 5),
      };
      // dispatch(
      //   setPaginationModel({
      //     contextId: props.contextId,
      //     paginationModel: lsPaginationModel,
      //   })
      // );
      setPaginationModel(lsPaginationModel);
      let newPageRequest: IDmsPageRequest = data.pageRequest;
      newPageRequest.page = getDefaultDmsPageRequest().page;
      newPageRequest.pageSize = getDefaultDmsPageRequest().pageSize;
      // dispatch(
      //   setFilterModel({
      //     contextId: props.contextId,
      //     filterModel: !isBackwardNavigation() ? { items: [] } : filterModel,
      //   })
      // );
      setFilterModel(!isBackwardNavigation() ? { items: [] } : filterModel);
      // dispatch(setFilterModel({ contextId: props.contextId, filterModel: filterModel }))
      // dispatch(setFilterModel({ contextId: props.contextId, filterModel: { items: [] } }))
      // if (typeof props.onFilterModelChange !== "undefined") {
      //   props.onFilterModelChange(filterModel)
      // }
      let searchFilter: ISearchFilter = {
        id: "grid_column_filter",
        logic: "and",
        searchConditions: [],
      };
      newPageRequest.searchFilters = [searchFilter];
      props.data.pageRequest = newPageRequest;
      // if (typeof props.onPaginationModelChange !== "undefined") props.onPaginationModelChange(lsPaginationModel)
      let lsPinnedColumns = localStorageGridData.pinnedColumns ?? {};
      setPinnedColumns(lsPinnedColumns);
    }
  }, [nameSession]);

  useEffect(() => {
    // dispatch(
    //   setRowSelectionModel({
    //     contextId: props.contextId,
    //     rowSelectionModel: props.rowSelectionModel!,
    //   })
    // );
    setRowSelectionModel(props.rowSelectionModel!);
  }, [props.rowSelectionModel]);

  useEffect(() => {
    radioSelection.current = rowSelectionModel ? rowSelectionModel[0] : -1;
    previousFilterModel.current = filterModel;
    previousRowSelectionModel.current = rowSelectionModel;
    previousPaginationModel.current = paginationModel;

    // Carica la larghezza delle colonne dal localstorage
    let localStorageGridData: LocalStorageGridData = JSON.parse(
      localStorage.getItem(localStorageGrid.current) ?? "null"
    );
    if (localStorageGridData) {
      setRefWidths(localStorageGridData.widths ?? {});
    } else {
      setRefWidths({});
    }
    setRefColumnOrder(
      localStorageGridData
        ? localStorageGridData.columnOrder
          ? localStorageGridData.columnOrder
          : data.columns.map((col) => col.field)
        : data.columns.map((col) => col.field)
    );

    //if return from editpage, is necessary set pagemodel and filter from redux
    //otherwise set with localstorage (only for page number) or with default
    let newPageRequest: IDmsPageRequest = data.pageRequest;
    let pgModel: GridPaginationModel = { page: 0, pageSize: 5 };
    //if present in redux I use that
    //otherwise I check in localstorage pagesize
    //otherwise in props otherwise default page 0 and pagesize 5
    if (!isBackwardNavigation()) {
      //      dispatch(resetPage({ contextId: props.contextId }));
      setFilterModel({ items: [] });
      setRowSelectionModel([]);
      setPaginationModel({ page: 0, pageSize: 5 });

      if (localStorageGridData) {
        pgModel.pageSize =
          localStorageGridData.pageSize ?? props.defaultPageSize ?? 5;
        newPageRequest.pageSize =
          localStorageGridData.pageSize ?? props.defaultPageSize ?? 5;
      } else {
        pgModel.pageSize =
          props.defaultPageSize ??
          (props.paginationModel ? props.paginationModel.pageSize : 5);
        newPageRequest.pageSize =
          props.defaultPageSize ??
          (props.paginationModel ? props.paginationModel.pageSize : 5);
      }
      pgModel.page = props.paginationModel ? props.paginationModel.page : 0;
      if (props.pagination ?? true)
        newPageRequest.page = props.paginationModel
          ? props.paginationModel!.page++
          : 1;
      else newPageRequest.page = 0;
      // dispatch(
      //   setPaginationModel({
      //     contextId: props.contextId,
      //     paginationModel: pgModel,
      //   })
      // );
      setPaginationModel(pgModel);
      // dispatch(
      //   setRowSelectionModel({
      //     contextId: props.contextId,
      //     rowSelectionModel: props.rowSelectionModel ?? [],
      //   })
      // );
      setRowSelectionModel(props.rowSelectionModel ?? []);
    } else {
      newPageRequest.page = paginationModel ? paginationModel.page : 0;
      if (props.pagination ?? true) newPageRequest.page!++;
      newPageRequest.pageSize = paginationModel ? paginationModel.pageSize : 5;

      let searchFilter: ISearchFilter = { logic: "and", searchConditions: [] };
      searchFilter.logic =
        filterModel!.logicOperator === GridLogicOperator.Or ? "or" : "and";
      filterModel!.items.forEach((value: GridFilterItem, index: number) => {
        searchFilter.searchConditions!.push({
          fieldDescriptor: [value.field],
          condition: value.operator,
          values: value.value,
        });
      });
      newPageRequest.searchFilters = [searchFilter];
      if (props.onRowSelectionModelChange && props.rowSelectionModel) {
        props.onRowSelectionModelChange(rowSelectionModel!);
      }
    }
    // Load initial sort saved in local storage
    if (sortModel) {
      newPageRequest.orderBy = getSortings(sortModel);
    }
    if (props.data.pageRequest !== newPageRequest) {
      props.data.pageRequest = newPageRequest;
    }
    if (props.checkboxSelection) {
      if (pinnedColumns.left) {
        let isAlready = true;
        const appPinnedColumns = { ...pinnedColumns };
        if (!appPinnedColumns.left!.includes("__check__")) {
          isAlready = false;
          appPinnedColumns.left!.unshift("__check__");
        }
        if (!isAlready) {
          setPinnedColumns(appPinnedColumns);
        }
      } else setPinnedColumns({ ...pinnedColumns, left: ["__check__"] });
    }
  }, []);

  useEffect(() => {
    if (props.data.columns.length === 0) {
      setData({ ...data, rows: props.data.rows });
      return;
    }
    let page = 0;
    let pageSize = paginationModel ? paginationModel.pageSize : 5;
    let totalCount = 0;
    if (props.data.pageResponse != null) {
      page = props.data.pageResponse.page;
      if (page > 0) page--;
      else page = 0;
      pageSize = props.data.pageResponse.pageSize;
      totalCount = props.data.pageResponse.totalCount ?? 0;
    }
    props.data.columns.forEach((col) => {
      if (typeof widths.current[col.field] !== "undefined") {
        col.width = widths.current[col.field];
      }
    });
    let appColVisibility = columnVisibilityModel;
    props.data.columns.forEach((col) => {
      if (col.type !== "actions") {
        col.filterable =
          props.rowReordering ?? false ? false : col.filterable ?? true;
        col.hideable = props.data.columnVisibilityModel[col.field];
        col.sortable = col.sortable ?? true;
        col.pinnable = true;
        col.resizable = true;
      } else {
        if (!actionCols.current.includes(col.field))
          actionCols.current.push(col.field);
        col.filterable = false;
        col.hideable = false;
        col.sortable = false;
        col.pinnable = false;
        col.resizable = false;
        if (pinnedColumns.right) {
          let isAlready = true;
          const appPinnedColumns = { ...pinnedColumns };
          if (!appPinnedColumns.right!.includes(col.field)) {
            isAlready = false;
            appPinnedColumns.right!.push(col.field);
          }
          if (!isAlready) {
            setPinnedColumns(appPinnedColumns);
          }
        } else setPinnedColumns({ ...pinnedColumns, right: [col.field] });
      }
      if (!props.data.columnVisibilityModel[col.field])
        appColVisibility[col.field] = false;
      else if (typeof appColVisibility[col.field] === "undefined")
        appColVisibility[col.field] = true;
    });
    if (Object.keys(columnOrder.current).length === 0)
      setRefColumnOrder(
        props.data.columns.map((col) => {
          return col.field;
        })
      );
    setData({
      ...data,
      columnVisibilityModel: props.data.columnVisibilityModel,
      columns: orderedColumns(applyFilterOperators(props.data.columns)),
      rows: props.data.rows,
    });
    // if (Object.keys(widths).length === 0) setWidths(readColumnsWidth())
    // setPaginationModel({ page: page, pageSize: pageSize })
    // dispatch(
    //   setPaginationModel({
    //     contextId: props.contextId,
    //     paginationModel: { page: page, pageSize: pageSize },
    //   })
    // );
    setPaginationModel({ page: page, pageSize: pageSize });
    // if (typeof props.onPaginationModelChange !== "undefined") props.onPaginationModelChange({ page: page, pageSize: pageSize })
    setRowCount(totalCount);
    let lsWidth: Widths = {};
    props.data.columns.forEach((col) => {
      lsWidth[col.field] = col.width!;
    });
    setRefWidths(lsWidth);
  }, [props.data.columns, props.data.columnVisibilityModel, props.data.rows]);

  useEffect(() => {
    const lsGridDataToSave: LocalStorageGridData = {
      widths: widths.current,
      columnVisibilityModel: columnVisibilityModel,
      columnOrder: columnOrder.current,
      sortModel: sortModel,
      pageSize: paginationModel ? paginationModel.pageSize : 5,
      pinnedColumns: pinnedColumns,
    };
    localStorage.setItem(
      localStorageGrid.current,
      JSON.stringify(lsGridDataToSave)
    );
  }, [
    widths,
    columnVisibilityModel,
    columnOrder,
    sortModel,
    paginationModel ? paginationModel.page : 0,
    pinnedColumns,
  ]);

  // useEffect(() => {
  //   // This effect runs when the component mounts and dismounts
  //   return () => {
  //     dispatch(restorePageState({ contextId: props.contextId }))
  //   }
  // }, [dispatch])

  //#endregion

  //#region HandleFunctions

  const onColumnVisibilityModelChange = (
    newColumnVisibilityModel: GridColumnVisibilityModel,
    details: GridCallbackDetails<any>
  ) => {
    // alert("handleColumnVisibilityModelChange")
    // setData({ ...data, columnVisibilityModel: newColumnVisibilityModel })
    setColumnVisibilityModel(newColumnVisibilityModel);
  };

  const onColumnWidthChange = (resizeCols: GridColumnResizeParams) => {
    const updatedWidths: Widths = {
      ...widths.current,
      [resizeCols.colDef.field]: resizeCols.width,
    };
    setRefWidths(updatedWidths);
  };

  const onColumnOrderChange = (e: GridColumnOrderChangeParams) => {
    try {
      const newOrder = [...columnOrder.current];
      const [movedItem] = newOrder.splice(e.oldIndex, 1);
      newOrder.splice(e.targetIndex, 0, movedItem);
      setRefColumnOrder(newOrder);
    } catch {}
  };

  //switch pinned columns
  const onPinnedColumnsChange = (pinnedCol: GridPinnedColumns) => {
    if (actionCols.current.length > 0) {
      if (pinnedCol.right) {
        if (pinnedCol.right!.length > actionCols.current.length) {
          actionCols.current.forEach((col) => {
            pinnedCol.right! = pinnedCol.right!.filter(
              (item: string) => item !== col
            );
          });
          actionCols.current.forEach((col) => {
            pinnedCol.right!.push(col);
          });
        }
      }
    }
    // setAreDataRecived(false)
    setPinnedColumns(pinnedCol);
  };

  const onRowSelectionModelChange = (rowSelection: GridRowSelectionModel) => {
    if (data.columns.length === 0) return;
    // dispatch(
    //   setRowSelectionModel({
    //     contextId: props.contextId,
    //     rowSelectionModel: rowSelection,
    //   })
    // );
    setRowSelectionModel(rowSelection);
    if (typeof props.onRowSelectionModelChange !== "undefined") {
      props.onRowSelectionModelChange(rowSelection);
    }
  };
  const onSortModelChange = (
    newModel: GridSortModel,
    details: GridCallbackDetails
  ) => {
    // Without available columns there is nothing todo
    if (data.columns.length === 0) return;
    setSortModel(newModel);
    if (props.pagination ?? true) {
      let newPageRequest: IDmsPageRequest = data.pageRequest;
      newPageRequest.orderBy = getSortings(newModel);
      props.data.pageRequest = newPageRequest;
      // setPaginationModel({ page: 0, pageSize: paginationModel.pageSize })
      // dispatch(
      //   setPaginationModel({
      //     contextId: props.contextId,
      //     paginationModel: { page: 0, pageSize: paginationModel.pageSize },
      //   })
      // );
      setPaginationModel({ page: 0, pageSize: paginationModel!.pageSize });
      props.dmsDataFunction();
    }
  };

  const onPaginationModelChange = (
    newPaginationModel: GridPaginationModel,
    details: GridCallbackDetails<any>
  ) => {
    let newPageRequest: IDmsPageRequest = data.pageRequest;
    newPageRequest.page = newPaginationModel.page + 1;
    newPageRequest.pageSize = newPaginationModel.pageSize;
    // setPaginationModel(newPaginationModel)
    // dispatch(
    //   setPaginationModel({
    //     contextId: props.contextId,
    //     paginationModel: newPaginationModel,
    //   })
    // );
    setPaginationModel(newPaginationModel);
    props.data.pageRequest = newPageRequest;
    props.dmsDataFunction();
    // if (typeof props.onPaginationModelChange !== "undefined") props.onPaginationModelChange(newPaginationModel)
  };

  const onFilterModelChange = (filterModel: GridFilterModel) => {
    if (data.columns.length === 0) return;
    // dispatch(
    //   setFilterModel({ contextId: props.contextId, filterModel: filterModel })
    // );
    setFilterModel(filterModel);
    let searchFilter: ISearchFilter = { logic: "and", searchConditions: [] };
    searchFilter.logic =
      filterModel.logicOperator === GridLogicOperator.Or ? "or" : "and";
    filterModel.items.forEach((el, index) => {
      searchFilter.searchConditions!.push({
        fieldDescriptor: [el.field],
        condition: el.operator,
        values: el.value,
      });
    });
    props.data.pageRequest!.searchFilters = [searchFilter];
  };

  const onPreferencePanelOpen = (
    params: GridPreferencePanelParams,
    event: MuiEvent<{}>,
    details: GridCallbackDetails
  ) => {
    // The preference panel is opened by filter settings or manage columns
    // The current page request is saved to check if something is changed when the panel close
    setOldPageRequest(structuredClone(data.pageRequest));
  };

  const onPreferencePanelClose = (
    params: GridPreferencePanelParams,
    event: MuiEvent<{}>,
    details: GridCallbackDetails
  ) => {
    // The preference panel is opened by filter settings or manage columns
    // If the panel is related to filter settings then if something is changed from the previous request then the data must be reloaded
    if (
      JSON.stringify(oldPageRequest.searchFilter) !==
      JSON.stringify(data.pageRequest.searchFilters)
    ) {
      props.data.pageRequest!.searchFilters = data.pageRequest.searchFilters;
      // props.data.pageRequest!.searchText = props.searchedText ?? ""
      props.dmsDataFunction();
    }
  };
  //#endregion

  //#region Customizations

  function CustomUserItem(props: GridColumnMenuItemProps) {
    const { myCustomHandler, myCustomValue } = props;
    return (
      <MenuItem onClick={myCustomHandler}>
        <ListItemIcon>
          <FontAwesomeIcon icon={faArrowsRotate} />
        </ListItemIcon>
        <ListItemText>{myCustomValue}</ListItemText>
      </MenuItem>
    );
  }

  function CustomColumnMenu(props: GridColumnMenuProps) {
    return (
      <GridColumnMenu
        {...props}
        // startIcon={<FontAwesomeIcon icon={faArrowsRotate}/>}
        slots={{
          // Add new item
          columnMenuUserItem: CustomUserItem,
        }}
        slotProps={{
          columnMenuUserItem: {
            // set `displayOrder` for new item
            displayOrder: 15,
            // pass additional props
            myCustomValue: t("Reset view"),
            myCustomHandler: () => {
              resetGrid();
            },
          },
        }}
      />
    );
  }
  //#endregion

  //#region Rendering

  return (
    <DataGridPro
      {...data}
      loading={loading}
      pagination={props.pagination ?? true}
      paginationMode={props.pagination ?? true ? "server" : "client"}
      paginationModel={paginationModel ?? { page: 0, pageSize: 5 }}
      onPaginationModelChange={onPaginationModelChange}
      columnVisibilityModel={columnVisibilityModel}
      onColumnVisibilityModelChange={onColumnVisibilityModelChange}
      onColumnWidthChange={onColumnWidthChange}
      onColumnOrderChange={onColumnOrderChange}
      keepNonExistentRowsSelected={props.keepNonExistentRowsSelected ?? true}
      checkboxSelection={props.checkboxSelection}
      disableRowSelectionOnClick={props.disableRowSelectionOnClick ?? false}
      rowSelectionModel={rowSelectionModel!}
      onRowSelectionModelChange={(newRowSelection) => {
        onRowSelectionModelChange(newRowSelection);
      }}
      sortingMode={props.pagination ?? true ? "server" : "client"}
      sortModel={sortModel}
      onSortModelChange={onSortModelChange}
      filterMode={props.pagination ?? true ? "server" : "client"}
      // filterMode="server"
      filterModel={filterModel!}
      filterDebounceMs={1000}
      onFilterModelChange={onFilterModelChange}
      onPreferencePanelOpen={onPreferencePanelOpen}
      onPreferencePanelClose={onPreferencePanelClose}
      pinnedColumns={pinnedColumns}
      onPinnedColumnsChange={onPinnedColumnsChange}
      pageSizeOptions={props.pageSizeOptions ?? [5, 10, 20, 50, 100]}
      rowCount={rowCount}
      getRowId={props.getRowId}
      onRowClick={(params, event) => {
        if (props.onRowClick != null) props.onRowClick(params, event);
      }}
      slots={{
        columnMenu: CustomColumnMenu,
        noRowsOverlay: () => (
          <div
            style={{
              transform: "scale(0.6)",
              position: "relative",
              top: "-30px",
            }}
          >
            <VaporEmptyState
              badgeIcon
              title={t("There is no data to display")}
            />
          </div>
        ),
        noResultsOverlay: () => (
          <div
            style={{
              transform: "scale(0.6)",
              position: "relative",
              top: "-30px",
            }}
          >
            <VaporEmptyState
              badgeIcon
              title={t("There is no filtered data to display")}
            />
          </div>
        ),
        rowReorderIcon: orderingIcon,
      }}
      sx={{
        ".MuiDataGrid-virtualScrollerContent": {
          minHeight: "110px !important",
        },
        ".MuiDataGrid-columnHeaders": { backgroundColor: "#F2F5F8 !important" },
        ".MuiDataGrid-columnHeader": { backgroundColor: "#F2F5F8 !important" },
        ".MuiDataGrid-pinnedColumnHeaders--right": {
          paddingRight: "0 !Important",
        },
      }}
      initialState={{ pinnedColumns: { left: [], right: ["_actions_"] } }}
      hideFooter={props.hideFooter ?? false}
      rowReordering={props.rowReordering ?? false}
      onRowOrderChange={props.onRowOrderChange}
      localeText={LocaleTextConstant(t)}
    />
  );
  //#endregion
};

export default DmsDataGridPro;
