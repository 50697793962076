
export default class DmsHelpers
{

	/** Client side generation of a valid random ObjectId */
	static newObjectId = () => {
		const timestamp = Math.floor(new Date().getTime() / 1000).toString(16);
		const objectId = timestamp + 'xxxxxxxxxxxxxxxx'.replace(/[x]/g, () => {
			return Math.floor(Math.random() * 16).toString(16);
		}).toLowerCase();
	
		return objectId;
	}

	/** Ensure first position in positional assignment  */
	static firstPosition() : number { return -new Date().valueOf() * 10000 }

	/** Ensure last position in positional assignment  */
	static lastPosition() : number { return new Date().valueOf() * 10000 }

	/** Evaluate middle position in positional assignment */
	static middlePosition(prevPos?: number | null, nextPos?: number | null) : number { return ((prevPos ?? this.firstPosition()) + (nextPos ?? this.lastPosition()) ) / 2.0 }

	/** Suffix to append for personal saving */
	static personalSuffix(userName?: string) : string {
		let now = new Date();
		return ` (${userName} - ${now.getFullYear()}-${String(now.getUTCMonth()+1).padStart(2, '0')}-${String(now.getUTCDate()).padStart(2, '0')} ${String(now.getUTCHours()).padStart(2, '0')}:${String(now.getUTCMinutes()).padStart(2, '0')}:${String(now.getUTCSeconds()).padStart(2, '0')})`
	}

	static parseInt(v: string, def: number, radix?: number) : number {
		if (radix==null)
			radix = 10;
		var result = parseInt(v, radix);
		if (isNaN(result) && arguments.length > 1)
			return def;
		return result;
	}

	static formatDocSize(size: number, zeroString?: string)
	{
		let unit = "";
		let value = 0;
		size = DmsHelpers.parseInt(""+size, 0);
		if (zeroString != null && size === 0)
			return zeroString;
	
		if (size < 10000) // 0 - 9999 bytes
		{
			unit = "bytes";
			value = size;
		}
		else if (size < 10000000) // 10 - 9999 KB 
		{
			unit = "KB";
			value = (size / 1000);
		}
		else // MB
		{
			unit = "MB";
			value = size / 1000000;
		}
		//result = Math.round(result) +" "+ unit +" ("+ size +" bytes)";
		return Math.round(value) +" "+ unit;
	}

	static getFileExtension(fileName: string)
	{
		let list = fileName.split(".")
		if (list.length === 0)
			return ""
		else
			return list[list.length-1]
	}

}