import { ExampleList } from "./example-list";

export const examples = () => {

  return [
    {
      target: "$ONE_LAYOUT_ROUTE",
      handler: {
        exact: true,
        path: "/examples",
        element: <ExampleList />
      }
    }
  ];
};
