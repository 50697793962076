// import axios from "../../utils/axios"
import { getJSON, putJSON, postJSON, patchJSON } from "@1f/react-sdk";
import {
  IDmsPageRequest,
  IDmsPageResponse,
} from "../models/pagination/DmsPagination";
import { DmsSubjectDto } from "../models/subjects";

export class DmsSubjectMembersService {
  //#region ===== UPDATE =====

  // static updateGroupMembersAsync = async (subjectId?: string, membersToAdd?: string[], membersToRemove?: string[]): Promise<boolean> => {
  //   let payload = { membersToAdd: membersToAdd, membersToRemove: membersToRemove }
  //   let response = await axios.patch(`/api/v1/DmsSubjectMembers/${subjectId}/update/group/members`, payload)
  //   return response.data
  // }

  // static updateGroupMembershipAsync = async (subjectId?: string, membershipToAdd?: string[], membershipToRemove?: string[]): Promise<boolean> => {
  //   let payload = { membershipToAdd: membershipToAdd, membershipToRemove: membershipToRemove }
  //   let response = await axios.patch(`/api/v1/DmsSubjectMembers/${subjectId}/update/group/membership`, payload)
  //   return response.data
  // }

  static updateRoleMembersAsync = async (
    subjectId?: string,
    membersToAdd?: string[],
    membersToRemove?: string[]
  ): Promise<boolean> => {
    let payload = {
      membersToAdd: membersToAdd,
      membersToRemove: membersToRemove,
    };
    let response = await patchJSON<boolean>(
      `dms::be::Api://api/v1/DmsSubjectMembers/${subjectId}/update/role/members`,
      {
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  };

  static updateRoleMembershipAsync = async (
    subjectId?: string,
    membershipToAdd?: string[],
    membershipToRemove?: string[]
  ): Promise<boolean> => {
    let payload = {
      membershipToAdd: membershipToAdd,
      membershipToRemove: membershipToRemove,
    };
    let response = await patchJSON<boolean>(
      `dms::be::Api://api/v1/DmsSubjectMembers/${subjectId}/update/role/membership`,
      {
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  };

  //#endregion

  //#region ===== SEARCH =====

  static ancestorSubjectAsync = async (
    subjectId: string,
    type?: number,
    pageRequest?: IDmsPageRequest,
    minDepth?: number,
    maxDepth?: number
  ): Promise<IDmsPageResponse<DmsSubjectDto>> => {
    let queryParams = "";
    if (minDepth !== undefined) queryParams += `&minDepth=${minDepth}`;
    if (maxDepth !== undefined) queryParams += `&maxDepth=${maxDepth}`;
    if (type !== undefined) queryParams += `&type=${type}`;
    let response = await postJSON<IDmsPageResponse<DmsSubjectDto>>(
      `dms::be::Api://api/v1/DmsSubjectMembers/search/ancestor?subject=${subjectId}${queryParams}`,
      {
        body: JSON.stringify(pageRequest),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  };

  static descendantSubjectAsync = async (
    subjectId: string,
    type?: number,
    pageRequest?: IDmsPageRequest,
    minDepth?: number,
    maxDepth?: number
  ): Promise<IDmsPageResponse<DmsSubjectDto>> => {
    let queryParams = "";
    if (minDepth !== undefined) queryParams += `&minDepth=${minDepth}`;
    if (maxDepth !== undefined) queryParams += `&maxDepth=${maxDepth}`;
    if (type !== undefined) queryParams += `&type=${type}`;
    let response = await postJSON<IDmsPageResponse<DmsSubjectDto>>(
      `dms::be::Api://api/v1/DmsSubjectMembers/search/descendant?subject=${subjectId}${queryParams}`,
      {
        body: JSON.stringify(pageRequest),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  };

  //#endregion
}
