import { ChangeEvent, FC, useState } from "react"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { Radio } from "@vapor/react-material/Radio"
import { RadioGroup } from "@vapor/react-material/RadioGroup"
import { FormControlLabel } from "@vapor/react-material/FormControlLabel"
import { TextField } from "@vapor/react-material/TextField"
import { LocalizationProvider, DateTimePicker } from "@vapor/react-x-date-pickers/"
import { FormControl } from "@vapor/react-material/FormControl"
import { FormLabel } from "@vapor/react-material/FormLabel"
import { t } from "i18next"

export interface FilterInputComponentProps {
  item: {
    value: any[]
    field: string
    operator: string
    id: number
  }
  applyValue: (updatedItem: { value?: any[] }) => void //is used to apply changes when a change is occurring within FilterInputComponent
  type: string //is used to switch the InputComponent structure to return
}

//const FilterInputComponent: FC<FilterInputComponentProps> = ({ item, applyValue, type }) => {
export const FilterInputComponent: React.FC<FilterInputComponentProps> = ({ item, applyValue, type }) => {
  const [inputValue, setInputValue] = useState<any>()
  const [secondInputValue, setSecondInputValue] = useState<any>()

  //checks if both of them are available for the filter
  const handleInputValueChange = (val: any) => {
    let value = val
    setInputValue(val)
    if (value && secondInputValue) {
      let newValuesArray = [value, secondInputValue]
      applyValue({ ...item, value: newValuesArray })
    }
  }
  //checks if both of them are available for the filter
  const handleSecondInputValueChange = (val: any) => {
    let value = val
    setSecondInputValue(val)
    if (inputValue && value) {
      let newValuesArray = [inputValue, value]
      applyValue({ ...item, value: newValuesArray })
    }
  }

  if (type === "boolean") {
    return (
      <FormControl style={{ display: "flex", marginLeft: "1vw" }}>
        <FormLabel id="demo-row-radio-buttons-group-label" style={{ fontFamily: "Roboto", fontSize: "14px", color: "hsl(200, 19%, 15%)", fontWeight: "500", marginBottom: "8px", marginRight: "3px" }}>
          Condition
        </FormLabel>
        <RadioGroup
          row
          value={inputValue || (item.value && item.value !== null && item.value[0]) || null}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            applyValue({ ...item, value: [e.target.value] })
          }}
        >
          <FormControlLabel value="true" control={<Radio />} label={t("True")} />
          <FormControlLabel value="false" control={<Radio />} label={t("False")} />
        </RadioGroup>
      </FormControl>
    )
  } else if (type === "datetime") {
    if (item.operator === "datetime-is-between") {
      //item.operator is the filter operation choosed on the filter
      return (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              label={t("From")}
              value={inputValue || null}
              onChange={(e) => {
                handleInputValueChange(e)
              }}
              // renderInput={(params) => <TextField {...params} />}
              slots={{
                textField: (params) => <TextField {...params} />}}
            />
            <DateTimePicker
              label={t("To")}
              value={secondInputValue || null}
              onChange={(e) => {
                handleSecondInputValueChange(e)
              }}
              //renderInput={(params) => <TextField {...params} />}
              slots={{
                textField: (params) => <TextField {...params} />}}
            />
          </LocalizationProvider>
        </div>
      )
    } else
      return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            label="Date"
            value={inputValue || (item.value && item.value !== null && item.value[0]) || null}
            onChange={(e) => {
              applyValue({ ...item, value: [e as string] })
            }}
            //renderInput={(params) => <TextField {...params} />}
            slots={{
              textField: (params) => <TextField {...params} />}}
        />
        </LocalizationProvider>
      )
  } else {
    //    if (item.operator === "string-is-between" || item.operator === "between") {
    if (item.operator.indexOf("between") >= 0) {
      return (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <TextField
            label={t("From")}
            value={inputValue || (item.value && item.value !== null && item.value[0]) || ""}
            onChange={(e) => {
              handleInputValueChange(e.target.value)
            }}
            size="small"
            style={{ width: "50%", marginLeft: "3px" }}
          />
          <TextField
            label={t("To")}
            value={secondInputValue || (item.value && item.value !== null && item.value[1]) || ""}
            onChange={(e) => {
              handleSecondInputValueChange(e.target.value)
            }}
            size="small"
            style={{ width: "50%", marginLeft: "3px" }}
          />
        </div>
      )
    } else {
      return <TextField label="Value" value={inputValue || (item.value && item.value !== null && item.value[0]) || ""} onChange={(e: ChangeEvent<HTMLInputElement>) => applyValue({ ...item, value: [e.target.value] })} size="small" style={{ width: "100%", marginLeft: "3px" }} />
    }
  }
}

//export default FilterInputComponent
