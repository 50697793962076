import React from "react";
import { Drawer, IconButton } from "@vapor/react-material";
import VaporHeaderBar from "@vapor/react-custom/VaporHeaderBar";
import { Typography } from "@vapor/react-extended";
import CloseIcon from "@mui/icons-material/Close";
import ContentSwitcherDrawer from "./ContentSwitcherDrawer";
import { ETabSubjectMemeber } from "../../../../../domain/enums";

interface IProps {
  activeTab: ETabSubjectMemeber;
  title: string;
  text: string;
  isOpen: boolean;
  onClose: () => void;
  subjectId: string | undefined;
}

const SubjectMemberDrawer = ({
  subjectId,
  isOpen,
  onClose,
  title,
  text,
  activeTab,
}: IProps) => {
  return (
    <>
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={onClose}
        hideBackdrop
        width={700}
      >
        <VaporHeaderBar
          title=""
          divider
          rightItems={[
            <IconButton onClick={onClose} color="primary">
              <CloseIcon />
            </IconButton>,
          ]}
          titleItems={
            <Typography variant="titleMedium" fontWeight={700} color="#101214">
              {title}
            </Typography>
          }
          reduced={true}
        />

        <ContentSwitcherDrawer
          text={text}
          subjectId={subjectId}
          activeTab={activeTab}
        />
      </Drawer>
    </>
  );
};

export default SubjectMemberDrawer;
