import { Provider } from "react-redux";
import RolesPage from "./Config/RolesTable/RolesTable";
import EditRoles from "./Config/EditRole/EditRoles";
// import EditRoles from "./EditRole/EditRoles";
// import Role from "./Role/Role";
import { store } from "../../store/store";
import SnackbarProvider from "../../components/SnackbarProvider";
// import "../../i18n/i18n"; // Assicurati di importare il file di configurazione

import { LicenseInfo } from "@mui/x-license-pro";
LicenseInfo.setLicenseKey(
  "81c5b74554e34c1f397e9cedbf341f5dTz05Mzc1MyxFPTE3NTE3OTM2MzMwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

export const rolesPage = () => {
  return [
    {
      target: "$ONE_LAYOUT_ROUTE",
      handler: {
        exact: true,
        path: "/role/config",
        element: (
          <Provider store={store}>
            {/* <SnackbarProvider> */}
            <RolesPage />
            {/* </SnackbarProvider> */}
          </Provider>
        ),
      },
    },
    {
      target: "$ONE_LAYOUT_ROUTE",
      handler: {
        exact: true,
        // path: "/role/config/editrole/:id",
        path: "/role/config/editrole/:id",
        element: (
          <Provider store={store}>
            {/* <SnackbarProvider> */}
            <EditRoles />,{/* </SnackbarProvider> */}
          </Provider>
        ),
      },
    },
    // {
    //   target: "$ONE_LAYOUT_ROUTE",
    //   handler: {
    //     exact: true,
    //     path: "/role/config/editrole",
    //     element: (
    //       <Provider store={store}>
    //         <SnackbarProvider>
    //           <EditRoles />,
    //         </SnackbarProvider>
    //       </Provider>
    //     ),
    //   },
    // },
    // {
    //   target: "$ONE_LAYOUT_ROUTE",
    //   handler: {
    //     exact: true,
    //     path: "/role/config/newrole",
    //     element: (
    //       <Provider store={store}>
    //         <SnackbarProvider>
    //           <Role />,
    //         </SnackbarProvider>
    //       </Provider>
    //     ),
    //   },
    // },
  ];
};
