/** Generic DMS page request  */
export interface IDmsPageRequest {
  page?: number
  pageSize?: number
  searchText?: string | null | undefined
  searchFilter?: IDmsSearchFilterItem[] // DEPRECATED replaced by searchFilters
  searchFilters?: ISearchFilter[]
  orderBy?: IDmsOrderByItem[]
}

export interface ISearchFilter {
  id?: string
  logic?: "and" | "or"
  searchConditions?: ISearchCondition[]
  subFilters?: ISearchFilter[]
}

export interface ISearchCondition {
  fieldDescriptor: string[]
  condition: string
  values?: string[]
  options?: string[]
}

export interface IDmsSearchFilterItem {
  id?: string[]
  fieldDescriptor: string[]
  condition: string
  value: string[]
  option?: string[]

  //filter: IDmsSearchFilterEntry[]
  descCondition?: string
  logic?: string
}

export interface IDmsOrderByItem {
  fieldDescriptor: string[]
  direction: number
}

export const getDefaultDmsPageRequest = () : IDmsPageRequest => {
  return {
    page: 1,
    pageSize: 5,
  }
}

/** Generic DMS page response */
export interface IDmsPageResponse<T> {
  count: number
  totalCount?: number
  page: number
  pageSize: number
  pageCount?: number
  data: T[]
  availableFieldDescriptors: string[]
}

export interface IDmsPageResponseWithInfo<T1, T2> {
  count: number
  totalCount?: number
  page: number
  pageSize: number
  pageCount?: number
  data: T1[]
  info: T2
  availableFieldDescriptors: string[]
}
