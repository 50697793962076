import { IDmsAttributeType } from "./models/DmsAttributes"
import { IDmsTextTranslation } from "./models/component/DmsComponent"
import { DmsEnumAttributeType } from "./models/value/DmsEnum"

export class DmsConstants {
  static WKI_EMPTY: string = "000000000000000000000000"
}

export class DmsAttributeType implements IDmsAttributeType {
  constructor(type: string, label?: IDmsTextTranslation[], descr?: IDmsTextTranslation[] ) {
    this.type = type
    this.label = label
    this.descr = descr
  }
  type: string
  label?: IDmsTextTranslation[];
  descr?: IDmsTextTranslation[];
}
export const DmsAttributeTypes: IDmsAttributeType[] = [
  new DmsAttributeType(DmsEnumAttributeType.Bool,
    [
      { l: "it", t: "Vero/Falso" },
      { l: "en", t: "True/False" },
    ],[]
  ),
  new DmsAttributeType(DmsEnumAttributeType.Int,
    [
      { l: "it", t: "Intero" },
      { l: "en", t: "Integer" },
    ],[]
  ),
  new DmsAttributeType(DmsEnumAttributeType.Decimal,
    [
      { l: "it", t: "Decimale" },
      { l: "en", t: "Decimal" },
    ],[]
  ),
  new DmsAttributeType(DmsEnumAttributeType.String,
    [
      { l: "it", t: "Testo breve" },
      { l: "en", t: "Short text" },
    ],[]
  ),
  new DmsAttributeType(DmsEnumAttributeType.Text,
    [
      { l: "it", t: "Testo lungo" },
      { l: "en", t: "Long text" },
    ],[]
  ),
  new DmsAttributeType(DmsEnumAttributeType.DateTime,
    [
      { l: "it", t: "Data ora" },
      { l: "en", t: "Date time" },
    ],[]
  ),
  new DmsAttributeType(DmsEnumAttributeType.Enum,
    [
      { l: "it", t: "Elenco" },
      { l: "en", t: "List" },
    ],[]
  ),
  new DmsAttributeType(DmsEnumAttributeType.Object,
    [
      { l: "it", t: "Elenco pubblicazioni" },
      { l: "en", t: "Publication list" },
    ],[]
  ),
  new DmsAttributeType(DmsEnumAttributeType.Subject,
    [
      { l: "it", t: "Elenco soggetti" },
      { l: "en", t: "Subject list" },
    ],[]
  ),
]

export enum DmsEnumDataType {
    Bool = 1,
    DateTime = 2,
    Int = 3,
    Decimal = 4,
    String = 5,
    Text = 6,
    Enum = 7,
    Object = 20,
    Subject = 21,

    Id = 101,
    StatusColor = 102
}

export enum DmsEnumDataStyle {
    // Bool
    OneZero = 100,    
    TrueFalse = 101,
    XSpace = 102,     // default
    YesNo = 103,

    // DateTime    
    DateTime = 200,   // default
    Date = 201,
    DateMonth = 202,
    Time = 203,

    // Int
    Int = 300,

    // Decimal
    Decimal = 400,

    // String
    String = 500,

    // Text
    Text = 600,

    // Enum
    Enum = 700,

    // Object
    Object = 2000,

    // Subject
    Subject = 2100,
    SubjectIcon = 2101,

    // Others
    Id = 1010,
    StatusColor = 1020,
}

export enum DmsEnumPropertyId 
{
  // Object properties 
  ObjectId = 1,
  Owner = 2,
  Language = 3,
  FlagSystem = 4,
  FlagPrivate = 5,
  DateCreation = 201,
  DateModify = 202,
  DateChangeStatus = 203,
  ClassId = 301,
  ClassName = 302,
  StatusId = 401,
  StatusName = 402,
  StatusColor = 403,

  // Subject attribute properties
	SubjectId = 1001,
	SubjectType = 1002,
	SubjectLogin = 1003,
	SubjectName = 1004,
	SubjectEmail = 1005    
}

export interface IDmsProperty {
    propertyId: DmsEnumPropertyId
    name: string
    dataType: DmsEnumDataType
    label: IDmsTextTranslation[]
    descr: IDmsTextTranslation[]
}

export class DmsProperty implements IDmsProperty{
    constructor(id: DmsEnumPropertyId, name: string, dataType: DmsEnumDataType, label: IDmsTextTranslation[], descr: IDmsTextTranslation[]) {
        this.propertyId = id;
        this.name = name;
        this.dataType = dataType;
        this.label = label;
        this.descr = descr;
    }
    propertyId: DmsEnumPropertyId;
    name: string;
    dataType: DmsEnumDataType;
    label: IDmsTextTranslation[];
    descr: IDmsTextTranslation[];
}

export const DmsObjectProperties: IDmsProperty[] = [
  new DmsProperty(DmsEnumPropertyId.ObjectId, "ObjectId", DmsEnumDataType.Id,
    [
        { l: "it", t: "Id pubblicazione" },
        { l: "en", t: "Publication Id" },
    ],
    []
  ),
  new DmsProperty(DmsEnumPropertyId.Owner, "Owner", DmsEnumDataType.Id,
    [
        { l: "it", t: "Proprietario" },
        { l: "en", t: "Owner" },
    ],
    []
  ),
  new DmsProperty(DmsEnumPropertyId.Language, "Language", DmsEnumDataType.String,
    [
        { l: "it", t: "Lingua" },
        { l: "en", t: "Language" },
    ],
    []
  ),
  new DmsProperty(DmsEnumPropertyId.FlagSystem, "FlagSystem", DmsEnumDataType.Bool,
    [
        { l: "it", t: "Oggetto di sistema" },
        { l: "en", t: "System object" },
    ],
    []
  ),
  new DmsProperty(DmsEnumPropertyId.FlagPrivate, "FlagPrivate", DmsEnumDataType.Bool,
    [
        { l: "it", t: "Dati privati" },
        { l: "en", t: "Private data" },
    ],
    []
  ),
  new DmsProperty(DmsEnumPropertyId.DateCreation, "DateCreation", DmsEnumDataType.DateTime,
    [
        { l: "it", t: "Data creazione" },
        { l: "en", t: "Creation date" },
    ],
    []
  ),
  new DmsProperty(DmsEnumPropertyId.DateModify, "DateModify", DmsEnumDataType.DateTime,
    [
        { l: "it", t: "Data modifica" },
        { l: "en", t: "Modification date" },
    ],
    []
  ),
  new DmsProperty(DmsEnumPropertyId.DateChangeStatus, "DateChangeStatus", DmsEnumDataType.DateTime,
    [
        { l: "it", t: "Dati cambio stato" },
        { l: "en", t: "Status change date" },
    ],
    []
  ),
  new DmsProperty(DmsEnumPropertyId.ClassId, "ClassId", DmsEnumDataType.Id,
    [
        { l: "it", t: "Id tipologia" },
        { l: "en", t: "Type Id" },
    ],
    []
  ),
  new DmsProperty(DmsEnumPropertyId.ClassName, "ClassName", DmsEnumDataType.String,
    [
        { l: "it", t: "Nome tipologia" },
        { l: "en", t: "Type name" },
    ],
    []
  ),
  new DmsProperty(DmsEnumPropertyId.StatusId, "StatusId", DmsEnumDataType.Id,
    [
        { l: "it", t: "Id stato" },
        { l: "en", t: "Status id" },
    ],
    []
  ),
  new DmsProperty(DmsEnumPropertyId.StatusName, "StatusName", DmsEnumDataType.String,
    [
        { l: "it", t: "Nome stato" },
        { l: "en", t: "Status name" },
    ],
    []
  ),
  new DmsProperty(DmsEnumPropertyId.StatusColor, "StatusColor", DmsEnumDataType.StatusColor,
    [
        { l: "it", t: "Colore stato" },
        { l: "en", t: "Status color" },
    ],
    []
  )
]

export const DmsSubjectProperties: IDmsProperty[] = [
  new DmsProperty(DmsEnumPropertyId.SubjectId, "SubjectId", DmsEnumDataType.Id,
    [
        { l: "it", t: "ID soggetto" },
        { l: "en", t: "Subject ID" },
    ],
    []
  ),
  new DmsProperty(DmsEnumPropertyId.SubjectType, "SubjectType", DmsEnumDataType.Int,
    [
        { l: "it", t: "Tipo soggetto" },
        { l: "en", t: "Subject type" },
    ],
    []
  ),
  new DmsProperty(DmsEnumPropertyId.SubjectLogin, "SubjectLogin", DmsEnumDataType.String,
    [
        { l: "it", t: "Login utente" },
        { l: "en", t: "Subject login" },
    ],
    []
  ),  
  new DmsProperty(DmsEnumPropertyId.SubjectName, "SubjectName", DmsEnumDataType.String,
    [
        { l: "it", t: "Nome utente" },
        { l: "en", t: "Subject name" },
    ],
    []
  ),  
  new DmsProperty(DmsEnumPropertyId.SubjectEmail, "SubjectEmail", DmsEnumDataType.String,
    [
        { l: "it", t: "Email utente" },
        { l: "en", t: "Subject email" },
    ],
    []
  ),  
]

export class DmsFormatStyle {
    constructor(id: DmsEnumDataStyle, name: string, dataType: DmsEnumDataType[], label: IDmsTextTranslation[], descr: IDmsTextTranslation[]) {
        this.id = id;
        this.name = name;
        this.dataType = dataType;
        this.label = label;
        this.descr = descr;
    }
    id: DmsEnumDataStyle;
    name: string;
    dataType: DmsEnumDataType[];
    label: IDmsTextTranslation[];
    descr: IDmsTextTranslation[];
}

export const DmsViewColumnFormatStyles =
    [
        new DmsFormatStyle(DmsEnumDataStyle.OneZero, "OneZero", [DmsEnumDataType.Bool],
            [
                { l: "it", t: "1 o 0" },
                { l: "en", t: "1 or 0" }
            ],
            []
        ),
        new DmsFormatStyle(DmsEnumDataStyle.TrueFalse, "TrueFalse", [DmsEnumDataType.Bool],
            [
                { l: "it", t: "Vero o Falso" },
                { l: "en", t: "True or False" }
            ],
            []
        ),
        new DmsFormatStyle(DmsEnumDataStyle.XSpace, "XSpace", [DmsEnumDataType.Bool],
            [
                { l: "it", t: "✔ o spazio" },
                { l: "en", t: "✔ or space" }
            ],
            []
        ),
        new DmsFormatStyle(DmsEnumDataStyle.YesNo, "YesNo", [DmsEnumDataType.Bool],
            [
                { l: "it", t: "Sì o No" },
                { l: "en", t: "Yes or No" }
            ],
            []
        ),
        new DmsFormatStyle(DmsEnumDataStyle.DateTime, "DateTime", [DmsEnumDataType.DateTime],
            [
                { l: "it", t: "Data e ora" },
                { l: "en", t: "Date and time" }
            ],
            []
        ),
        new DmsFormatStyle(DmsEnumDataStyle.Date, "Date", [DmsEnumDataType.DateTime],
            [
                { l: "it", t: "Data" },
                { l: "en", t: "Date" }
            ],
            []
        ),
        new DmsFormatStyle(DmsEnumDataStyle.DateMonth, "DateMonth", [DmsEnumDataType.DateTime],
            [
                { l: "it", t: "Data con nome mese" },
                { l: "en", t: "Date with month name" }
            ],
            []
        ),
        new DmsFormatStyle(DmsEnumDataStyle.Time, "Time", [DmsEnumDataType.DateTime],
            [
                { l: "it", t: "Ora, minuti, secondi" },
                { l: "en", t: "Hour, minutes, seconds" }
            ],
            []
        ),
        new DmsFormatStyle(DmsEnumDataStyle.Int, "Int", [DmsEnumDataType.Int],
            [
                { l: "it", t: "Numero intero" },
                { l: "en", t: "Integer number" }
            ],
            []
        ),
        new DmsFormatStyle(DmsEnumDataStyle.Decimal, "Decimal", [DmsEnumDataType.Decimal],
            [
                { l: "it", t: "Numero decimale" },
                { l: "en", t: "Decimal number" }
            ],
            []
        ),
        new DmsFormatStyle(DmsEnumDataStyle.String, "String", [DmsEnumDataType.String],
            [
                { l: "it", t: "Testo breve" },
                { l: "en", t: "Short text" }
            ],
            []
        ),
        new DmsFormatStyle(DmsEnumDataStyle.Text, "String", [DmsEnumDataType.Text],
            [
                { l: "it", t: "Testo lungo" },
                { l: "en", t: "Long text" }
            ],
            []
        ),
        new DmsFormatStyle(DmsEnumDataStyle.Enum, "Enum", [DmsEnumDataType.Enum],
            [
                { l: "it", t: "Elenco valori" },
                { l: "en", t: "Value list" }
            ],
            []
        ),
        new DmsFormatStyle(DmsEnumDataStyle.Object, "Object", [DmsEnumDataType.Object],
            [
                { l: "it", t: "Pubblicazioni collegate" },
                { l: "en", t: "Related pubblications" }
            ],
            []
        ),
        new DmsFormatStyle(DmsEnumDataStyle.Subject, "Subject", [DmsEnumDataType.Subject],
            [
                { l: "it", t: "Soggetti collegati" },
                { l: "en", t: "Related subjects" }
            ],
            []
        ),
        new DmsFormatStyle(DmsEnumDataStyle.SubjectIcon, "SubjectIcon", [DmsEnumDataType.Subject],
            [
                { l: "it", t: "Icona per utente, gruppo, ruolo" },
                { l: "en", t: "Icons for user, group, role" }
            ],
            []
        ),
        new DmsFormatStyle(DmsEnumDataStyle.Id, "Id", [DmsEnumDataType.Id],
            [
                { l: "it", t: "Identificativo" },
                { l: "en", t: "Identifier" }
            ],
            []
        ),
        new DmsFormatStyle(DmsEnumDataStyle.StatusColor, "StatusColor", [DmsEnumDataType.StatusColor],
            [
                { l: "it", t: "Colore dello stato corrente" },
                { l: "en", t: "Current status color" }
            ],
            []
        ),
    ];

// Options for single/multiple file management
export enum DmsEnumUploadOptionSingleFile {
    AutomaticVersioning = 1,            // Single file with automatic versioning"
    ReplacePreviousFile = 2             // Single file with replacement of previous file
}
export enum DmsEnumUpploadModel {
    Type1 = 0,
    Type2 = 1
}

// Option for duplicated file names
export enum DmsEnumUploadOptionAllowDuplicate {
    AppendIfDifferent = 0,              // Append the new file if different
    AlwaysAppend = 1,                   // Always append the new file
    NewVersionIfDifferent = 2,          // Create a new version if different    
    NewCurrentVersionIfDifferent = 3,   // Create a new current version if different
    AlwaysReplace = 4,                  // Always replace existing file
}

// Restriction options on file types
export enum DmsEnumFileOptionEnableExtension {
    Default = 1,                        // Use tenant settings        
    ExtensionInList = 2                 // Allow only extensions in list
}

// Extension check options 
export enum DmsEnumFileOptionEnableExtension2 {
    CheckLastOnly = 1,                  // Check only last extension    
    CheckPrevious = 2                   // Check previous extensions of file that end with .p7m .m7m .tsd .p7s .tsr .tst
}

// CheckedFilter parameter 
export enum DmsEnumCheckedFilter {
    UncheckedAll = 0,
    CheckedOnly = 1,    // Return only items which have _checked = true
    UncheckedOnly = 2,  // Return only items which have _checked = false
    All = 3             // Return all items with _checked field set to true or false
}

/** Option for IDmsEditOption*/
export enum DmsEnumEditLock {
    /** Simply read data, without any lock */
    ReadOnly = 0,

    /** A lock for editing is requested, this can lead to an error if there is already a lock with a different key  */
    RequestEditing = 1,

    /** A lock with the supplied key is forced, the existing lock is replaced.
     *  Always use DmsEnumEditLock.RequestEditing before and then DmsEnumEditLock.ForceEditing only if required by the user 
     * */
    ForceEditing = 2
}

/** Option for rounding mode in attribute configuration */
export enum DmsEnumRoundingMode {
    /** Rounding: 1.555 => 1,56 */
    Round = 0,

    /** Truncation to lower: 1.555 => 1.55 */
    Floor = 1
}

/** Used in Subject search to set the includeSpecials option */
export enum DmsEnumIncludeSpecialSubjectOption {
    /** None (default value) */
    None = 0,

    /** Include Administrators role */
    AdministratorsRole = 1,

    /** Include Administrators and Authenticated users roles */
    AdministratorsAuthenticatedUsersRoles = 2,

    /** Include all special subjects (Administrator, Administrators, Authenticated users) */
    All = 3
}

/** Used as option in catalog folder operations */
export enum DmsEnumCatalogFolderOption
{
  /** Operaration must be applied to the whole catalog (normally as a default) */
	ApplyToCatalog = 0,

  /** Operaration must be applied to folder only */
	ApplyToFolderOnly = 1,

  /** Operaration must be applied to folder and subfolders */
	ApplyToFolderAndSubFolders = 2
}

