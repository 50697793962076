import { useState, useEffect } from "react";
import { useTranslation } from "@1f/react-sdk";

import HeaderBar from "@vapor/react-custom/VaporHeaderBar";
import {
  Stack,
  FormControl,
  Link,
  TableRow,
  Breadcrumbs,
  Table,
  TableCell,
  TableContainer,
  Checkbox,
  TableBody,
  TableHead,
  MenuItem,
  Select,
  Box,
  Drawer,
  Button,
  IconButton,
  TextField,
  TextareaAutosize,
} from "@vapor/react-material";
import Typography from "@vapor/react-extended/ExtendedTypography";

import { SxProps, Theme } from "@mui/system";
import InputAdornment from "@mui/material/InputAdornment";
import LanguageIcon from "@mui/icons-material/Language";
import CloseIcon from "@mui/icons-material/Close";
import VaporToolbar from "@vapor/react-custom/VaporToolbar";
import CircleXmark from "@vapor/react-icons/CircleXmark";
import { IDmsTextTranslation } from "../../domain/models/component/DmsComponent";
import { UiLanguage } from "../../domain/models/shared";
// import { getAlyanteWebApiConfig } from "../../utils/helpers"
import { labelTextField } from "./LabelTextField.style";
import Dialog from "@vapor/react-material/Dialog";
import DialogTitle from "@vapor/react-material/DialogTitle";
import Divider from "@vapor/react-material/Divider";
import DialogContentText from "@vapor/react-material/DialogContentText";
import DialogActions from "@vapor/react-material/DialogActions";
import DialogContent from "@vapor/react-material/DialogContent";
import SearchBar from "../SearchBar/SearchBar";
import { AngleRight } from "@vapor/react-icons";
interface IProps {
  id?: string | undefined;
  sx?: SxProps<Theme>;
  title?: string | undefined;
  labels: IDmsTextTranslation[];
  disabled?: boolean;
  readOnly?: boolean;
  onChange: (labels: Array<IDmsTextTranslation>) => void;
  useTextarea?: boolean;
  useCustom?: boolean;
  open?: boolean;
  onSave?: (test: IDmsTextTranslation[]) => void;
}

function LabelTextField({
  id,
  sx,
  title,
  labels,
  disabled,
  readOnly,
  onChange,
  useTextarea,
  useCustom,
  open,
  onSave,
}: IProps) {
  const [defaultLanguage, setDefaultLanguage] = useState<
    UiLanguage | undefined
  >();
  // const [currLanguage, setCurrLanguage] = useState<UiLanguage>()
  const [enabledLanguages, setEnabledLanguages] = useState<UiLanguage[]>([]);
  const [otherLanguages, setOtherLanguages] = useState<UiLanguage[]>([]);
  const { t } = useTranslation();
  const [changedLabels, setChangedLabels] = useState<IDmsTextTranslation[]>([
    ...(labels ?? []),
  ]);
  // Drawer state
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [isCloseBtnEnabled, setIsCloseBtnEnabled] = useState<boolean>(true);
  const [isUndoBtnEnabled, setIsUndoBtnEnabled] = useState<boolean>(false);
  const [isSaveBtnEnabled, setIsSaveBtnEnabled] = useState<boolean>(false);
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState<boolean>(false);
  // Browse DrawerState
  const [isBrowseDrawerOpen, setIsBrowseDrawerOpen] = useState<boolean>(false);
  const [selectedLanguage, setSelectedLanguage] = useState<string>("");
  const [filters, setFilters] = useState<string>("");
  // componentDidMount
  useEffect(() => {
    // let config = getAlyanteWebApiConfig()
    //to implement
    const enabledLanguages = [
      { name: "it", description: "Italiano", alpha2: "it", alpha3: "ita" },
      { name: "en", description: "English", alpha2: "gb", alpha3: "gbr" },
      { name: "es", description: "Español", alpha2: "es", alpha3: "esp" },
      { name: "fr", description: "Français", alpha2: "fr", alpha3: "fra" },
      { name: "ro", description: "Română", alpha2: "ro", alpha3: "rou" },
      { name: "de", description: "Deutsch", alpha2: "de", alpha3: "deu" },
    ];
    const defaultLanguage = {
      name: "it",
      description: "Italiano",
      alpha2: "it",
      alpha3: "ita",
    };
    // let { language, defaultLanguage, enabledLanguages } = config

    let otherLanguages =
      enabledLanguages?.filter((l) => l.name !== defaultLanguage.name) ?? [];

    // setCurrLanguage(language)
    setEnabledLanguages(enabledLanguages);
    setDefaultLanguage(defaultLanguage);
    setOtherLanguages(otherLanguages);
  }, []);

  // label props change
  useEffect(() => {
    setChangedLabels([...(labels ?? [])]);
  }, [labels]);

  useEffect(() => {
    if (open === true || open === false) {
      setIsDrawerOpen(open);
    }
  }, [open]);

  // on every render, compare initial labels with changed labels, and dynamically enable/disable drawer buttons
  useEffect(() => {
    // There is no way/code that shifts the order of objects in the array so this deep compare works
    if (hasPendingChanges()) {
      setIsCloseBtnEnabled(false);
      setIsUndoBtnEnabled(true);
      setIsSaveBtnEnabled(true);
    } else {
      setIsCloseBtnEnabled(true);
      setIsUndoBtnEnabled(false);
      setIsSaveBtnEnabled(false);
    }
  });

  const hasPendingChanges = (): boolean => {
    let initialLabelsStr = JSON.stringify(labels);
    let changedLabelsStr = JSON.stringify(changedLabels);

    if (initialLabelsStr === changedLabelsStr) return false;
    else return true;
  };

  // Main input change
  // NOTE:This changed the labels automatically (without Cancel/Save) because we are not in the drawer but
  //      in the input directly. Thus, onChange, directly calls the callback of the caller
  const handleMainInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let text = e.target.value ?? "";

    // let newLabels = [...changedLabels] ?? []
    let newLabels = [...changedLabels];

    const index = newLabels.findIndex((l) => l.l === defaultLanguage!.name);
    if (index < 0) {
      newLabels.push({
        l: defaultLanguage!.name,
        t: text,
      });
    } else {
      newLabels[index] = { ...newLabels[index], t: text };
    }

    onChange(newLabels);
  };

  // Inputs inside the drawer change
  // NOTE: We are inside the drawer here, so changing any label doesn't automatically call the caller's callback:
  //       instead, changes are stored in memory (state) and only through Cancel/Save/Close buttons we apply changes to the caller.
  const handleTranslationChange = (language?: string, text?: string) => {
    let newChangedLabels = [...changedLabels];

    if (text === null || text === undefined) text = "";

    const index = newChangedLabels.findIndex((l) => l.l === language);
    // If no previous label and now you assigned a valid value, add
    if (index < 0) {
      newChangedLabels.push({
        l: language!,
        t: text,
      });
    } else {
      // Careful not to mutate
      newChangedLabels[index] = { ...newChangedLabels[index], t: text };
    }

    setChangedLabels(newChangedLabels);
  };

  // Closing with pending changes show confirmation dialog
  const handleDrawerClose = () => {
    if (hasPendingChanges()) {
      setIsCancelDialogOpen(true);
    } else {
      setIsDrawerOpen(false);
    }
  };
  const handleBrowseDrawerClose = () => {
    setIsBrowseDrawerOpen(false);
  };

  // Dialog actions
  const handleDialogClose = () => {
    setIsCancelDialogOpen(false);

    // Close the drawer only if the user chose to close without saving
    if (!hasPendingChanges()) {
      setIsDrawerOpen(false);
    }
  };

  const handleCancelWithoutSaving = () => {
    setIsCancelDialogOpen(false);
    setIsDrawerOpen(false); // Close the entire drawer
    setChangedLabels(labels); // Reset changes
  };
  // Calls the callback so labels in the caller change
  const handleDrawerSave = () => {
    onChange(changedLabels);
    if (onSave) {
      onSave(changedLabels);
    }
    setIsDrawerOpen(false);
  };

  //#region Browser drawer
  const onHandleLanguageFilter = (language: string) => {
    setSelectedLanguage(language);
  };

  const onFilter = (filter: string) => {
    console.log("1");
  };
  const handleBrowseDrawerSave = () => {
    console.log("@");
  };

  return (
    <>
      {useCustom ? (
        ""
      ) : useTextarea ? ( // conditionally render TextareaAutosize if useTextarea prop is true
        <Box display={"flex"}>
          <TextareaAutosize
            aria-label="empty textarea"
            placeholder=""
            spellCheck="false"
            style={{ minHeight: "60px", width: "635px", marginBottom: "10px" }}
            onChange={(e) =>
              handleMainInputChange(e as React.ChangeEvent<HTMLTextAreaElement>)
            }
            value={
              changedLabels.filter((l) => l.l === defaultLanguage?.name)[0]
                ?.t ?? ""
            }
            disabled={disabled}
            readOnly={readOnly}
          />
          {otherLanguages.length > 0 && (
            <IconButton onClick={() => setIsDrawerOpen(true)}>
              <LanguageIcon style={labelTextField.languageIconforTextArea} />
            </IconButton>
          )}
        </Box>
      ) : (
        <TextField
          id={"main-input" + id}
          sx={sx}
          label={title}
          variant="outlined"
          disabled={disabled}
          readOnly={readOnly}
          onChange={handleMainInputChange}
          value={
            changedLabels.filter((l) => l.l === defaultLanguage?.name)[0]?.t ??
            ""
          }
          InputProps={{
            endAdornment: otherLanguages.length > 0 && (
              <InputAdornment position="end">
                <LanguageIcon
                  style={labelTextField.languageIcon}
                  onClick={() => setIsDrawerOpen(true)}
                />
              </InputAdornment>
            ),
          }}
        />
      )}

      {otherLanguages.length > 0 && (
        <>
          <Drawer
            id={id}
            anchor="right"
            open={isDrawerOpen}
            onClick={(e) => e.stopPropagation()}
            onClose={handleDrawerClose}
            hideBackdrop
            width={800}
          >
            {!isBrowseDrawerOpen ? (
              <>
                <HeaderBar
                  title={t("Translations")}
                  divider
                  rightItems={[
                    <IconButton onClick={handleDrawerClose} color="primary">
                      <CloseIcon />
                    </IconButton>,
                  ]}
                />
                <Box>
                  <Typography
                    variant="buttonLarge"
                    gutterBottom
                    component="div"
                    style={labelTextField.languageTypography}
                  >
                    {t("Language")}
                  </Typography>

                  {/* Default language */}
                  <Stack direction="row" spacing={2}>
                    <Box sx={labelTextField.defaultLanguageBox}>
                      <Typography
                        variant="subtitle"
                        gutterBottom
                        component="div"
                        style={labelTextField.descriptionTypography}
                      >
                        {defaultLanguage?.description}
                      </Typography>
                    </Box>

                    <TextField
                      id={`${id ?? ""}-${defaultLanguage?.name ?? ""}`}
                      label={t("Label")}
                      variant="outlined"
                      style={labelTextField.textField}
                      disabled={disabled}
                      readOnly={readOnly}
                      value={
                        changedLabels.filter(
                          (l) => l.l === defaultLanguage?.name
                        )[0]?.t ?? ""
                      }
                      onChange={(e) =>
                        handleTranslationChange(
                          defaultLanguage?.name,
                          e.target.value
                        )
                      }
                    />
                    {disabled || readOnly ? (
                      <CircleXmark color="critical" />
                    ) : (
                      <>
                        <IconButton
                          onClick={() =>
                            handleTranslationChange(defaultLanguage?.name, "")
                          }
                        >
                          <CircleXmark color="critical" />
                        </IconButton>
                      </>
                    )}
                  </Stack>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    m={3}
                  >
                    <Typography style={labelTextField.translationTypography}>
                      {t("Translations")}
                    </Typography>
                    <Button
                      onClick={() => setIsBrowseDrawerOpen(true)}
                      variant="outlined"
                    >
                      {t("Browse Translations")}
                    </Button>
                  </Box>

                  {/* <Typography variant="buttonLarge" gutterBottom component="div" style={{ paddingTop: "35px", paddingLeft: "35px" }}>
                            Language
                        </Typography> */}

                  {/* Other languages */}
                  {otherLanguages.map((lang) => (
                    <Stack
                      key={lang.name}
                      id={lang.name}
                      direction="row"
                      spacing={2}
                      style={labelTextField.stack}
                    >
                      <Box sx={labelTextField.box}>
                        <Typography
                          variant="subtitle"
                          gutterBottom
                          component="div"
                          style={labelTextField.typography}
                        >
                          {lang.description}
                        </Typography>
                      </Box>

                      <TextField
                        id={`${id ?? ""}-${lang.name}`}
                        label=""
                        variant="outlined"
                        style={labelTextField.textField1}
                        disabled={disabled}
                        readOnly={readOnly}
                        value={
                          changedLabels.filter((l) => l.l === lang.name)[0]
                            ?.t ?? ""
                        }
                        onChange={(e) =>
                          handleTranslationChange(lang.name, e.target.value)
                        }
                      />

                      {disabled || readOnly ? (
                        <CircleXmark color="critical" />
                      ) : (
                        <>
                          <IconButton
                            onClick={() =>
                              handleTranslationChange(lang?.name, "")
                            }
                          >
                            <CircleXmark color="critical" />
                          </IconButton>
                        </>
                      )}
                    </Stack>
                  ))}
                </Box>

                <VaporToolbar
                  variant="surface"
                  contentLeft={
                    <Button onClick={handleDrawerClose}>Cancel</Button>
                  }
                  contentRight={
                    isSaveBtnEnabled ? (
                      <Button onClick={handleDrawerSave} variant="contained">
                        {t("Confirm")}
                      </Button>
                    ) : (
                      <Button disabled variant="contained">
                        {t("Confirm")}
                      </Button>
                    )
                  }
                />
              </>
            ) : (
              <>
                {/* Header */}
                <HeaderBar
                  title=""
                  divider
                  titleItems={[
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      alignItems={"center"}
                    >
                      <Breadcrumbs separator={<AngleRight color="primary" />}>
                        <Link
                          underline="hover"
                          onClick={handleBrowseDrawerClose}
                        >
                          {t("Translation")}
                        </Link>
                        <Typography>{t("Browse Translations")}</Typography>
                      </Breadcrumbs>
                      <Typography variant="titleMedium">
                        {t("Browse Translations")}
                      </Typography>
                    </Box>,
                  ]}
                  rightItems={[
                    <IconButton
                      onClick={handleBrowseDrawerClose}
                      color="primary"
                    >
                      <CloseIcon />
                    </IconButton>,
                  ]}
                />
                {/* Content */}
                <Box>
                  <Box display={"flex"} gap={2} margin={"20px"}>
                    <FormControl fullWidth sx={{ flexBasis: "50%" }}>
                      <Select
                        id="demo-simple-select"
                        labelId="demo-simple-select-label"
                        label="type"
                        value={selectedLanguage}
                        onChange={(e) =>
                          onHandleLanguageFilter(e.target.value as string)
                        }
                      >
                        {enabledLanguages &&
                          enabledLanguages.map((item, index) => (
                            <MenuItem key={index} value={item.description}>
                              {item.description}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                    <SearchBar
                      width={"100%"}
                      filters={filters}
                      onFilter={onFilter}
                    />
                  </Box>
                  <Box>
                    <TableContainer>
                      <Table aria-label="customized table">
                        <TableHead shadow>
                          <TableCell padding="checkbox">
                            <Checkbox color="primary" />
                          </TableCell>
                          {enabledLanguages &&
                            enabledLanguages.map((item, index) => (
                              <TableCell>{item.description}</TableCell>
                            ))}
                        </TableHead>
                        <TableBody>
                          <TableRow hover>
                            <TableCell padding="checkbox">
                              <Checkbox color="primary" />
                            </TableCell>

                            {enabledLanguages &&
                              enabledLanguages.map((item) => (
                                <TableCell>{item.description}</TableCell>
                              ))}
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Box>
                <VaporToolbar
                  variant="surface"
                  contentLeft={
                    <Button onClick={handleBrowseDrawerClose}>Cancel</Button>
                  }
                  contentRight={
                    <Button
                      onClick={handleBrowseDrawerSave}
                      variant="contained"
                    >
                      {t("Save")}
                    </Button>
                  }
                />
              </>
            )}
          </Drawer>
        </>
      )}
      {/* Confirmation Dialog */}
      <Dialog
        open={isCancelDialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="cancel-dialog-title"
        aria-describedby="cancel-dialog-description"
      >
        <DialogTitle>
          {t("Confirm Cancel")}
          <IconButton color="primary" onClick={handleDialogClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText id="cancel-dialog-description">
            {t("Are you sure you want to close without saving changes?")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleDialogClose}>
            {t("Cancel")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCancelWithoutSaving}
          >
            {t("Close Without Saving")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

LabelTextField.defaultProps = {
  id: null,
  title: "",
  labels: [],
  disabled: false,
  readOnly: false,
  onChange: (labels: Array<IDmsTextTranslation>) => null,
};

export default LabelTextField;
