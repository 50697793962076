import { ClassDtoRead } from "../classes"
import { IDmsXDataItem } from "../component/DmsComponent"
import { IDmsViewDtoRead } from "./DmsViews"

export interface IDmsObjectDtoCreate {
  classId: string
  objectEdit?: IDmsObjectEdit[]
}

export interface IDmsObjectEdit {
  n?: DmsEnumNamespace
  k?: string
  v?: string
  reset?: boolean
  valuesToAdd?: string[]
  valueToRemove?: string[]
}

export enum DmsEnumNamespace {
  // Properties
  PropertyObjectId = 1,
  PropertyClassId = 171,
  PropertyWorkflowStatusId = 140, // Editable only through action execution
  PropertyOwnerId = 131,
  PropertyDateCreation = 2,
  PropertyDateModify = 3,
  PropertyDateChangeStatus = 4,
  PropertyLanguage = 9, // Editable
  PropertyFlagSystem = 111,
  PropertyFlagPrivate = 112, // Editable

	// Document properties
	DocumentId = 2101,
	DocumentName = 2102,
	DocumentDescr = 2103,
	DocumentType = 2104,
	DocumentVersionId = 2105,
	DocumentCurrentId = 2106,
	DocumentVersion = 2107,
	DocumentRelease = 2108,
	DocumentSize = 2120,
	DocumentDateCreation = 2122,
	DocumentDateModify = 2123,

  // Catalog folders
  CatalogFolderId = 1500,

  // Attributes
  AttributeBoolean = 1601,
  AttributeDateTime = 1602,
  AttributeInt = 1603,
  AttributeDecimal = 1604,
  AttributeString = 1605,
  AttributeText = 1606,
  AttributeEnum = 1607,
  AttributeObject = 1620,
  AttributeSubject = 1621,

  // Recipients
  ObjectRecipient = 1300,

  // Links
  LinkToObject = 201,
  LinkToDocument = 210,
  LinkToExternal = 200,

  // Schema permissions
  SchemaObjectOnly = 301,
  SchemaLinkagePropagation = 302,
  SchemaLinkPropagation = 303,
  SchemaFullPropagation = 304,
  SchemaRecipients = 305,

  // Dynamic Action Permissions (DAP)
  DynamicOwner = 1101,
  DynamicOwnerManager = 1102,
  DynamicActionExecutor = 1103,
  DynamicActionExecutorManager = 1104,
  DynamicRecipient = 1104,
  DynamicRecipientManager = 1106,
  DynamicSubjectAttr = 1107,
  DynamicSubjectObjectAttr = 1108,
}

export interface IDmsObjectDtoCreated {
  objectId: string
  classId: string
  workflowId: string
  statusId: string
}

export interface IDmsObjectDtoRead {
  objectId: string
  classId: string
  workflowId: string
  statusId: string
  xData: IDmsXDataItem[]
  classInfo: ClassDtoRead
}

export interface IDmsObjectDtoUpdate {
  objectId: string
  objectEdit: IDmsObjectEdit[]
}

export interface IDmsObjectDtoDocument {
  documentId: number
  documentVersion: number
  documentCurrent: boolean
  documentName: string
  documentDescr: string
  idDS: string
  type: string
  dataCreation: Date
}

export interface IDmsObjectDtoGrid {
  id: string
  objectId: string
  classId: string
  workflowId: string
  statusId: string
  xData: IDmsXDataItem[]
}

export interface IDmsObjectDtoSearchInfo {
  viewConfig?: IDmsViewDtoRead
}
