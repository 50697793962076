import { default as defaultAxios } from "axios";

export const useAxios = (tokenData: string) => {
  // const tokenData = useTokenData(); // Usa il token all'interno del contesto React
  const baseUrl = "https://localhost:7033/";

  const axiosInstance = defaultAxios.create({
    baseURL: baseUrl,
    headers: {
      "Content-Type": "application/json",
    },
  });

  // Interceptor per aggiungere token e lingua alle richieste
  axiosInstance.interceptors.request.use((request) => {
    let jwt: string | null | undefined;
    let interfaceLanguage: string | null | undefined = "it"; // Default to 'it'

    try {
      // Simulazione del recupero del JWT e lingua
      jwt = localStorage.getItem("fakeLogin");
      if (jwt != null) {
        jwt = jwt.replace(/\s/gi, "");
        let list = jwt.trim().split("|");
        if (list.length > 1) interfaceLanguage = list[1];
      }
    } catch (e) {
      console.error("Error retrieving token or language", e);
    }

    request.headers.Authorization = `Bearer ${tokenData}`;
    request.headers["Accept-Language"] = interfaceLanguage;
    return request;
  });

  return axiosInstance; // Ritorna l'istanza Axios configurata
};
