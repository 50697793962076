import { useState, ReactNode } from "react";
import { useSnackbar } from "@1f/react-sdk";
import { AlertTitle, Link, Typography, Box } from "@vapor/react-material";
import { v4 as uuidv4 } from "uuid";
import { IDmsExceptionDto } from "../domain/models/dto/DmsErrors";

export interface SnackbarContextProps {
  showInfo: (message?: string) => void;
  showWarning: (message?: string) => void;
  showSuccess: (message?: string) => void;
  showError: (message?: any) => void;
}

enum SnackbarType {
  Info = "info",
  Warning = "warning",
  Success = "success",
  Error = "error",
}

// interface SnackbarProviderProps {
//   children?: React.ReactNode;
// }

const useCustomSnackbar = () => {
  const [id, setId] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<any>(null);
  const [options, setOptions] = useState<any>(null);
  const [messageType, setMessageType] = useState<SnackbarType>(
    SnackbarType.Info
  );
  const [showDetails, setShowDetails] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();

  const showNotification = (
    message: ReactNode,
    variant: SnackbarType,
    action?: ReactNode,
    options: any = {}
  ) => {
    enqueueSnackbar(message, {
      ...options,
      variant: variant,
      autoHideDuration: messageType !== SnackbarType.Error ? 6000 : null,
      anchorOrigin: {
        horizontal: "right",
        vertical: "top",
      },
      action: action,
    });
  };

  const showInfo = (message?: string, options?: any) => {
    const _id = uuidv4();
    setId(_id);
    setOpen(true);
    setMessageType(SnackbarType.Info);
    setMessage(message);
    options && setOptions(options);
    showNotification(
      messageReview(message),
      SnackbarType.Info,
      undefined,
      options
    );
  };

  const showWarning = (message?: string, options?: any) => {
    const _id = uuidv4();
    setId(_id);
    setOpen(true);
    setMessageType(SnackbarType.Warning);
    setMessage(message);
    options && setOptions(options);
    showNotification(
      messageReview(message),
      SnackbarType.Warning,
      undefined,
      options
    );
  };

  const showSuccess = (message?: string, options?: any) => {
    const _id = uuidv4();
    setId(_id);
    setOpen(true);
    setMessageType(SnackbarType.Success);
    setMessage(message);
    options && setOptions(options);
    showNotification(
      messageReview(message),
      SnackbarType.Success,
      undefined,
      options
    );
  };

  const showError = (message?: any, options?: any) => {
    const _id = uuidv4();
    setId(_id);
    setOpen(true);
    setMessageType(SnackbarType.Error);
    setMessage(message);
    options && setOptions(options);
    showNotification(
      messageReview(message),
      SnackbarType.Error,
      undefined,
      options
    );
  };

  const handleClose = () => {
    setOpen(false);
    setShowDetails(false);
  };

  // const getNotificationIcon = (type: SnackbarType) => {
  //   switch (type) {
  //     case SnackbarType.Info:
  //       return <FontAwesomeIcon icon={faInfoCircle} />;
  //     case SnackbarType.Warning:
  //       return <FontAwesomeIcon icon={faExclamationCircle} />;
  //     case SnackbarType.Success:
  //       return <FontAwesomeIcon icon={faCheckCircle} color={"#09822A"} />;
  //     case SnackbarType.Error:
  //       return <FontAwesomeIcon icon={faMinusCircle} />;
  //     default:
  //       return null;
  //   }
  // };

  const messageReview = (message: any) => {
    if (!message) return "An error occurred!";

    if (typeof message === "string") {
      return message;
    }

    let dmsException: IDmsExceptionDto | undefined;
    if (message?.dmsErrorCode != null) {
      dmsException = message;
    } else if (message?.response?.data?.dmsErrorCode != null) {
      dmsException = message.response.data;
    }

    if (dmsException) {
      return (
        <>
          <AlertTitle>{dmsException.message}</AlertTitle>
          {dmsException.detail}
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "400px",
              whiteSpace: showDetails ? "normal" : "nowrap",
              maxHeight: showDetails ? "300px" : "none",
            }}
          >
            {showDetails && dmsException?.info && (
              <>
                <Box>
                  <Typography variant="bodySmall" noWrap>
                    dmsErrorCode: {dmsException.dmsErrorCode}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="bodySmall" noWrap>
                    source: {dmsException.source}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="bodySmall" noWrap>
                    method: {dmsException.method}
                  </Typography>
                </Box>
                <ul style={{ paddingLeft: "16px", listStyleType: "disc" }}>
                  {dmsException.info.map((item, index) => (
                    <li key={index}>
                      <b>{item.key}</b>: {item.value}
                    </li>
                  ))}
                </ul>
              </>
            )}
          </div>
          {dmsException.info && dmsException.info.length > 0 && (
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() => setShowDetails((prev) => !prev)}
            >
              {showDetails ? "View Less" : "View Details"}
            </Link>
          )}
        </>
      );
    }

    // old code without "show detail" button for other cases
    if (messageType === SnackbarType.Error) {
      return (
        <>
          <AlertTitle>{message.message}</AlertTitle>
          {message?.detail}
        </>
      );
    }

    return message;
  };

  //   return (
  //     <SnackbarContext.Provider
  //       value={{ showInfo, showWarning, showSuccess, showError }}
  //     >
  //       {children}
  //       {enqueueSnackbar(messageReview(message), {
  //         variant: messageType,
  //         autoHideDuration: messageType !== SnackbarType.Error ? 6000 : null,
  //         anchorOrigin: {
  //           horizontal: "right",
  //           vertical: "top",
  //         },
  //       })}
  //     </SnackbarContext.Provider>
  //   );

  return {
    showError,
    showInfo,
    showWarning,
    showSuccess,
  };
};

export default useCustomSnackbar;
