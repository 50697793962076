// import i18next from "../../i18n/i18n";
import { DmsEnumSearchFilterCondition } from "../../domain/models/dto/DmsSearchFilters";
import { FilterInputComponent } from "./FilterInputComponent";

const t = (label: string) => {
  return label;
};
export const emptyFilterOperators = [
  {
    value: DmsEnumSearchFilterCondition.IsEmpty,
    label: t("FilterCondition.IsEmpty"),
    InputComponent: FilterInputComponent,
  },
  {
    value: DmsEnumSearchFilterCondition.IsNotEmpty,
    label: t("FilterCondition.IsNotEmpty"),
    InputComponent: FilterInputComponent,
  },
];

export const stringFilterOperators = [
  {
    value: DmsEnumSearchFilterCondition.StringContains,
    label: t("FilterCondition.Contains"),
    InputComponent: FilterInputComponent,
    InputComponentProps: { type: "string" },
  },
  {
    value: DmsEnumSearchFilterCondition.StringNotContains,
    label: t("FilterCondition.NotContains"),
    InputComponent: FilterInputComponent,
    InputComponentProps: { type: "string" },
  },
  {
    value: DmsEnumSearchFilterCondition.StringEqual,
    label: t("FilterCondition.Is"),
    InputComponent: FilterInputComponent,
    InputComponentProps: { type: "string" },
  },
  {
    value: DmsEnumSearchFilterCondition.StringNotEqual,
    label: t("FilterCondition.IsNot"),
    InputComponent: FilterInputComponent,
    InputComponentProps: { type: "string" },
  },
  {
    value: DmsEnumSearchFilterCondition.StringStartWith,
    label: t("FilterCondition.StartsWith"),
    InputComponent: FilterInputComponent,
    InputComponentProps: { type: "string" },
  },
  {
    value: DmsEnumSearchFilterCondition.StringEndsWith,
    label: t("FilterCondition.EndsWith"),
    InputComponent: FilterInputComponent,
    InputComponentProps: { type: "string" },
  },
  {
    value: DmsEnumSearchFilterCondition.StringBetween,
    label: t("FilterCondition.IsBetween"),
    InputComponent: FilterInputComponent,
    InputComponentProps: { type: "string" },
  },
  {
    value: DmsEnumSearchFilterCondition.StringSelectedValues,
    label: t("FilterCondition.IsAny"),
    InputComponent: FilterInputComponent,
    InputComponentProps: { type: "string" },
  },
  {
    value: DmsEnumSearchFilterCondition.IsEmpty,
    label: t("FilterCondition.IsEmpty"),
  },
  {
    value: DmsEnumSearchFilterCondition.IsNotEmpty,
    label: t("FilterCondition.IsNotEmpty"),
  },
];

export const intFilterOperators = [
  {
    value: DmsEnumSearchFilterCondition.IntEqual,
    label: "=",
    InputComponent: FilterInputComponent,
    InputComponentProps: { type: "number" },
  },
  {
    value: DmsEnumSearchFilterCondition.IntGreater,
    label: ">",
    InputComponent: FilterInputComponent,
    InputComponentProps: { type: "number" },
  },
  {
    value: DmsEnumSearchFilterCondition.IntGreaterEqual,
    label: ">=",
    InputComponent: FilterInputComponent,
    InputComponentProps: { type: "number" },
  },
  {
    value: DmsEnumSearchFilterCondition.IntLess,
    label: "<",
    InputComponent: FilterInputComponent,
    InputComponentProps: { type: "number" },
  },
  {
    value: DmsEnumSearchFilterCondition.IntLessEqual,
    label: "<=",
    InputComponent: FilterInputComponent,
    InputComponentProps: { type: "number" },
  },
  {
    value: DmsEnumSearchFilterCondition.IntBetween,
    label: ">= x <=",
    InputComponent: FilterInputComponent,
    InputComponentProps: { type: "number" },
  },
];

export const idFilterOperators = [
  {
    value: DmsEnumSearchFilterCondition.IdEqual,
    label: t("FilterCondition.Is"),
    InputComponent: FilterInputComponent,
    InputComponentProps: { type: "string" },
  },
  // { value: "isNot", label: i18next.t("FilterCondition.IsNot"), InputComponent: FilterInputComponent, InputComponentProps: { type: "string" }  },
  {
    value: DmsEnumSearchFilterCondition.IdGreater,
    label: t("FilterCondition.GreaterThan"),
    InputComponent: FilterInputComponent,
    InputComponentProps: { type: "string" },
  },
  {
    value: DmsEnumSearchFilterCondition.IdGreaterEqual,
    label: t("FilterCondition.GreaterEqual"),
    InputComponent: FilterInputComponent,
    InputComponentProps: { type: "string" },
  },
  {
    value: DmsEnumSearchFilterCondition.IdLess,
    label: t("FilterCondition.LessThan"),
    InputComponent: FilterInputComponent,
    InputComponentProps: { type: "string" },
  },
  {
    value: DmsEnumSearchFilterCondition.IdLessEqual,
    label: t("FilterCondition.LessEqual"),
    InputComponent: FilterInputComponent,
    InputComponentProps: { type: "string" },
  },
  {
    value: DmsEnumSearchFilterCondition.IdBetween,
    label: t("FilterCondition.IsBetween"),
    InputComponent: FilterInputComponent,
    InputComponentProps: { type: "string" },
  },
];

export const booleanFilterOperators = [
  {
    value: DmsEnumSearchFilterCondition.BooleanEqual,
    label: t("FilterCondition.Is"),
    InputComponent: (props: any) => (
      <FilterInputComponent {...props} type="boolean" />
    ),
  },
  {
    value: DmsEnumSearchFilterCondition.IsEmpty,
    label: t("FilterCondition.IsEmpty"),
    InputComponent: null,
  },
  {
    value: DmsEnumSearchFilterCondition.IsNotEmpty,
    label: t("FilterCondition.IsNotEmpty"),
    InputComponent: null,
  },
];

export const datetimeFilterOperators = [
  {
    value: DmsEnumSearchFilterCondition.DatetimeEqual,
    label: t("FilterCondition.Is"),
    InputComponent: (props: any) => (
      <FilterInputComponent {...props} type="datetime" />
    ),
  },
  {
    value: DmsEnumSearchFilterCondition.DatetimeBetween,
    label: t("FilterCondition.IsBetween"),
    InputComponent: (props: any) => (
      <FilterInputComponent {...props} type="datetime" />
    ),
  },
  {
    value: DmsEnumSearchFilterCondition.DatetimeBefore,
    label: t("FilterCondition.IsBefore"),
    InputComponent: (props: any) => (
      <FilterInputComponent {...props} type="datetime" />
    ),
  },
  {
    value: DmsEnumSearchFilterCondition.DatetimeAfter,
    label: t("FilterCondition.IsAfter"),
    InputComponent: (props: any) => (
      <FilterInputComponent {...props} type="datetime" />
    ),
  },
  {
    value: DmsEnumSearchFilterCondition.IsEmpty,
    label: t("FilterCondition.IsEmpty"),
    InputComponent: null,
  },
  {
    value: DmsEnumSearchFilterCondition.IsNotEmpty,
    label: t("FilterCondition.IsNotEmpty"),
    InputComponent: null,
  },
];

export const decimalFilterOperators = [
  {
    value: DmsEnumSearchFilterCondition.DecimalEqual,
    label: t("FilterCondition.Is"),
    InputComponent: FilterInputComponent,
  },
  {
    value: DmsEnumSearchFilterCondition.DecimalGreater,
    label: t("FilterCondition.GreaterThan"),
    InputComponent: FilterInputComponent,
  },
  {
    value: DmsEnumSearchFilterCondition.DecimalGreaterEqual,
    label: ">=",
    InputComponent: FilterInputComponent,
  },
  {
    value: DmsEnumSearchFilterCondition.DecimalLess,
    label: "<",
    InputComponent: FilterInputComponent,
  },
  {
    value: DmsEnumSearchFilterCondition.DecimalLessEqual,
    label: "<=",
    InputComponent: FilterInputComponent,
  },
  {
    value: DmsEnumSearchFilterCondition.DecimalBetween,
    label: "< x <",
    InputComponent: FilterInputComponent,
  },
  // { value: "isEmpty", label: i18next.t("FilterCondition.IsEmpty"), InputComponent: null },
  // { value: "isNotEmpty", label: i18next.t("FilterCondition.IsNotEmpty"), InputComponent: null },
];

export const labelDescrFilterOperators = [
  // { value: "is", label: "is", InputComponent: FilterInputComponent },
  // { value: "isNot", label: "isNot", InputComponent: FilterInputComponent },
  // { value: "contains", label: "contains", InputComponent: FilterInputComponent },
  // { value: "notContains", label: "notContains", InputComponent: FilterInputComponent },
  // { value: "containsValues", label: "containsValues", InputComponent: FilterInputComponent },
  // { value: "startsWith", label: "startsWith", InputComponent: FilterInputComponent },
  // { value: "endsWith", label: "endsWith", InputComponent: FilterInputComponent },
  // { value: "isBetween", label: "isBetween", InputComponent: FilterInputComponent },
  // { value: "selectValue", label: "Select value", InputComponent: FilterInputComponent },
  {
    value: DmsEnumSearchFilterCondition.StringContains,
    label: t("FilterCondition.Contains"),
    InputComponent: FilterInputComponent,
    InputComponentProps: { type: "string" },
  },
  {
    value: DmsEnumSearchFilterCondition.StringNotContains,
    label: t("FilterCondition.NotContains"),
    InputComponent: FilterInputComponent,
    InputComponentProps: { type: "string" },
  },
  {
    value: DmsEnumSearchFilterCondition.StringEqual,
    label: t("FilterCondition.Is"),
    InputComponent: FilterInputComponent,
    InputComponentProps: { type: "string" },
  },
  {
    value: DmsEnumSearchFilterCondition.StringNotEqual,
    label: t("FilterCondition.IsNot"),
    InputComponent: FilterInputComponent,
    InputComponentProps: { type: "string" },
  },
  {
    value: DmsEnumSearchFilterCondition.StringStartWith,
    label: t("FilterCondition.StartsWith"),
    InputComponent: FilterInputComponent,
    InputComponentProps: { type: "string" },
  },
  {
    value: DmsEnumSearchFilterCondition.StringEndsWith,
    label: t("FilterCondition.EndsWith"),
    InputComponent: FilterInputComponent,
    InputComponentProps: { type: "string" },
  },
  {
    value: DmsEnumSearchFilterCondition.StringBetween,
    label: t("FilterCondition.IsBetween"),
    InputComponent: FilterInputComponent,
    InputComponentProps: { type: "string" },
  },
  {
    value: DmsEnumSearchFilterCondition.StringSelectedValues,
    label: t("FilterCondition.IsAny"),
    InputComponent: FilterInputComponent,
    InputComponentProps: { type: "string" },
  },
  {
    value: DmsEnumSearchFilterCondition.IsEmpty,
    label: t("FilterCondition.IsEmpty"),
  },
  {
    value: DmsEnumSearchFilterCondition.IsNotEmpty,
    label: t("FilterCondition.IsNotEmpty"),
  },
];

export const translationCondition = (type: string, id: string) => {
  let cond;
  switch (type) {
    case "id":
      cond = idFilterOperators.find((i) => i.value === id);
      break;
    case "text-translation":
      cond = labelDescrFilterOperators.find((i) => i.value === id);
      break;
    case "string":
      cond = stringFilterOperators.find((i) => i.value === id);
      break;
    case "int":
      cond = intFilterOperators.find((i) => i.value === id);
      break;
    case "bool":
      cond = booleanFilterOperators.find((i) => i.value === id);
      break;
    case "datetime":
      cond = datetimeFilterOperators.find((i) => i.value === id);
      break;
    case "decimal":
      cond = decimalFilterOperators.find((i) => i.value === id);
      break;
    default:
      cond = emptyFilterOperators.find((i) => i.value === id);
      break;
  }
  return typeof cond !== "undefined" ? cond.label : "";
};
