// import { t } from "i18next";
import {
  GridActionsColDef,
  GridCellParams,
  GridColDef,
  GridColType,
  GridRenderCellParams,
  GridRowParams,
} from "@mui/x-data-grid-pro";
import { getTextTranslation } from "../../utils/helpers";
import { IDmsTextTranslation } from "../../domain/models/component/DmsComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Radio } from "@vapor/react-material";
import { faCheck } from "@fortawesome/pro-regular-svg-icons/faCheck";
import { faFile } from "@fortawesome/pro-regular-svg-icons/faFile";
import { faFileLines } from "@fortawesome/pro-regular-svg-icons/faFileLines";
import { faFileExcel } from "@fortawesome/pro-regular-svg-icons/faFileExcel";
import { faFilePdf } from "@fortawesome/pro-regular-svg-icons/faFilePdf";
import { faEnvelope } from "@fortawesome/pro-regular-svg-icons/faEnvelope";
import { faImage } from "@fortawesome/pro-regular-svg-icons/faImage";
import { faSignature } from "@fortawesome/pro-regular-svg-icons/faSignature";
import { DmsEnumSubjectType } from "../../domain/models/value/DmsEnum";
import DmsHelpers from "../../utils/DmsHelpers";
import { faUserShield } from "@fortawesome/pro-solid-svg-icons/faUserShield";
import { faUserGroup } from "@fortawesome/pro-solid-svg-icons/faUserGroup";
import { faUser } from "@fortawesome/pro-solid-svg-icons/faUser";

export interface IStandardColumnOption {
  width?: number;
  minWidth?: number;
  maxWidth?: number;
  filterable?: boolean;
  sortable?: boolean;
  visible?: boolean; // Require management of columnVisibilityModel like for object views
}

export interface CustomGridColDef extends Omit<GridColDef, "type"> {
  type?: "id" | "text" | "int" | GridColDef["type"]; // Aggiungi il tipo personalizzato 'id'
  visible?: boolean;
  width?: number;
}

export const StandardColumns = {
  //#region ===== Common =====
  /** field: "id" */
  id: (t: any, option?: Partial<CustomGridColDef>): CustomGridColDef => {
    const column: CustomGridColDef = {
      field: "id",
      headerName: `${t("ID")}`,
      type: "id",
      width: 240,
      editable: false,
      hideable: true,
      filterable: false,
      visible: false,
      ...option,
    };
    return column;
  },

  /** field: "name" */
  name: (t: any, option?: Partial<CustomGridColDef>): CustomGridColDef => {
    const column: CustomGridColDef = {
      field: "name",
      headerName: t("Name"),
      type: "string",
      width: option?.width,
      // minWidth: 100,
      // maxWidth: 250,
      editable: false,
      ...option,
    };
    return column;
  },

  /** field: "label" */
  label: (t: any, option?: Partial<CustomGridColDef>): CustomGridColDef => {
    const column: CustomGridColDef = {
      field: "label",
      headerName: `${t("Label")}`,
      type: "text",
      width: option?.width ?? 300,
      editable: false,
      valueGetter: (params: GridCellParams) => {
        return getTextTranslation(
          params.row.label as IDmsTextTranslation[],
          "-"
        );
      },
      ...option,
    };
    return column;
  },

  /** field: "descr" */
  descr: (t: any, option?: Partial<CustomGridColDef>): CustomGridColDef => {
    const column: CustomGridColDef = {
      field: "descr",
      headerName: `${t("Description")}`,
      type: "string",
      width: option?.width ?? 300,
      editable: false,
      valueGetter: (params: GridCellParams) => {
        return getTextTranslation(
          params.row.descr as IDmsTextTranslation[],
          "-"
        );
      },
      ...option,
    };
    return column;
  },

  /** field: "flagSystem" */
  flagSystem: (
    t: any,
    option?: Partial<CustomGridColDef>
  ): CustomGridColDef => {
    const column: CustomGridColDef = {
      field: "flagSystem",
      headerName: t("Protected"),
      type: "boolean",
      width: option?.width ?? 60,
      editable: false,
      renderCell: (params: GridRenderCellParams<any, boolean>) => {
        return (
          <Box>
            {params.row.flagSystem === true ? (
              <FontAwesomeIcon color="#008FD6" size="lg" icon={faCheck} />
            ) : (
              "-"
            )}
          </Box>
        );
      },
      ...option,
    };
    return column;
  },

  flagCustom: (
    t: any,
    option?: Partial<CustomGridColDef>
  ): CustomGridColDef => {
    const column: CustomGridColDef = {
      field: "flagCustom",
      headerName: `${t("Predefined")}`,
      type: "boolean",
      width: option?.width ?? 60,
      editable: false,
      renderCell: (params: GridRenderCellParams<any, boolean>) => {
        return (
          <Box>
            {params.row.flagCustom! === true ? (
              <FontAwesomeIcon color="#008FD6" size="lg" icon={faCheck} />
            ) : (
              "-"
            )}
          </Box>
        );
      },
      ...option,
    };
    return column;
  },

  //#endregion

  //#region ===== Attributes =====

  /** field: "attributeId" */
  attributeId: (
    t: any,
    option?: Partial<CustomGridColDef>
  ): CustomGridColDef => {
    const column: CustomGridColDef = {
      field: "attributeId",
      headerName: `${t("Attribute ID")}`,
      type: "id",
      width: option?.width ?? 240,
      editable: false,
      ...option,
    };
    return column;
  },

  /** field: "type" */
  attributeType: (
    t: any,
    option?: Partial<CustomGridColDef>
  ): CustomGridColDef => {
    const column: CustomGridColDef = {
      field: "type",
      headerName: `${t("Type")}`,
      type: "string",
      width: option?.width ?? 300,
      editable: false,
      filterable: false,
      valueGetter: (params: GridCellParams) => {
        try {
          switch ((params.value as string)?.toLowerCase()) {
            case "bool":
              return t("AttributeType.bool");
            case "int":
              return t("AttributeType.int");
            case "decimal":
              return t("AttributeType.decimal");
            case "string":
              return t("AttributeType.string");
            case "text":
              return t("AttributeType.text");
            case "datetime":
              return t("AttributeType.datetime");
            case "enum":
              return t("AttributeType.enum");
            case "object":
              return t("AttributeType.object");
            case "subject":
              return t("AttributeType.subject");
          }
          return params.value;
        } catch (e) {
          return "?";
        }
      },
      ...option,
    };
    return column;
  },

  /** field: "name" */
  attributeName: (
    t: any,
    option?: Partial<CustomGridColDef>
  ): CustomGridColDef => {
    const column: CustomGridColDef = {
      field: "name",
      headerName: `${t("Name")}`,
      type: "string",
      width: option?.width ?? 300,
      editable: false,
      valueGetter: (params: GridCellParams) => {
        try {
          return params.row.attributeInfo.name;
        } catch (e) {
          return "?";
        }
      },
      ...option,
    };
    return column;
  },

  /** field: "label" */
  attributeLabel: (
    t: any,
    option?: Partial<CustomGridColDef>
  ): CustomGridColDef => {
    const column: CustomGridColDef = {
      field: "label",
      headerName: `${t("Label")}`,
      type: "string",
      width: option?.width ?? 200,
      editable: false,
      valueGetter: (params: GridCellParams) => {
        let result: string | null | undefined;
        try {
          result = getTextTranslation(params.row.label);
          if (result == null) {
            result = getTextTranslation(
              params.row.attributeInfo.label as IDmsTextTranslation[]
            );
            if (result != null) result += " *";
          }
        } catch (e) {
          result = null;
        }
        if (result == null || result === "") result = "-";
        return result;
      },
      ...option,
    };
    return column;
  },

  /** field: "descr" */
  attributeDescr: (
    t: any,
    option?: Partial<CustomGridColDef>
  ): CustomGridColDef => {
    const column: CustomGridColDef = {
      field: "descr",
      headerName: `${t("Description")}`,
      type: "string",
      width: option?.width ?? 300,
      editable: false,
      valueGetter: (params: GridCellParams) => {
        let result: string | null | undefined;
        try {
          result = getTextTranslation(params.row.descr);
          if (result == null) {
            result = getTextTranslation(
              params.row.attributeInfo.descr as IDmsTextTranslation[]
            );
            if (result != null) result += " *";
          }
        } catch (e) {
          result = null;
        }
        if (result == null || result === "") result = "-";
        return result;
      },
      ...option,
    };
    return column;
  },

  //#endregion

  //#region ===== CatalogFolders =====

  /** field: "catalogId" */
  catalogId: (t: any, option?: Partial<CustomGridColDef>): CustomGridColDef => {
    const column: CustomGridColDef = {
      field: "catalogId",
      headerName: `${t("Catalog ID")}`,
      type: "id",
      width: option?.width ?? 240,
      editable: false,
      ...option,
    };
    return column;
  },

  //#endregion

  //#region ===== Classes =====

  /** field: "classId" */
  classId: (t: any, option?: Partial<CustomGridColDef>): CustomGridColDef => {
    const column: CustomGridColDef = {
      field: "classId",
      headerName: `${t("Publication type ID")}`,
      type: "id",
      width: option?.width ?? 240,
      editable: false,
      ...option,
    };
    return column;
  },

  //#endregion

  //#region ===== Documents

  documentId: (
    t: any,
    option?: Partial<CustomGridColDef>
  ): CustomGridColDef => {
    const column: CustomGridColDef = {
      field: "documentId",
      headerName: `${t("Document ID")}`,
      type: "id",
      width: option?.width ?? 240,
      editable: false,
      ...option,
    };
    return column;
  },

  fileName: (t: any, option?: Partial<CustomGridColDef>): CustomGridColDef => {
    const column: CustomGridColDef = {
      field: "fileName",
      headerName: `${t("File name")}`,
      type: "string",
      width: option?.width ?? 240,
      editable: false,
      ...option,
    };
    return column;
  },

  fileFormat: (
    t: any,
    option?: Partial<CustomGridColDef>
  ): CustomGridColDef => {
    const column: CustomGridColDef = {
      field: "fileFormat",
      headerName: `${t("Format")}`,
      type: "string",
      width: option?.width ?? 240,
      editable: false,
      renderCell: (params: GridRenderCellParams<any, boolean>) => {
        let fileExtension = DmsHelpers.getFileExtension(params.row.fileName);
        let icon = faFile;
        switch (fileExtension) {
          case "doc":
          case "docx":
          case "dot":
          case "dotx":
          case "odt":
          case "rtf":
          case "txt":
            icon = faFileLines;
            break;
          case "ods":
          case "xls":
          case "xlsx":
            icon = faFileExcel;
            break;
          case "pdf":
            icon = faFilePdf;
            break;
          case "msg":
          case "eml":
            icon = faEnvelope;
            break;
          case "gif":
          case "png":
          case "jpg":
            icon = faImage;
            break;
          case "m7m":
          case "p7m":
          case "p7s":
          case "tsr":
          case "tsd":
          case "tst":
            icon = faSignature;
            break;
        }
        return (
          <Box>
            <FontAwesomeIcon color="#008FD6" size="lg" icon={icon} />{" "}
            {fileExtension}{" "}
          </Box>
        );
      },
      ...option,
    };
    return column;
  },

  fileSize: (t: any, option?: Partial<CustomGridColDef>): CustomGridColDef => {
    const column: CustomGridColDef = {
      field: "fileSize",
      headerName: `${t("File size")}`,
      type: "string",
      width: option?.width ?? 240,
      editable: false,
      valueGetter: (params: GridCellParams) => {
        try {
          var value = DmsHelpers.parseInt(params.row.fileSize, 0);
          return DmsHelpers.formatDocSize(value);
        } catch (e) {
          return "?";
        }
      },
      ...option,
    };
    return column;
  },

  //#endregion

  //#region ===== File Types =====
  fileExtension: (t: any, option: IStandardColumnOption) => {
    const column: CustomGridColDef = {
      field: "fileExtension",
      headerName: t("File extension"),
      type: "string",
      width: option?.width ?? 300,
      editable: false,
      ...option,
    };
    return column;
  },

  fileMimeType: (t: any, option: IStandardColumnOption) => {
    const column: CustomGridColDef = {
      field: "mimeType",
      headerName: t("Type"),
      type: "string",
      width: option?.width ?? 300,
      editable: false,
      ...option,
    };
    return column;
  },
  //#endregion

  //#region ===== Subjects =====

  /** field: "subjectId" */
  subjectId: (t: any, option?: Partial<CustomGridColDef>): CustomGridColDef => {
    const column: CustomGridColDef = {
      field: "subjectId",
      headerName: `${t("Subject ID")}`,
      type: "id",
      width: option?.width ?? 240,
      editable: false,
      ...option,
    };
    return column;
  },

  subjectType: (
    t: any,
    option?: Partial<CustomGridColDef>
  ): CustomGridColDef => {
    const column: CustomGridColDef = {
      field: "type",
      headerName: `${t("Type")}`,
      type: "int",
      filterable: false,
      width: option?.width ?? 100,
      editable: false,
      renderCell: (params: any) => {
        // console.log(params.row.type, "params")
        if (params.row.type === 1) {
          return (
            <Box>
              <FontAwesomeIcon color="#00AAFF" icon={faUser} /> User{" "}
            </Box>
          );
        } else if (params.row.type === 2) {
          return (
            <Box>
              <FontAwesomeIcon color="#006699" icon={faUserGroup} /> Group{" "}
            </Box>
          );
        } else if (params.row.type === 3) {
          return (
            <Box>
              <FontAwesomeIcon icon={faUserShield} color="#7745EC" /> Role{" "}
            </Box>
          );
        }
      },
      ...option,
    };
    return column;
  },

  subjectLogin: (
    t: any,
    option?: Partial<CustomGridColDef>
  ): CustomGridColDef => {
    const column: CustomGridColDef = {
      field: "login",
      headerName: `${t("Teamsystem ID")}`,
      editable: false,
      width: option?.width ?? 300,
      type: "string",
      valueGetter: (params: GridCellParams) => {
        let log: string | null | undefined;
        try {
          if (params.row.type === DmsEnumSubjectType.User) {
            log = params.row.login;
          } else {
            log = null;
          }
        } catch (e) {
          log = null;
        }
        if (log == null || log === "") log = "-";
        return log;
      },
      ...option,
    };
    return column;
  },
  //#endregion

  //#region ===== Objects =====
  objectId: (t: any, option?: Partial<CustomGridColDef>): CustomGridColDef => {
    const column: CustomGridColDef = {
      field: "objectId",
      headerName: `${t("Publication ID")}`,
      type: "id",
      width: option?.width ?? 240,
      editable: false,
      ...option,
    };
    return column;
  },
  //#endregion

  //#region ===== Views =====

  /** field: "viewId" */
  viewId: (t: any, option?: Partial<CustomGridColDef>): CustomGridColDef => {
    const column: CustomGridColDef = {
      field: "viewId",
      headerName: `${t("View ID")}`,
      type: "id",
      width: option?.width ?? 240,
      editable: false,
      ...option,
    };
    return column;
  },

  /** field: "visibility" */
  viewVisibility: (
    t: any,
    option?: Partial<CustomGridColDef>
  ): CustomGridColDef => {
    const column: CustomGridColDef = {
      field: "visibility",
      headerName: t("Visibility"),
      type: "string",
      width: option?.width ?? 100,
      editable: false,
      valueGetter: (params: GridCellParams) => {
        try {
          return (params.row.flagCommon as boolean)
            ? t("Common")
            : t("Personal");
        } catch (e) {
          return "?";
        }
      },
      ...option,
    };
    return column;
  },

  viewVisibilityAttr: (t: any, option: IStandardColumnOption) => {
    const column: CustomGridColDef = {
      field: "visibility",
      headerName: t("Visibility"),
      type: "id",
      editable: false,
      width: option?.width ?? 100,
      renderCell: (params: GridCellParams) => {
        return (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            {params.row.show ? (
              <FontAwesomeIcon color="#008FD6" size="lg" icon={faCheck} />
            ) : (
              "-"
            )}
          </Box>
        );
      },
      ...option,
    };
    return column;
  },

  //#endregion

  //#region ===== Workflow =====

  /** field: "workflowInfo" */
  workflowInfo: (
    t: any,
    option?: Partial<CustomGridColDef>
  ): CustomGridColDef => {
    const column: CustomGridColDef = {
      field: "workflowInfo",
      headerName: `${t("Workflow")}`,
      type: "string",
      width: option?.width ?? 300,
      editable: false,
      valueGetter: (params: GridCellParams) => {
        let result: string | null | undefined;
        try {
          result = getTextTranslation(
            params.row.workflowInfoList[0].label as IDmsTextTranslation[],
            params.row.workflowInfoList[0].name
          );
        } catch (e) {
          result = null;
        }
        if (result == null || result === "") result = "-";
        return result;
      },
      ...option,
    };
    return column;
  },

  //#endregion
};
