import { useState, useEffect } from "react"
import { Stack, Button, TextField, IconButton } from "@vapor/react-material"
import SearchIcon from "@mui/icons-material/Search"
import { CircleXmark } from "@vapor/react-icons"

interface Props {
  filters: string
  onFilter: (newFilters: string) => void
  width?: string
  useCircleXmarkbutton?: boolean
  label?: string
  widthNotUndred?:boolean
  noMaxWidth?:boolean
}
let timer: NodeJS.Timeout

const SearchBar = ({ filters, onFilter, width, label, useCircleXmarkbutton,widthNotUndred,noMaxWidth }: Props) => {
  const [text, setText] = useState<string | null | undefined>("")

  //#region Effects

  useEffect(() => {
    setText(filters)
  }, [filters])

  //#endregion

  const onTextChange = (e: any) => {
    clearTimeout(timer)
    let newText = e.target.value
    setText(newText)

    // Set a new timer to update the value after debounceTime milliseconds
    timer = setTimeout(() => {
      onFilter(newText)
    }, 500)
  }

  const onSearchClick = () => {
    clearTimeout(timer)
    onFilter(text ?? "")
  }

  const onClear = () => {
    clearTimeout(timer)
    setText("")
    onFilter("")
  }

  return (
    <>
      <Stack alignItems={"end"} direction="row" style={noMaxWidth?{}: widthNotUndred?{maxWidth: "300px"}:{ maxWidth: "300px", width: "100%" }} >
        <TextField sx={{ width: width }} value={text} onChange={onTextChange} />
        <Button
          sx={{
            "&.MuiButtonBase-root.MuiButton-root.MuiButton-sizeMedium": {
              borderRadius: 0,
              minWidth: 40,
              p: 1,
            },
          }}
          size="medium"
          variant="contained"
          onClick={onSearchClick}
        >
          <SearchIcon sx={{ color: "white" }} />
        </Button>
        {useCircleXmarkbutton ? (
          <IconButton onClick={onClear} sx={{ visibility: text && text?.length > 0 ? "visible" : "hidden" }}>
            <CircleXmark color="critical" />
          </IconButton>
        ) : (
          <></>
        )}
      </Stack>
    </>
  )
}
export default SearchBar
