import { OneBox } from "@1f/react-starter-sdk";

import { examples } from "./features/examples";
import { dashboard } from "./features/dashboard";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux"; // Importa il Provider
import { store } from "./store/store";
import { rolesPage } from "./features/Roles";
// import "./i18n/i18n"; // Assicurati di importare il file di configurazione

const proxyBaseUrl = import.meta.env.VITE_APP_PROXY_BASE_URL || "/api";
//const proxyBaseUrl = import.meta.env.VITE_APP_PROXY_BASE_URL || "/";

/*
  In production, the VITE_APP_ID should be set in the environment variables of the pipeline,
  The default to demo is needed to redirect to localhost:3000 in development
*/
const appId = import.meta.env.VITE_APP_ID || "rnd::dms::app";
// const rootElement = document.getElementById("root") as HTMLElement;
// ReactDOM.createRoot(rootElement).render(
//   <React.StrictMode>
//     <Provider store={store}>
//       <>
// {
OneBox.run({
  settings: {
    one: {
      appId,
      axios: {
        proxy: {
          baseUrl: proxyBaseUrl,
        },
      },
      box: {
        module: {
          name: "rnd::dms::app", // <--- Set to your bu/island name, for example if the URN of your app is "PORTAL://homepage" this value should be "PORTAL"
        },
        menu: {
          show: true, // <--- Set to true to show the powerboard instead of the example feature
        },
        powerboard: {
          show: true, // <--- Set to true to show the powerboard instead of the example feature
        },
      },
      layout: { title: "DMS Cloud" },
      i18n: {
        options: {
          // Load all known namespaces
          // (more namespaces will be loaded on demand)
          ns: ["react-sdk", "rnd::dms::app"],
          defaultNS: "rnd::dms::app",
          expirationTime: 300000, // default value is set to 3600000 milliseconds
        },
      },
    },
  },
  features: [dashboard, examples, rolesPage],
  services: [],
}).catch(console.error);
// }
// </>
//   </Provider>
// </React.StrictMode>
// );
