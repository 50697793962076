import {
  GridFilterModel,
  GridPaginationModel,
  GridRowId,
  GridRowSelectionModel,
} from "@mui/x-data-grid-pro";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface PaginationModel {
  page: number;
  pageSize: number;
}

interface PageState {
  paginationModel: PaginationModel;
  filterModel: GridFilterModel;
  selectedRows?: string[];
}

export interface DmsDataGridProPageState {
  [contextID: string]: {
    paginationModel: PaginationModel;
    filterModel: GridFilterModel;
    rowSelectionModel?: GridRowSelectionModel;
    savedPageState: PageState | null;
  };
}

const initialState: DmsDataGridProPageState = {
  default: {
    paginationModel: { page: 0, pageSize: 5 },
    filterModel: { items: [] },
    rowSelectionModel: [],
    savedPageState: null,
  },
};

const dmsDataGridProPageSlice = createSlice({
  name: "dmsDataGridProPage",
  initialState,
  reducers: {
    // setRowsCount(state, action: PayloadAction<{ rowCount: number }>) {
    //   state.rowsCount = action.payload.rowCount
    // },
    setPaginationModel(
      state,
      action: PayloadAction<{
        contextId: string;
        paginationModel: GridPaginationModel;
      }>
    ) {
      const { contextId, paginationModel } = action.payload;
      if (!state[contextId]) state[contextId] = state["default"];
      state[contextId].paginationModel = paginationModel;
    },
    setFilterModel(
      state,
      action: PayloadAction<{ contextId: string; filterModel: GridFilterModel }>
    ) {
      const { contextId, filterModel } = action.payload;
      if (!state[contextId]) state[contextId] = state["default"];
      state[contextId].filterModel = filterModel;
    },
    setRowSelectionModel(
      state,
      action: PayloadAction<{
        contextId: string;
        rowSelectionModel: GridRowId[];
      }>
    ) {
      const { contextId, rowSelectionModel } = action.payload;
      if (!state[contextId]) state[contextId] = state["default"];
      state[contextId].rowSelectionModel = rowSelectionModel;
    },
    resetPage(state, action: PayloadAction<{ contextId: string }>) {
      const { contextId } = action.payload;
      state[contextId] = state["default"];
    },
    // savePageState(state, action: PayloadAction<{ contextId: string }>) {
    //   const { contextId } = action.payload
    //   state[contextId].savedPageState = { paginationModel: state[contextId].paginationModel, filterModel: state[contextId].filterModel, selectedRows: state[contextId].selectedRows || [] }
    // },
    // restorePageState(state, action: PayloadAction<{ contextId: string }>) {
    //   const { contextId } = action.payload
    //   if (state[contextId] && state[contextId].savedPageState) {
    //     state[contextId].paginationModel = state[contextId].savedPageState!.paginationModel
    //     state[contextId].filterModel = state[contextId].savedPageState!.filterModel
    //     const selectedRow = state[contextId].savedPageState!.selectedRows || []
    //     state[contextId].selectedRows = selectedRow
    //     state[contextId].savedPageState = null // Ripristina e azzera lo stato salvato
    //   }
    // },
  },
});

//export const { setPaginationModel, setFilterModel, resetPage, savePageState, restorePageState } = dmsDataGridProPageSlice.actions
export const {
  setPaginationModel,
  setFilterModel,
  setRowSelectionModel,
  resetPage,
} = dmsDataGridProPageSlice.actions;

export default dmsDataGridProPageSlice.reducer;
