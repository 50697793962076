import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface RolesPageState {
  searchText: string;
}

const initialState: RolesPageState = {
  searchText: "",
};

const rolesPageSlice = createSlice({
  name: "rolesPage",
  initialState,
  reducers: {
    setSearchText(state, action: PayloadAction<{ searchText: string }>) {
      state.searchText = action.payload.searchText;
    },
    resetRolesPage(state) {
      state.searchText = initialState.searchText;
    },
  },
});

export const {
  // setRowsCount,
  setSearchText,
  resetRolesPage,
} = rolesPageSlice.actions;

export const { actions, reducer } = rolesPageSlice;
export default rolesPageSlice.reducer;
