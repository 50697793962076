// import { IAlyanteWebApiConfig } from "./container/IAlyanteWebApiConfig"
// import { ALYANTE_WEBAPI_CONFIG } from "./constants"
import { EMembershipTab } from "../domain/enums";
import { DmsEnumSubjectType } from "../domain/models/value/DmsEnum";

import { IDmsTextTranslation } from "../domain/models/component/DmsComponent";
// import TsContainerManager from "./container/TsContainerManager"

/**
 * If running from the Container (and you have injected the script), returns that configuration from the injected script.
 * Otherwise, returns a sample instance for when running from localhost (directly, not through the container.)
 */
// export const getAlyanteWebApiConfig = (): IAlyanteWebApiConfig => {
//   var config = window[ALYANTE_WEBAPI_CONFIG] as IAlyanteWebApiConfig
//   return config
// }

export function isBackwardNavigation() {
  return window.location.href.indexOf("back=1") >= 0;
}

/**
 * Used to add array of parameters to a query string
 */
export const appendQueryString = (
  prev: string,
  paramName: string,
  values?: any | any[]
): string => {
  if (values == null || values.length === 0) return prev;
  let queue = "";
  if (Array.isArray(values)) {
    values.forEach((value) => {
      if (queue !== "") queue += "&";
      if (value != null)
        queue += `${paramName}=${encodeURIComponent(value.toString())}`;
    });
  } else queue += `${paramName}=${encodeURIComponent(values.toString())}`;
  if (queue === "") return prev;
  if (prev === "") return queue;
  else return prev + "&" + queue;
};

export const getTextTranslation = (
  textTranslation?: IDmsTextTranslation[],
  defaultResult?: string
): string | null | undefined => {
  if (!textTranslation) return defaultResult;

  let interfaceLanguage = "it"; //TsContainerManager.interfaceLanguage
  let defaultLanguage = { name: "it" }; //getAlyanteWebApiConfig().defaultLanguage

  let label = textTranslation.find((l) => l.l === interfaceLanguage);

  if (!label) {
    label = textTranslation.find((l) => l.l === defaultLanguage.name);
  }
  if (!label) {
    let nonEmptyLabels = textTranslation.filter((l) => l.t);
    if (nonEmptyLabels.length > 0) label = nonEmptyLabels[0];
  }

  return label?.t ?? defaultResult;
};

// OBSOLETE: replace with getTextTranslation
export const getLabel = (
  labels?: IDmsTextTranslation[]
): string | null | undefined => {
  if (!labels) return;
  if (labels.length === 0) return;
  if (!labels) return;
  if (labels.length === 0) return;

  let interfaceLanguage = "it"; //TsContainerManager.interfaceLanguage
  let defaultLanguage = { name: "it" }; //getAlyanteWebApiConfig().defaultLanguage

  let label = labels.find((l) => l.l === interfaceLanguage);

  if (!label) {
    label = labels.find((l) => l.l === defaultLanguage.name);
  }
  if (!label) {
    let nonEmptyLabels = labels.filter((l) => l.t);
    if (nonEmptyLabels.length > 0) label = nonEmptyLabels[0];
  }

  return label?.t;
};

export const clearEmptyLabels = (
  labels?: IDmsTextTranslation[]
): IDmsTextTranslation[] => {
  let result: IDmsTextTranslation[] = [];

  if (!labels || labels.length === 0) return result;

  labels.forEach((label) => {
    if (!label.l?.trim() || !label.t?.trim()) return;
    result.push({ l: label.l.trim(), t: label.t.trim() });
  });

  return result;
};

export const getMembershipTabTitle = (
  subjectType?: DmsEnumSubjectType,
  tab?: EMembershipTab
) => {
  let title = "";

  if (!subjectType || !tab) return;

  if (subjectType !== DmsEnumSubjectType.Role) {
    switch (tab) {
      case EMembershipTab.UserAndGroupMembers:
        title = "Members";
        break;

      case EMembershipTab.GroupMemberships:
        title = "Member of";
        break;

      // case EMembershipTab.RoleMemberships:
      //   title = "Roles"
      //   break

      default:
        break;
    }
  }

  if (subjectType === DmsEnumSubjectType.Role) {
    switch (tab) {
      case EMembershipTab.UserAndGroupMembers:
        title = "?#Members";
        break;

      case EMembershipTab.RoleMembers:
        title = "?#Members of";
        break;

      // case EMembershipTab.RoleMemberships:
      //   title = "Other roles"
      //   break

      default:
        break;
    }
  }

  return title;
};

export function checkVATCode(VAT?: string | null | undefined): boolean {
  if (!VAT) return false;

  if (VAT.length != 11) return false;

  var i,
    v,
    x = 0,
    y = 0;
  for (i = 0; i < 11; i++) {
    v = ParseInt(VAT.charAt(i), null);
    if (v == null) return false;

    if (i % 2 == 0) x += v;
    else y += v * 2 > 9 ? v * 2 - 9 : v * 2;
  }

  return (x + y) % 10 == 0;
}

export function checkFiscalCode(
  fiscalCode: string | null | undefined,
  checkIfVATCode: boolean = true
): boolean {
  if (!fiscalCode) return false;

  if (fiscalCode.length == 11 && checkIfVATCode)
    return checkVATCode(fiscalCode);

  if (fiscalCode.length != 16) return false;

  if (
    fiscalCode.match(
      /^[A-Z]{6}[A-Z0-9]{2}[A-Z][A-Z0-9]{2}[A-Z][A-Z0-9]{3}[A-Z]$/
    ) == null
  )
    return false;

  var i,
    c,
    v,
    s = 0,
    t = "BAKPLCQDREVOSFTGUHMINJWZYX";
  var ck = ParseInt(fiscalCode.charAt(15), null, 36);

  if (!ck) return false;
  else if (ck < 10) return false;
  else ck -= 10;

  for (i = 0; i < 15; i++) {
    c = fiscalCode.charAt(i);
    v = ParseInt(c, null, 36);

    if (v == null) return false;

    if (i % 2 == 0)
      s +=
        v >= 10 ? t.indexOf(c) : t.indexOf((v + 10).toString(36).toUpperCase());
    else s += v >= 10 ? v - 10 : v;
  }
  return s % 26 == ck;
}

function ParseInt(
  value: string,
  defaultValue: number | null,
  radix?: number | null | undefined
): number | null {
  if (radix == null) radix = 10;

  let result = parseInt(value, radix);

  if (isNaN(result) && arguments.length > 1) return defaultValue;

  return result;
}

export const checkEmail = (email?: string | null | undefined): boolean => {
  var tester =
    /^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

  if (!email) return false;

  var emailParts = email.split("@");

  if (emailParts.length !== 2) return false;

  var account = emailParts[0];
  var address = emailParts[1];

  if (account.length > 64) return false;
  else if (address.length > 255) return false;

  var domainParts = address.split(".");

  if (
    domainParts.some(function (part) {
      return part.length > 63;
    })
  )
    return false;

  return tester.test(email);
};
