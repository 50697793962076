// import i18next from "../../i18n/i18n";
import { GridLocaleText } from "@vapor/react-x-data-grid/node_modules/@mui/x-data-grid-pro";

// const t = (label: string) => {
//   return label;
// };
export const LocaleTextConstant = (t: any): GridLocaleText => {
  const lTextConst: GridLocaleText = {
    // Root
    noRowsLabel: t("DataGrid.noRowsLabel"),
    noResultsOverlayLabel: t("DataGrid.noResultsOverlayLabel"),

    // Density selector toolbar button text
    toolbarDensity: t("DataGrid.toolbarDensity"),
    toolbarDensityLabel: t("DataGrid.toolbarDensityLabel"),
    toolbarDensityCompact: t("DataGrid.toolbarDensityCompact"),
    toolbarDensityStandard: t("DataGrid.toolbarDensityStandard"),
    toolbarDensityComfortable: t("DataGrid.toolbarDensityComfortable"),

    // Columns selector toolbar button text
    toolbarColumns: t("DataGrid.toolbarColumns"),
    toolbarColumnsLabel: t("DataGrid.toolbarColumnsLabel"),

    // Filters toolbar button text
    toolbarFilters: t("DataGrid.toolbarFilters"),
    toolbarFiltersLabel: t("DataGrid.toolbarFiltersLabel"),
    toolbarFiltersTooltipHide: t("DataGrid.toolbarFiltersTooltipHide"),
    toolbarFiltersTooltipShow: t("DataGrid.toolbarFiltersTooltipShow"),
    toolbarFiltersTooltipActive: (count: number) =>
      count !== 1
        ? `${count} ` + t("DataGrid.toolbarFiltersTooltipActive>1")
        : `${count} ` + t("DataGrid.toolbarFiltersTooltipActive1"),

    // Quick filter toolbar field
    toolbarQuickFilterPlaceholder: t("DataGrid.toolbarQuickFilterPlaceholder"),
    toolbarQuickFilterLabel: t("DataGrid.toolbarQuickFilterLabel"),
    toolbarQuickFilterDeleteIconLabel: t(
      "DataGrid.toolbarQuickFilterDeleteIconLabel"
    ),

    // Export selector toolbar button text
    toolbarExport: t("DataGrid.toolbarExport"),
    toolbarExportLabel: t("DataGrid.toolbarExportLabel"),
    toolbarExportCSV: t("DataGrid.toolbarExportCSV"),
    toolbarExportPrint: t("DataGrid.toolbarExportPrint"),
    toolbarExportExcel: t("DataGrid.toolbarExportExcel"),

    columnsPanelTextFieldLabel: t("DataGrid.columnsPanelTextFieldLabel"),
    columnsPanelTextFieldPlaceholder: t(
      "DataGrid.columnsPanelTextFieldPlaceholder"
    ),
    columnsPanelDragIconLabel: t("DataGrid.columnsPanelDragIconLabel"),
    columnsPanelShowAllButton: t("DataGrid.columnsPanelShowAllButton"),
    columnsPanelHideAllButton: t("DataGrid.columnsPanelHideAllButton"),

    // // Columns management text

    // Filter panel text
    filterPanelAddFilter: t("DataGrid.filterPanelAddFilter"),
    filterPanelRemoveAll: t("DataGrid.filterPanelRemoveAll"),
    filterPanelDeleteIconLabel: t("DataGrid.filterPanelDeleteIconLabel"),
    filterPanelLogicOperator: t("DataGrid.filterPanelLogicOperator"),
    filterPanelOperator: t("DataGrid.filterPanelOperator"),
    filterPanelOperatorAnd: t("DataGrid.filterPanelOperatorAnd"),
    filterPanelOperatorOr: t("DataGrid.filterPanelOperatorOr"),
    filterPanelColumns: t("DataGrid.filterPanelColumns"),
    filterPanelInputLabel: t("DataGrid.filterPanelInputLabel"),
    filterPanelInputPlaceholder: t("DataGrid.filterPanelInputPlaceholder"),

    // Filter operators text
    filterOperatorContains: t("DataGrid.filterOperatorContains"),
    filterOperatorEquals: t("DataGrid.filterOperatorEquals"),
    filterOperatorStartsWith: t("DataGrid.filterOperatorStartsWith"),
    filterOperatorEndsWith: t("DataGrid.filterOperatorEndsWith"),
    filterOperatorIs: t("DataGrid.filterOperatorIs"),
    filterOperatorNot: t("DataGrid.filterOperatorNot"),
    filterOperatorAfter: t("DataGrid.filterOperatorAfter"),
    filterOperatorOnOrAfter: t("DataGrid.filterOperatorOnOrAfter"),
    filterOperatorBefore: t("DataGrid.filterOperatorBefore"),
    filterOperatorOnOrBefore: t("DataGrid.filterOperatorOnOrBefore"),
    filterOperatorIsEmpty: t("DataGrid.filterOperatorIsEmpty"),
    filterOperatorIsNotEmpty: t("DataGrid.filterOperatorIsNotEmpty"),
    filterOperatorIsAnyOf: t("DataGrid.filterOperatorIsAnyOf"),
    "filterOperator=": "=",
    "filterOperator!=": "!=",
    "filterOperator>": ">",
    "filterOperator>=": ">=",
    "filterOperator<": "<",
    "filterOperator<=": "<=",

    // Header filter operators text
    headerFilterOperatorContains: t("DataGrid.headerFilterOperatorContains"),
    headerFilterOperatorEquals: t("DataGrid.headerFilterOperatorEquals"),
    headerFilterOperatorStartsWith: t(
      "DataGrid.headerFilterOperatorStartsWith"
    ),
    headerFilterOperatorEndsWith: t("DataGrid.headerFilterOperatorEndsWith"),
    headerFilterOperatorIs: t("DataGrid.headerFilterOperatorIs"),
    headerFilterOperatorNot: t("DataGrid.headerFilterOperatorNot"),
    headerFilterOperatorAfter: t("DataGrid.headerFilterOperatorAfter"),
    headerFilterOperatorOnOrAfter: t("DataGrid.headerFilterOperatorOnOrAfter"),
    headerFilterOperatorBefore: t("DataGrid.headerFilterOperatorBefore"),
    headerFilterOperatorOnOrBefore: t(
      "DataGrid.headerFilterOperatorOnOrBefore"
    ),
    headerFilterOperatorIsEmpty: t("DataGrid.headerFilterOperatorIsEmpty"),
    headerFilterOperatorIsNotEmpty: t(
      "DataGrid.headerFilterOperatorIsNotEmpty"
    ),
    headerFilterOperatorIsAnyOf: t("DataGrid.headerFilterOperatorIsAnyOf"),
    "headerFilterOperator=": t("DataGrid.headerFilterOperator="),
    "headerFilterOperator!=": t("DataGrid.headerFilterOperator!="),
    "headerFilterOperator>": t("DataGrid.headerFilterOperator>"),
    "headerFilterOperator>=": t("DataGrid.headerFilterOperator>="),
    "headerFilterOperator<": t("DataGrid.headerFilterOperator<"),
    "headerFilterOperator<=": t("DataGrid.headerFilterOperator<="),

    // Filter values text
    filterValueAny: t("DataGrid.filterValueAny"),
    filterValueTrue: t("DataGrid.filterValueTrue"),
    filterValueFalse: t("DataGrid.filterValueFalse"),

    // Column menu text
    columnMenuLabel: t("DataGrid.columnMenuLabel"),
    columnMenuShowColumns: t("DataGrid.columnMenuShowColumns"),
    columnMenuManageColumns: t("DataGrid.columnMenuManageColumns"),
    columnMenuFilter: t("DataGrid.columnMenuFilter"),
    columnMenuHideColumn: t("DataGrid.columnMenuHideColumn"),
    columnMenuUnsort: t("DataGrid.columnMenuUnsort"),
    columnMenuSortAsc: t("DataGrid.columnMenuSortAsc"),
    columnMenuSortDesc: t("DataGrid.columnMenuSortDesc"),

    // Column header text
    columnHeaderFiltersTooltipActive: (count: number) =>
      count !== 1
        ? `${count} ` + t("DataGrid.columnHeaderFiltersTooltipActive>1")
        : `${count} ` + t("DataGrid.columnHeaderFiltersTooltipActive1"),
    columnHeaderFiltersLabel: t("DataGrid.columnHeaderFiltersLabel"),
    columnHeaderSortIconLabel: t("DataGrid.columnHeaderSortIconLabel"),

    // Rows selected footer text
    footerRowSelected: (count: number) =>
      count !== 1
        ? `${count.toLocaleString()} ` + t("DataGrid.footerRowSelected>1")
        : `${count.toLocaleString()} ` + t("DataGrid.footerRowSelected1"),

    // Total row amount footer text
    footerTotalRows: t("DataGrid.footerTotalRows"),

    // Total visible row amount footer text
    footerTotalVisibleRows: (visibleCount: number, totalCount: number) =>
      `${visibleCount.toLocaleString()} ` +
      t("DataGrid.footerTotalVisibleRows") +
      ` ${totalCount.toLocaleString()}`,

    // Checkbox selection text
    checkboxSelectionHeaderName: t("DataGrid.checkboxSelectionHeaderName"),
    checkboxSelectionSelectAllRows: t(
      "DataGrid.checkboxSelectionSelectAllRows"
    ),
    checkboxSelectionUnselectAllRows: t(
      "DataGrid.checkboxSelectionUnselectAllRows"
    ),
    checkboxSelectionSelectRow: t("DataGrid.checkboxSelectionSelectRow"),
    checkboxSelectionUnselectRow: t("DataGrid.checkboxSelectionUnselectRow"),

    // Boolean cell text
    booleanCellTrueLabel: t("DataGrid.booleanCellTrueLabel"),
    booleanCellFalseLabel: t("DataGrid.booleanCellFalseLabel"),

    // Actions cell more text
    actionsCellMore: t("DataGrid.actionsCellMore"),

    // Column pinning text
    pinToLeft: t("DataGrid.pinToLeft"),
    pinToRight: t("DataGrid.pinToRight"),
    unpin: t("DataGrid.unpin"),

    // Tree Data
    treeDataGroupingHeaderName: t("DataGrid.treeDataGroupingHeaderName"),
    treeDataExpand: t("DataGrid.treeDataExpand"),
    treeDataCollapse: t("DataGrid.treeDataCollapse"),

    // Grouping columns
    groupingColumnHeaderName: t("DataGrid.groupingColumnHeaderName"),
    groupColumn: (name: string) => t("DataGrid.groupColumn") + ` ${name}`,
    unGroupColumn: (name: string) => t("DataGrid.unGroupColumn") + ` ${name}`,

    // Master/detail
    detailPanelToggle: t("DataGrid.detailPanelToggle"),
    expandDetailPanel: t("DataGrid.expandDetailPanel"),
    collapseDetailPanel: t("DataGrid.collapseDetailPanel"),

    // Row reordering text
    rowReorderingHeaderName: t("DataGrid.rowReorderingHeaderName"),

    // Aggregation
    aggregationMenuItemHeader: t("DataGrid.aggregationMenuItemHeader"),
    aggregationFunctionLabelSum: t("DataGrid.aggregationFunctionLabelSum"),
    aggregationFunctionLabelAvg: t("DataGrid.aggregationFunctionLabelAvg"),
    aggregationFunctionLabelMin: t("DataGrid.aggregationFunctionLabelMin"),
    aggregationFunctionLabelMax: t("DataGrid.aggregationFunctionLabelMax"),
    aggregationFunctionLabelSize: t("DataGrid.aggregationFunctionLabelSize"),

    // Pagination text
    MuiTablePagination: {
      labelRowsPerPage: `${t("DataGrid.labelRowsPerPage")}`, //`${t("DataGrid.labelRowsPerPage")}`, // Traduzione di "Rows per page"
      labelDisplayedRows: ({ from, to, count }) =>
        `${from}–${to} ` +
        `${t("DataGrid.labelDisplayedRowsOf")}` +
        ` ${
          count !== -1
            ? count
            : t("DataGrid.labelDisplayedRowsMoreOf") + ` ${to}`
        }`,
      //    `${from}–${to} ` + ` di ` + (count !== -1 ? count : `più di ${to}`),
    },
  };
  return lTextConst;
};
